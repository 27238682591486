import React from "react"; // This is required for the transpiled .js version (React.createELement)
import LayersCollections from "./LayersCollections.js";
import { Button, Drawer, FontAwesome } from "ca-react-component-lib";

/* jshint ignore:start */

export default function Layers({
  viewer,
  assessmentId,
  collections,
  setCollections,
  comments,
  setComments,
  measurements,
  setMeasurements,
  primitives,
  setPrimitives,
  showMeasurement,
  setShowMeasurement,
  showPrimitive,
  setShowPrimitive,
  setMode,
  clippingPlanes,
  setClippingPlanes,
}) {
  const [showLayers, setShowLayers] = React.useState(true);

  return (
    <>
      <Drawer
        hideBackdrop
        id="reactLayersDrawer"
        open={showLayers}
        ModalProps={{
          disableEnforceFocus: true,
        }}
      >
        <LayersCollections
          viewer={viewer}
          assessmentId={assessmentId}
          collections={collections}
          setCollections={setCollections}
          comments={comments}
          setComments={setComments}
          measurements={measurements}
          setMeasurements={setMeasurements}
          primitives={primitives}
          setPrimitives={setPrimitives}
          showMeasurement={showMeasurement}
          setShowMeasurement={setShowMeasurement}
          showPrimitive={showPrimitive}
          setShowPrimitive={setShowPrimitive}
          clippingPlanes={clippingPlanes}
          setClippingPlanes={setClippingPlanes}
          setMode={setMode}
        />
      </Drawer>

      <Button
        className={showLayers ? "toolbarButton active" : "toolbarButton white"}
        onClick={() => setShowLayers(!showLayers)}
        sx={{
          position: "fixed",
          left: 8,
          bottom: 8,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <FontAwesome icon="layer-group" />
      </Button>
    </>
  );
}

/* jshint ignore:end */

import {
  ManagedArray,
  Cesium3DTileset,
  Model,
  PrimitiveCollection,
} from "../../Core/cesium/Source/Cesium.js";

function VisibilityState() {
  this.states = new ManagedArray();
  this.count = 0;
}

VisibilityState.prototype.hidePrimitiveCollection = function (
  primitiveCollection
) {
  const primitivesLength = primitiveCollection.length;
  for (let i = 0; i < primitivesLength; ++i) {
    const primitive = primitiveCollection.get(i);
    if (primitive instanceof PrimitiveCollection) {
      this.hidePrimitiveCollection(primitive);
      continue;
    }

    this.states.push(primitive.show);

    if (primitive instanceof Cesium3DTileset || primitive instanceof Model) {
      continue;
    }
    primitive.show = false;
  }
};

VisibilityState.prototype.restorePrimitiveCollection = function (
  primitiveCollection
) {
  const primitivesLength = primitiveCollection.length;
  for (let i = 0; i < primitivesLength; ++i) {
    const primitive = primitiveCollection.get(i);
    if (primitive instanceof PrimitiveCollection) {
      this.restorePrimitiveCollection(primitive);
      continue;
    }

    primitive.show = this.states.get(this.count++);
  }
};

VisibilityState.prototype.hide = function (scene) {
  this.states.length = 0;

  this.hidePrimitiveCollection(scene.primitives);
  this.hidePrimitiveCollection(scene.groundPrimitives);
};

VisibilityState.prototype.restore = function (scene) {
  this.count = 0;

  this.restorePrimitiveCollection(scene.primitives);
  this.restorePrimitiveCollection(scene.groundPrimitives);
};
export default VisibilityState;

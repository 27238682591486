import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import {
  FontAwesome,
  Box,
  Drawer,
  List,
  ListItem,
  IconButton,
  Divider,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Slider,
} from "ca-react-component-lib";
import ColorPicker from "./ColorPicker.js";
import DistanceUnits from "../Widgets/Measure/DistanceUnits.js";
import MeasureUnits from "../Widgets/Measure/MeasureUnits.js";
import { Color, HeadingPitchRoll, Transforms, Math as CesiumMath, Matrix4, Cartesian3 } from "@cesium/engine";

let hprScratch = new HeadingPitchRoll();

/* jshint ignore:start */
export default function PrimitiveInfo({ viewer, setPrimitives, showPrimitive, setShowPrimitive }) {
  const measurementUnits = localStorage.getItem('measurementUnits') || DistanceUnits.FEET;
  const [HPR, setHPR] = React.useState(new HeadingPitchRoll());
  const [sliderWidth, setSliderWidth] = React.useState(undefined);
  const [sliderHeight, setSliderHeight] = React.useState(undefined);
  const [sliderLength, setSliderLength] = React.useState(undefined);
  const [sliderHeading, setSliderHeading] = React.useState(undefined);
  const [sliderPitch, setSliderPitch] = React.useState(undefined);
  const [sliderRoll, setSliderRoll] = React.useState(undefined);

  const closeDrawer = () => {
    setShowPrimitive(false);
  };

  const updateTitle = (title) => {
    setPrimitives((currentPrimitives) => currentPrimitives.map(p => {
      if (p.id === showPrimitive.id) {
        p.title = title;
      }
      return p;
    }));
  };

  const updateColor = (color) => {
    setShowPrimitive((currentPrimitive) => {
      const newPrimitive = {...currentPrimitive};
      newPrimitive.color = color;
      return newPrimitive;
    });
    setPrimitives((currentPrimitives) => currentPrimitives.map(p => {
      if (p.id === showPrimitive.id) {
        p.color = color;
        const box = window.cesiumTools.getEntityById(showPrimitive.id);
        box.color = box._primitive._color = Color.fromCssColorString(color).withAlpha(p.opacity || 0.3);
        box.forceUpdate();
      }
      return p;
    }));
  };

  const updateOpacity = (opacity) => {
    setShowPrimitive((currentPrimitive) => {
      const newPrimitive = {...currentPrimitive};
      newPrimitive.opacity = opacity;
      return newPrimitive;
    });
    setPrimitives((currentPrimitives) => currentPrimitives.map(p => {
      if (p.id === showPrimitive.id) {
        p.opacity = opacity;
        const box = window.cesiumTools.getEntityById(showPrimitive.id);
        box.color = box._primitive._color = Color.fromCssColorString(p.color).withAlpha(opacity);
        box.forceUpdate();
      }
      return p;
    }));
  };

  const updatePrimitiveValue = (newValue, key) => {
    setShowPrimitive((currentPrimitive) => {
      const newPrimitive = {...currentPrimitive};
      newPrimitive.primitive.dimensions[key] = newValue;
      return newPrimitive;
    });
    setPrimitives((currentPrimitives) => currentPrimitives.map(p => {
      if (p.id === showPrimitive.id) {
        const newPrimitive = {...p};
        newPrimitive.primitive.dimensions[key] = newValue;
        return newPrimitive;
      }
      return p;
    }));
  };

  const updateHPR = React.useCallback((newHPR) => {
    const box = window.cesiumTools.getEntityById(showPrimitive.id);
    const newModelMatrix = Transforms.headingPitchRollToFixedFrame(Matrix4.getTranslation(showPrimitive.primitive.modelMatrix, new Cartesian3()), newHPR);

    setShowPrimitive((currentPrimitive) => {
      const newPrimitive = {...currentPrimitive};
      newPrimitive.primitive.modelMatrix = newModelMatrix;
      return newPrimitive;
    });
    setPrimitives((currentPrimitives) => currentPrimitives.map(p => {
      if (p.id === showPrimitive.id) {
        const newPrimitive = {...p};
        newPrimitive.primitive.modelMatrix = newModelMatrix;
        return newPrimitive;
      }
      return p;
    }));

    if (!window.cesiumTools._currentTool) {
      window.cesiumTools.selectTool("boxEdit");
    }
    window.cesiumTools._currentTool.selectedEntity = box;
    window.cesiumTools._currentTool._transformEditor?._viewModel?.deactivate();
    window.cesiumTools._currentTool._transformEditor?._viewModel?.setHeadingPitchRoll(
      showPrimitive.primitive.modelMatrix,
      newHPR
    );
    box._primitive._modelMatrix = newModelMatrix;
    box._primitive._hpr = newHPR;
    box.forceUpdate();
  }, [showPrimitive, setShowPrimitive, setPrimitives, viewer]);

  React.useEffect(() => {
    if (showPrimitive) {
      const newHPR = {...Transforms.fixedFrameToHeadingPitchRoll(
        showPrimitive.primitive.modelMatrix,
        viewer.scene.mapProjection.ellipsoid,
        undefined,
        hprScratch
      )};
      if (HPR.heading !== newHPR.heading || HPR.pitch !== newHPR.pitch || HPR.roll !== newHPR.roll) setHPR(newHPR);
    } else {
      setHPR(new HeadingPitchRoll());
    }
  }, [showPrimitive?.id]);

  const widthVal = showPrimitive?.primitive?.dimensions?.x ? MeasureUnits.convertDistance(showPrimitive.primitive.dimensions.x, DistanceUnits.METERS, measurementUnits).toFixed(2) : "0";
  const lengthVal = showPrimitive?.primitive?.dimensions?.z ? MeasureUnits.convertDistance(showPrimitive.primitive.dimensions.z, DistanceUnits.METERS, measurementUnits).toFixed(2) : "0";
  const heightVal = showPrimitive?.primitive?.dimensions?.y ? MeasureUnits.convertDistance(showPrimitive.primitive.dimensions.y, DistanceUnits.METERS, measurementUnits).toFixed(2) : "0";
  const headingVal = CesiumMath.toDegrees(HPR.heading).toFixed(2) || "0";
  const pitchVal = CesiumMath.toDegrees(HPR.pitch).toFixed(2) || "0";
  const rollVal = CesiumMath.toDegrees(HPR.roll).toFixed(2) || "0";

  return (
      <Drawer
        hideBackdrop
        anchor="right"
        open={!!showPrimitive}
        className="toolbarDrawer toolbarDrawerSlim"
        onClose={() => closeDrawer()}
        ModalProps={{
          disableEnforceFocus: true,
        }}
      >
        <List>
          <ListItem>
            {<FontAwesome
              icon="cube"
              sx={{ mr: 2 }}
            />}
            <TextField
              value={showPrimitive?.title || ""}
              variant="standard"
              className="drawerTitle"
              onChange={(e) => updateTitle(e.target.value)}
            />
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => closeDrawer()} sx={{ mr: -1 }}>
              <FontAwesome icon="xmark" />
            </IconButton>
          </ListItem>
        </List>

        <Divider />

          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="subtitle2" sx={{ my: 2 }}>Color</Typography>
              <ColorPicker color={showPrimitive?.color || "#bcc1c9"} setColor={(color) => updateColor(color)} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between"}}>
              <Typography variant="subtitle2">Opacity</Typography>
              <Slider
                sx={{ width: "100px" }}
                value={showPrimitive?.opacity >= 0 ? +showPrimitive.opacity : .3}
                onChange={(e, newVal) => updateOpacity(newVal)}
                min={0}
                max={1}
                step={.1}
              />
            </Box>
            <Divider />
            <Typography variant="subtitle2" sx={{ my: 2 }}>Dimensions</Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <TextField
                sx={{ mr: 2 }}
                fullWidth
                type="number"
                inputProps={{ step: "0.01" }}
                value={widthVal}
                label="Width"
                onChange={(e) => {
                  const targetValue = Math.max(e.target.value, 0);
                  const newValue = MeasureUnits.convertDistance(targetValue, measurementUnits, DistanceUnits.METERS);
                  updatePrimitiveValue(newValue, "x");
                  const box = window.cesiumTools.getEntityById(showPrimitive.id);
                  box.updateWidth(newValue);
                  box.updateMeasurementLabels();
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{MeasureUnits.getDistanceUnitSymbol(measurementUnits)}</InputAdornment>
                }}
              />
              <Slider
                sx={{ width: "100px" }}
                value={typeof sliderWidth !== "undefined" ? +sliderWidth : +widthVal}
                track={false}
                onChange={(e, newVal) => {
                  setSliderWidth(+newVal);
                }}
                onChangeCommitted={(e, newVal) => {
                  const targetValue = Math.max(newVal, 0);
                  const newValue = MeasureUnits.convertDistance(targetValue, measurementUnits, DistanceUnits.METERS);
                  updatePrimitiveValue(newValue, "x");
                  const box = window.cesiumTools.getEntityById(showPrimitive.id);
                  box.updateWidth(newValue);
                  box.updateMeasurementLabels();
                  setSliderWidth(undefined);
                }}
                min={+widthVal - 10}
                max={+widthVal + 10}
                step={0.01}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <TextField
                sx={{ mr: 2 }}
                fullWidth
                type="number"
                inputProps={{ step: "0.01" }}
                value={lengthVal}
                label="Height"
                onChange={(e) => {
                  const targetValue = Math.max(e.target.value, 0);
                  const newValue = MeasureUnits.convertDistance(targetValue, measurementUnits, DistanceUnits.METERS);
                  updatePrimitiveValue(newValue, "z");
                  const box = window.cesiumTools.getEntityById(showPrimitive.id);
                  box.updateLength(newValue);
                  box.updateMeasurementLabels();
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{MeasureUnits.getDistanceUnitSymbol(measurementUnits)}</InputAdornment>
                }}
              />
              <Slider
                sx={{ width: "100px" }}
                value={typeof sliderLength !== "undefined" ? +sliderLength : +lengthVal}
                track={false}
                onChange={(e, newVal) => {
                  setSliderLength(+newVal);
                }}
                onChangeCommitted={(e, newVal) => {
                  const targetValue = Math.max(newVal, 0);
                  const newValue = MeasureUnits.convertDistance(targetValue, measurementUnits, DistanceUnits.METERS);
                  updatePrimitiveValue(newValue, "z");
                  const box = window.cesiumTools.getEntityById(showPrimitive.id);
                  box.updateLength(newValue);
                  box.updateMeasurementLabels();
                  setSliderLength(undefined);
                }}
                min={+lengthVal - 10}
                max={+lengthVal + 10}
                step={0.01}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <TextField
                sx={{ mr: 2 }}
                fullWidth
                type="number"
                inputProps={{ step: "0.01" }}
                value={heightVal}
                label="Length"
                onChange={(e) => {
                  const targetValue = Math.max(e.target.value, 0);
                  const newValue = MeasureUnits.convertDistance(targetValue, measurementUnits, DistanceUnits.METERS);
                  updatePrimitiveValue(newValue, "y");
                  const box = window.cesiumTools.getEntityById(showPrimitive.id);
                  box.updateHeight(newValue);
                  box.updateMeasurementLabels();
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{MeasureUnits.getDistanceUnitSymbol(measurementUnits)}</InputAdornment>
                }}
              />
              <Slider
                sx={{ width: "100px" }}
                value={typeof sliderHeight !== "undefined" ? +sliderHeight : +heightVal}
                track={false}
                onChange={(e, newVal) => {
                  setSliderHeight(+newVal);
                }}
                onChangeCommitted={(e, newVal) => {
                  const targetValue = Math.max(newVal, 0);
                  const newValue = MeasureUnits.convertDistance(targetValue, measurementUnits, DistanceUnits.METERS);
                  updatePrimitiveValue(newValue, "y");
                  const box = window.cesiumTools.getEntityById(showPrimitive.id);
                  box.updateHeight(newValue);
                  box.updateMeasurementLabels();
                  setSliderHeight(undefined);
                }}
                min={+heightVal - 10}
                max={+heightVal + 10}
                step={0.01}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <Typography>Volume </Typography>
              <Typography>{MeasureUnits.volumeToString(
                showPrimitive?.primitive?.dimensions?.x * showPrimitive?.primitive?.dimensions?.y * showPrimitive?.primitive?.dimensions?.z,
                "CUBIC_"+measurementUnits
              )}</Typography>
            </Box>
            <Typography variant="subtitle2" sx={{ my: 2 }}>Orientation</Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <TextField
                sx={{ mr: 2 }}
                fullWidth
                type="number"
                value={headingVal}
                inputProps={{ step: "0.05" }}
                label="Heading"
                onChange={(e) => {
                  setHPR((currentHPR) => {
                    const newHPR = { ...currentHPR, heading: CesiumMath.toRadians(+e.target.value) };
                    updateHPR(newHPR);
                    return newHPR;
                  });
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">deg</InputAdornment>
                }}
              />
              <Slider
                sx={{ width: "100px" }}
                value={typeof sliderHeading !== "undefined" ? +sliderHeading : +headingVal}
                track={false}
                onChange={(e, newVal) => {
                  setSliderHeading(+newVal);
                }}
                onChangeCommitted={(e, newVal) => {
                  setHPR((currentHPR) => {
                    const newHPR = { ...currentHPR, heading: CesiumMath.toRadians(+newVal) };
                    updateHPR(newHPR);
                    return newHPR;
                  });
                  setSliderHeading(undefined);
                }}
                min={+headingVal - 10}
                max={+headingVal + 10}
                step={0.05}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <TextField
                sx={{ mr: 2 }}
                fullWidth
                type="number"
                value={pitchVal}
                inputProps={{ step: "0.05" }}
                label="Pitch"
                onChange={(e) => {
                  setHPR((currentHPR) => {
                    const newHPR = { ...currentHPR, pitch: CesiumMath.toRadians(+e.target.value) };
                    updateHPR(newHPR);
                    return newHPR;
                  });
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">deg</InputAdornment>
                }}
              />
              <Slider
                sx={{ width: "100px" }}
                value={typeof sliderPitch !== "undefined" ? +sliderPitch : +pitchVal}
                track={false}
                onChange={(e, newVal) => {
                  setSliderPitch(+newVal);
                }}
                onChangeCommitted={(e, newVal) => {
                  setHPR((currentHPR) => {
                    const newHPR = { ...currentHPR, pitch: CesiumMath.toRadians(+newVal) };
                    updateHPR(newHPR);
                    return newHPR;
                  });
                  setSliderPitch(undefined);
                }}
                min={+pitchVal - 10}
                max={+pitchVal + 10}
                step={0.05}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <TextField
                sx={{ mr: 2 }}
                fullWidth
                type="number"
                value={rollVal}
                inputProps={{ step: "0.05" }}
                label="Roll"
                onChange={(e) => {
                  setHPR((currentHPR) => {
                    const newHPR = { ...currentHPR, roll: CesiumMath.toRadians(+e.target.value) };
                    updateHPR(newHPR);
                    return newHPR;
                  });
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">deg</InputAdornment>
                }}
              />
              <Slider
                sx={{ width: "100px" }}
                value={typeof sliderRoll !== "undefined" ? +sliderRoll : +rollVal}
                track={false}
                onChange={(e, newVal) => {
                  setSliderRoll(+newVal);
                }}
                onChangeCommitted={(e, newVal) => {
                  setHPR((currentHPR) => {
                    const newHPR = { ...currentHPR, roll: CesiumMath.toRadians(+newVal) };
                    updateHPR(newHPR);
                    return newHPR;
                  });
                  setSliderRoll(undefined);
                }}
                min={+rollVal - 10}
                max={+rollVal + 10}
                step={0.05}
              />
            </Box>
            <FormControlLabel
              control={
              <Checkbox
                checked={showPrimitive?.primitive?.dimensions?.showDimensions || false}
                onChange={(e) => {
                  updatePrimitiveValue(e.target.checked, "showDimensions");
                  const box = window.cesiumTools.getEntityById(showPrimitive.id);
                  box.dimensions.showDimensions = e.target.checked;
                  box._primitive._dimensions.showDimensions = e.target.checked;
                  box._xAxis.show = e.target.checked;
                  box._yAxis.show = e.target.checked;
                  box._zAxis.show = e.target.checked;
                  box._widthLabel.show = e.target.checked;
                  box._depthLabel.show = e.target.checked;
                  box._heightLabel.show = e.target.checked;
                }}
              />
              }
              label="Show Dimensions"
            />
          </Box>
      </Drawer>
  );
}
/* jshint ignore:end */
//This file is automatically rebuilt by the Cesium build process.
export default "vec2 intersectScene(vec2 screenCoord, vec3 positionUv, vec3 directionUv, out Intersections ix) {\n\
Ray ray = Ray(positionUv, directionUv);\n\
intersectShape(ray, ix);\n\
vec2 entryExitT = getIntersectionPair(ix, 0);\n\
if (entryExitT.x == NO_HIT) {\n\
return vec2(NO_HIT);\n\
}\n\
#if defined(CLIPPING_PLANES)\n\
intersectClippingPlanes(ray, ix);\n\
#endif\n\
#if defined(DEPTH_TEST)\n\
intersectDepth(screenCoord, ray, ix);\n\
#endif\n\
#if (INTERSECTION_COUNT > 1)\n\
initializeIntersections(ix);\n\
for (int i = 0; i < INTERSECTION_COUNT; ++i) {\n\
entryExitT = nextIntersection(ix);\n\
if (entryExitT.y > 0.0) {\n\
entryExitT.x = max(entryExitT.x, 0.0);\n\
break;\n\
}\n\
}\n\
#else\n\
entryExitT.x = max(entryExitT.x, 0.0);\n\
#endif\n\
return entryExitT;\n\
}\n\
";

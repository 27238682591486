import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { FontAwesome, Box, Dialog, IconButton, Stack, FormControl, InputLabel, TextField, Select, MenuItem, Card, Chip } from "ca-react-component-lib";
import getWorldPosition from "../Widgets/getWorldPosition";
import ColorPicker from "./ColorPicker";
import { Cartesian3 } from "@cesium/engine";
import { getMaxId } from "./ReactUI.js";

/* jshint ignore:start */
export default function Comments({ viewer, assessmentId, setMode, collections, comments, setComments, toolActive, setToolActive }) {
  const [commentCoordinates, setCommentCoordinates] = React.useState(undefined);
  const [id, setId] = React.useState(undefined);
  const [title, setTitle] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [color, setColor] = React.useState("#4CAF50");
  const [selectCollection, setSelectCollection] = React.useState(false);

  const handleClick = React.useCallback((e) => {
    const mousePosition  = new Cesium.Cartesian2(e.clientX, e.clientY);
    const position = getWorldPosition(viewer.scene, mousePosition, new Cartesian3());

    setCommentCoordinates(position);
    setToolActive(false);
    setTimeout(() => {
      const editPaper = document.querySelector(".commentDialog");

      if (editPaper) {
        const canvasPosition = viewer.scene.cartesianToCanvasCoordinates(
          position,
          new Cartesian3()
        );

        if (canvasPosition) {
          editPaper.style.top = canvasPosition.y + "px";
          editPaper.style.left = canvasPosition.x + "px";
        }
      }
    }, 0);
  }, []);

  const clearFields = () => {
    setCommentCoordinates(undefined);
    setSelectCollection(false);
    setId(undefined);
    setTitle("");
    setComment("");
    setColor("#4CAF50");
  };

  const createComment = () => {
    if (!commentCoordinates) return false;
    const newId = getMaxId(assessmentId);

    setComments([
      ...comments.filter((x) => x.id !== id),
      {
        id: newId,
        index: newId,
        title,
        comment,
        color,
        assessmentId,
        position: commentCoordinates,
        collection: selectCollection,
        camera: {
          position: viewer.scene.camera.positionWC.clone(),
          direction: viewer.scene.camera.direction.clone(),
          up: viewer.scene.camera.up.clone(),
          transform: viewer.scene.camera.transform.clone(),
        }
      }
    ])
    clearFields();
  };

  const editComment = (comment) => {
    setId(comment.id);
    setCommentCoordinates(comment.position);
    setTitle(comment.title);
    setComment(comment.comment);
    setColor(comment.color);
    setSelectCollection(comment.collection);

    setTimeout(() => {
      const editPaper = document.querySelector(".commentDialog");

      if (editPaper) {
        const canvasPosition = viewer.scene.cartesianToCanvasCoordinates(
          comment.position,
          new Cartesian3()
        );

        if (canvasPosition) {
          editPaper.style.top = canvasPosition.y + "px";
          editPaper.style.left = canvasPosition.x + "px";
        }
      }
    }, 0);
  }

  const deleteComment = (commentId) => {
    setComments(comments.filter(x => x.id !== commentId));
    clearFields();
  }

  const disableScrollOnCommentDots = (e) => {
      const newWheelEvent = new WheelEvent(e.type, {
        ...e,
        target: viewer.scene.canvas,
      });
      viewer.scene.canvas.dispatchEvent(newWheelEvent);
  }

  React.useEffect(() => {
    if (toolActive) {
      clearFields();
      viewer._container.style.cursor = "crosshair";
      setMode("comment");
      viewer.scene.canvas.addEventListener('click', handleClick, true);
    } else {
      viewer._container.style.cursor = "default";
      setMode("move");
      viewer.scene.canvas.removeEventListener('click', handleClick, true);
    }

    return () => {
      viewer.scene.canvas.removeEventListener('click', handleClick, true);
    }
  }, [toolActive]);

  React.useEffect(() => {
    const positionCommentDots = () => {
      {comments.map((comment) => {
        const canvasPosition = viewer.scene.cartesianToCanvasCoordinates(
          comment.position,
          new Cartesian3()
        );
        const commentDotDOM = document.querySelector('.commentDot[data-id="'+comment.id+'"]');

        if (commentDotDOM && canvasPosition) {
          commentDotDOM.style.top = canvasPosition.y - (commentDotDOM.clientHeight / 2) + "px";
          commentDotDOM.style.left = canvasPosition.x - (commentDotDOM.clientWidth / 2) + 13 + "px";
        }
      })}
    }
    viewer.scene.preRender.addEventListener(positionCommentDots);

    return () => {
      viewer.scene.preRender.removeEventListener(positionCommentDots);
    }
  }, [comments]);

  return (<>
    {<Dialog
      className="commentDialogWindow"
      open={!!commentCoordinates}
      onClose={() => clearFields()}
    >
      <Card className="commentDialog">
        <Stack spacing={2} sx={{ p: 2 }}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <div className="commentHeader">COMMENT</div>
            {id && <IconButton onClick={() => deleteComment(id)}><FontAwesome icon="trash" /></IconButton>}
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <TextField
              value={title}
              name="title"
              autoComplete="off"
              onChange={(e) => setTitle(e.target.value)}
              label="Title"
            />
            <ColorPicker color={color} setColor={setColor} />
            <IconButton onClick={() => createComment()} disabled={!title}>
              <FontAwesome icon="arrow-turn-up" size="small" />
            </IconButton>
          </Stack>
          <Stack>
            <TextField
              value={comment}
              name="comment"
              autoComplete="off"
              multiline
              onChange={(e) => setComment(e.target.value)}
              label="Comment"
            />
          </Stack>
          {collections.length > 0 && <Stack>
            <FormControl>
              <InputLabel>Collection</InputLabel>
              <Select value={selectCollection} onChange={(e) => setSelectCollection(e.target.value)}>
                <MenuItem key="collectionNone" value={false}>Unassigned</MenuItem>
                {collections.map((collection) => (
                  <MenuItem key={collection.id} value={collection.id}>{collection.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>}
        </Stack>
      </Card>
    </Dialog>}
    {comments
      .filter(c => !c.hide && !(c.collection && collections.find(col => col.id === c.collection)?.hide))
      .map((comment) => <div
      key={comment.id}
      className="commentDot"
      data-id={comment.id}
      onClick={() => editComment(comment)}
      onWheel={(e) => disableScrollOnCommentDots(e)}
    >
      <Box className="commentDotInnerWrapper" sx={{ display: "flex", flexDirection: "column", pointerEvents: "none" }}>
        <Box className="commentDotIcon" sx={{ backgroundColor: comment.color || "#4CAF50" }}>{comment.id}</Box>
        <Chip label={comment.title} color="primary" />
      </Box>
    </div>)}
  </>);
}
/* jshint ignore:end */
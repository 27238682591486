/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import {
    Cartesian2,
    Cartesian3,
    defaultValue,
    defined,
    DeveloperError,
    Ellipsoid,
    Matrix3,
    Matrix4,
    Math as CesiumMath,
    Plane,
    SceneTransforms
} from "../../Core/cesium/Source/Cesium.js";

export const Axes = { X: 0, Y: 1, Z: 2 };

export const DimensionLabelTypes = {
    HTML: 1,
    EDITOR: 2,
    TEXT: 0
};

export const DraggingStates = {
    BEGIN: 0,
    DRAGGING: 1,
    FINISHED: 2
};

export const EntityTypes = {
    POLYGON_2D: 1,
    POLYGON_3D: 2,
    BOX: 3
};

export const LabelAlignments = {
    leading: "leading",
    trailing: "trailing"
};

export const MouseButton = {
    LEFT: 0,
    MIDDLE: 1,
    RIGHT: 2
};

export const No = {
    TERRAIN: 0,
    EDGES_DISABLED: 1,
    EDGES_ENABLED: 2,
    EDGES_REQUIRED: 3,
    SKETCH_REQUIRED: 4,
    EDGES_OUTLINE_ENABLED: 5
};

export const ScreenDirections = {
    LEFT: 0,
    RIGHT: 1
};

export const toolIds = {
    basic: {
        box: "box",
        boxEdit: "boxEdit"
    }
};

export function calcCenterPoint(positions) {
    let t = Number.POSITIVE_INFINITY;
    let n = Number.NEGATIVE_INFINITY;
    let i = Number.POSITIVE_INFINITY;
    let r = Number.NEGATIVE_INFINITY;
    let a = Number.POSITIVE_INFINITY;
    let s = Number.NEGATIVE_INFINITY;

    for (const l of positions) {
        t = Math.min(t, l.x);
        n = Math.max(n, l.x);
        i = Math.min(i, l.y);
        r = Math.max(r, l.y);
        a = Math.min(a, l.z);
        s = Math.max(s, l.z);
    }

    return new Cartesian3(t + (n - t) / 2, i + (r - i) / 2, a + (s - a) / 2);
}

export function getClickPixelTolerance(viewer) {
    return viewer.scene.screenSpaceCameraController._aggregator._eventHandler._clickPixelTolerance;
}

export const getDefaultLanguage = () => (navigator.languages?.length ? navigator.languages[0] : navigator.language);

export function isSamePosition(screenPosition1, screenPosition2, pixelTolerance) {
    const i = screenPosition1.x - screenPosition2.x;
    const r = screenPosition1.y - screenPosition2.y;

    return Math.sqrt(i * i + r * r) < pixelTolerance;
}

export const utils = {
    rayPlane: function (ray, plane, result) {
        if (!defined(ray)) throw new DeveloperError("ray is required.");
        if (!defined(plane)) throw new DeveloperError("plane is required.");

        if (!defined(result)) {
            result = new Cartesian3();
        }

        const origin = ray.origin;
        const direction = ray.direction;
        const planeNormal = plane.normal;
        const dotProduct = Cartesian3.dot(planeNormal, direction);

        if (!(Math.abs(dotProduct) < CesiumMath.EPSILON15)) {
            const l = (-plane.distance - Cartesian3.dot(planeNormal, origin)) / dotProduct;

            if (!(l < 0)) {
                result = Cartesian3.multiplyByScalar(direction, l, result);

                return Cartesian3.add(origin, result, result);
            }
        }
    }
};

export function getLeftOrRightPointInfo(direction, points, modelMatrix, scene) {
    const r = points
        .map((s, l) => ({
            index: l,
            pointLocal: s,
            pointWC: Matrix4.multiplyByPoint(modelMatrix, s, new Cartesian3())
        }))
        .map((s) => {
            s.screenPoint = SceneTransforms.wgs84ToWindowCoordinates(scene, s.pointWC);

            return s;
        })
        .filter((s) => s.screenPoint)
        .sort((s, l) => l.screenPoint.x - s.screenPoint.x);

    return direction === ScreenDirections.RIGHT ? r[0] : r[r.length - 1];
}

export function getAngle(p1, p2) {
    return Math.atan2(p1.x * p2.y - p1.y * p2.x, p1.x * p2.x + p1.y * p2.y);
}

export function getPlane(position, screenPosition, viewer) {
    const camera = viewer.scene.camera;
    const a = Plane.fromPointNormal(position, Ellipsoid.WGS84.geodeticSurfaceNormal(position));
    const l = new Cartesian2(viewer.canvas.offsetWidth / 2, screenPosition.y);
    const c = camera.getPickRay(l);
    const point = c ? utils.rayPlane(c, a, new Cartesian3()) : undefined;

    if (!point) return;

    const m = Plane.projectPointOntoPlane(a, camera.position);
    const _ = Cartesian3.subtract(m, point, new Cartesian3());
    const normal = Cartesian3.normalize(_, new Cartesian3());

    return Plane.fromPointNormal(point, normal);
}

export function getPositions(positions, position, angle, scale) {
    scale = defaultValue(scale, 1);

    const r = [];
    const a = Matrix3.fromRotationZ(angle);
    const s = Matrix4.fromRotationTranslation(a);

    for (let l = 0; l < positions.length; l += 1) {
        const c = Cartesian3.multiplyByScalar(positions[l], scale, new Cartesian3());
        const h = Matrix4.multiplyByPoint(s, c, new Cartesian3());

        r[l] = Cartesian3.add(position, h, new Cartesian3());
    }

    return r;
}

export function getPositionsArr(options) {
    const t = options.index;
    const n = options.polygon;
    const r = t;
    const s = options.points[t];

    let l = n.getLine(t === 0 ? n.linesCount - 1 : t - 1)?.reversed;
    let c = n.getLine(r);

    if (!l || !c) {
        console.error("!left || !rightline");
        return;
    }

    if (n.isClockwise) {
        const L = l;
        l = c;
        c = L;
    }

    const f = c.vectorNormalized;
    let m = getAngle(f, l.vectorNormalized);

    if (m < 0) {
        m = 2 * Math.PI + m;
    }

    const _ = [];
    const v = [];
    const A = Math.PI / 18;
    let x = m / 2;

    for (; x >= 0; x -= A) {
        const L = Math.cos(x);
        const U = Math.sin(x);

        _.push(new Cartesian3(L, U, 0));
    }

    if (x < 0) {
        const L = Math.cos(0);
        const U = Math.sin(0);

        _.push(new Cartesian3(L, U, 0));
    }

    for (x = m / 2; x <= m; x += A) {
        const L = Math.cos(x);
        const U = Math.sin(x);

        v.push(new Cartesian3(L, U, 0));
    }

    if (x > m) {
        const L = Math.cos(m);
        const U = Math.sin(m);

        v.push(new Cartesian3(L, U, 0));
    }

    const b = getAngle(Cartesian3.UNIT_X, f);
    const T = s.clone();
    const S = 0.2 * Math.min(l.length, c.length);

    T.z = options.height;

    return [
        getPositions(_, T, b, S).map((L) => Matrix4.multiplyByPoint(options.modelMatrix, L, new Cartesian3())),
        getPositions(v, T, b, S).map((L) => Matrix4.multiplyByPoint(options.modelMatrix, L, new Cartesian3()))
    ];
}

export function pickObject(event, viewer) {
    if (event.picked) {
        return event.picked;
    }

    try {
        if ("endPosition" in event) {
            const n = viewer.scene.pick(event.endPosition);
            event.picked = n;
            return n;
        }
        if ("position" in event) {
            const n = viewer.scene.pick(event.position);
            event.picked = n;

            return n;
        }
    } catch {
        console.error("Cannot obtain event position for getPicked()");
    }
}

export function setMarginLeftFloat(element, labelAlignment) {
    const { style: n } = element;

    if (labelAlignment === LabelAlignments.trailing) {
        n.marginLeft = "-100%";
        n.float = "left";
    } else {
        n.marginLeft = "";
        n.float = "";
    }
}

export default class LineInfo {
    constructor(axe, positions, toWorld, scene) {
        this.axe = axe;
        this.line = positions;

        const localCenter = calcCenterPoint(positions);
        const worldCenter = Matrix4.multiplyByPoint(toWorld, localCenter, new Cartesian3());

        this.middlePoint2d = SceneTransforms.wgs84ToWindowCoordinates(scene, worldCenter);
    }
}

import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { Box, Select, MenuItem, FormControl } from 'ca-react-component-lib';

/* jshint ignore:start */
export default function ColorPicker({ color, setColor }) {
  const colors = [
    "#000000",
    "#666666",
    "#f44336",
    "#ff9800",
    "#ffeb3b",
    "#4caf50",
    "#03a9f4",
    "#673ab7",
    "#212121",
    "#bcc1c9",
    "#ef9a9a",
    "#ffcc80",
    "#fff59d",
    "#a5d6a7",
    "#81d4fa",
    "#b39ddb"
  ];

  return (<FormControl variant="standard" sx={{ justifyContent: "center" }}>
      <Select
        className="colorPicker"
        labelId="colorPicker"
        value={color || colors[0]}
        onChange={(e) => setColor(e.target.value)}
        renderValue={(backgroundColor) => (
          <Box className="colorPickerColor" sx={{ backgroundColor }} />
        )}
      >
        {colors.map((backgroundColor) => (
          <MenuItem
            key={backgroundColor}
            value={backgroundColor}
          >
            <Box className={(backgroundColor === color) ? "colorPickerBigColor active" : "colorPickerBigColor"} sx={{ backgroundColor }} />
          </MenuItem>
        ))}
      </Select>
  </FormControl>);
}
/* jshint ignore:end */
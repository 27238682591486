import SettingsButtonViewModel from "./SettingsButtonViewModel.js";
import DistanceUnits from "../Measure/DistanceUnits.js";
import getElement from "@cesium/engine/Source/DataSources/getElement.js";
import { defined, DeveloperError, destroyObject } from "@cesium/engine";
import InspectorShared from "@cesium/widgets/Source/InspectorShared.js";
import knockout from "@cesium/widgets/Source/ThirdParty/knockout.js";

/**
 * A single button widget that shows a configuration window.
 *
 * @alias SettingsButton
 * @constructor
 *
 * @param {Element|String} container The DOM element or ID that will contain the widget.
 * @param {Viewer} viewer The Scene instance to use.
 * @param {Cesium3DTileset} tileset The current tileset
 * @param {Boolean} georef Whether the model is georeferenced or not
 */
function SettingsButton(container, viewer, tileset, georef) {
  //>>includeStart('debug', pragmas.debug);
  if (!defined(container)) {
    throw new DeveloperError("container is required.");
  }
  //>>includeEnd('debug');

  container = getElement(container);

  const viewModel = new SettingsButtonViewModel(container, viewer?.scene, tileset, georef);
  const createCheckbox = InspectorShared.createCheckbox;

  viewModel._svgPath = "M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z";

  const element = document.createElement("button");
  element.type = "button";
  element.className = "cesium-button cesium-toolbar-button cesium-settings-button";
  element.setAttribute(
    "data-bind",
    "\
    attr: { title: tooltip },\
    click: command,\
    cesiumSvgPath: { path: _svgPath, width: 512, height: 512 }"
  );

  const settingsWindow = document.createElement("div");
  settingsWindow.id = "cesium-settings-widget";

  container.appendChild(element);

  settingsWindow.appendChild(createCheckbox("Disable Collision Detection", "settingsCollisionDetection"));
  settingsWindow.appendChild(createCheckbox("Disable Anti-aliasing", "settingsAA"));
  settingsWindow.appendChild(createCheckbox("Enable Compass", "settingsEnableCompass"));
  settingsWindow.appendChild(createCheckbox("Enable Clipping Planes Editor", "settingsClippingPlanesEditor"));

  const select = document.createElement("select");
  select.id = "select-measurement";


  for (const unit in DistanceUnits) {
    const option = document.createElement("option");
    option.value = unit;
    option.text = unit;
    select.appendChild(option);
  }
  select.onchange = (e) => {
    localStorage.setItem('measurementUnits', e.target.value);
    viewer.measure.viewModel.units.distanceUnits = DistanceUnits[e.target.value] || DistanceUnits.FEET;
  };
  select.value = localStorage.getItem("measurementUnits") || DistanceUnits.FEET;

  const label = document.createElement("label");
  label.setAttribute("for", "select-measurement");
  label.innerHTML = "Units: ";

  settingsWindow.appendChild(label);
  settingsWindow.appendChild(select);
  container.appendChild(settingsWindow);

  knockout.applyBindings(viewModel, element);
  knockout.applyBindings(viewModel, settingsWindow);

  this._container = container;
  this._viewModel = viewModel;
  this._element = element;
}

Object.defineProperties(SettingsButton.prototype, {
  /**
   * Gets the parent container.
   * @memberof SettingsButton.prototype
   *
   * @type {Element}
   */
  container: {
    get: function () {
      return this._container;
    },
  },

  /**
   * Gets the view model.
   * @memberof SettingsButton.prototype
   *
   * @type {SettingsButtonViewModel}
   */
  viewModel: {
    get: function () {
      return this._viewModel;
    },
  },
});

/**
 * @returns {Boolean} true if the object has been destroyed, false otherwise.
 */
SettingsButton.prototype.isDestroyed = function () {
  return false;
};

/**
 * Destroys the widget.  Should be called if permanently
 * removing the widget from layout.
 */
SettingsButton.prototype.destroy = function () {
  knockout.cleanNode(this._element);
  this._container.removeChild(this._element);

  return destroyObject(this);
};
export default SettingsButton;

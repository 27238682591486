import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import ReactDOM from 'react-dom/client';
import Header from './Header.js';
import Layers from './Layers.js';
import Comments from './Comments.js';
import Measurements from './Measurements.js';
import { ThemeProvider } from 'ca-react-component-lib';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Box from "../Widgets/Box.js";
import { Color } from "@cesium/engine";

/* jshint ignore:start */
const layerNames = ["collection", "comment", "measurement", "primitive", "clippingPlane"];

export const getMaxId = (assessmentId) => {
  const layers = layerNames.reduce((acc, layerName) => {
    localStorage.getItem(layerName) && acc.push(
      ...JSON.parse(localStorage.getItem(layerName)).filter(c => c.assessmentId === assessmentId).sort((a, b) => a.id - b.id)
    );
    return acc;
  }, []);
  const maxId = Math.max(...layers.map(l => Math.max(l.id || 0, l.index || 0)));
  return (maxId > 0 ? maxId : 0) + 1;
};

export default function ReactUI({ viewer, tileset, assessmentId }) {
  const ReactUI = () => {
    const enableDBSync = React.useRef(false); // this ref is used to prevent sending saveCesiumLayers event on initial load
    const [mode, setMode] = React.useState("move");
    const [commentToolActive, setCommentToolActive] = React.useState(false);
    const [collections, setCollections] = React.useState(
      localStorage.getItem("collection") ?
        JSON.parse(localStorage.getItem("collection")).filter(c => c.assessmentId === assessmentId).sort((a, b) => a.id - b.id)
        : []
    );
    const [comments, setComments] = React.useState(
      localStorage.getItem("comment") ?
        JSON.parse(localStorage.getItem("comment")).filter(c => c.assessmentId === assessmentId).sort((a, b) => a.id - b.id)
        : []
    );
    const [measurements, setMeasurements] =  React.useState(
      localStorage.getItem("measurement") ?
        JSON.parse(localStorage.getItem("measurement")).filter(c => c.assessmentId === assessmentId).sort((a, b) => a.id - b.id)
        : []
    );
    const [primitives, setPrimitives] =  React.useState(
      localStorage.getItem("primitive") ?
        JSON.parse(localStorage.getItem("primitive")).filter(c => c.assessmentId === assessmentId).sort((a, b) => a.id - b.id)
        : []
    );
    const [clippingPlanes, setClippingPlanes] =  React.useState(
      localStorage.getItem("clippingPlane") ?
        JSON.parse(localStorage.getItem("clippingPlane")).filter(c => c.assessmentId === assessmentId).sort((a, b) => a.id - b.id)
        : []
    );
    const [showMeasurement, setShowMeasurement] = React.useState(false);
    const [showPrimitive, setShowPrimitive] = React.useState(false);

    const getPayload = () => ({
      measurements,
      collections,
      comments,
      clippingPlanes,
      primitives,
    })

    React.useEffect(() => {
      // render previously saved primitives
      const cesiumTools = window.cesiumTools;
      const existingEntities = JSON.parse(localStorage.getItem("primitive"))?.filter(c => c.assessmentId === assessmentId)?.sort((a, b) => a.id - b.id) || [];

      existingEntities.length && cesiumTools.entitiesCreated(existingEntities.map(eE => new Box({
        id: eE.id,
        name: eE.title,
        color: Color.fromCssColorString(eE.color ? eE.color : "#03a9f4").withAlpha(eE.opacity >= 0 ? eE.opacity : 0.3),
        viewer: viewer,
        isBox: true,
        dimensions: eE.primitive.dimensions,
        position: eE.primitive.position,
        modelMatrix: eE.primitive.modelMatrix,
        orderIndex: -1,
        locked: false,
        visible: !eE.hide || !(showPrimitive.collection && !collections.find(col => col.id === showPrimitive.collection)?.hide),
        labelService: cesiumTools.labelService,
      })));

      // add listener for primitive creation
      window.addEventListener("newPrimitive", (e) => {
        setMode("move");
        const newPrimitive = e.detail;

        setPrimitives((currentPrimitives) => {
          const newId = getMaxId(assessmentId);
          const renderedPrimitive = window.cesiumTools.getEntityById(newPrimitive.id);
          renderedPrimitive.id = newId;

          return [...currentPrimitives, {
            id: newId,
            index: newId,
            color: "#03a9f4",
            opacity: 0.5,
            assessmentId,
            title: newPrimitive.name,
            camera: {
              position: viewer.scene.camera.positionWC.clone(),
              direction: viewer.scene.camera.direction.clone(),
              up: viewer.scene.camera.up.clone(),
              transform: viewer.scene.camera.transform.clone(),
            },
            primitive: {
              dimensions: {...newPrimitive.dimensions, showDimensions: false},
              position: newPrimitive.position,
              modelMatrix: newPrimitive.modelMatrix,
            }
          }];
        });
      });

      // add listener for primitive transformation
      window.addEventListener("updatePrimitive", (e) => {
        setPrimitives((currentPrimitives) => currentPrimitives.map((cP) => {
          const updatedPrimitive = e.detail;
          if (cP.id === updatedPrimitive.id) {
            cP.camera = {
              position: viewer.scene.camera.positionWC.clone(),
              direction: viewer.scene.camera.direction.clone(),
              up: viewer.scene.camera.up.clone(),
              transform: viewer.scene.camera.transform.clone(),
            };
            cP.primitive = {
              dimensions: updatedPrimitive.dimensions,
              position: updatedPrimitive.position,
              modelMatrix: updatedPrimitive.modelMatrix,
            };
          }
          return cP;
        }));
      });
      // update mode when transform editor is activated/deactivated
      window.addEventListener("transformEditorToggled", (e) => {
        const active = e.detail?.active;
        !active && setMode( "move");
      });
      window.addEventListener("setMode", (e) => {
        setMode( e.detail);
      });
      window.addEventListener("syncCesiumLayersFromDB", (e) => {
        enableDBSync.current = false;
        setCollections(e.detail.collections || []);
        setComments(e.detail.comments || []);
        setMeasurements(e.detail.measurements || []);
        setPrimitives(e.detail.primitives || []);
        setClippingPlanes(e.detail.clippingPlanes || []);
        setTimeout(() => enableDBSync.current = true, 0);
      });
    }, []);

    React.useEffect(() => {
      // deactivate primitive editor if mode changed
      if (mode !== "primitive") {
        try {
          window.cesiumTools._currentTool?._removeFootprintRectangle();
          window.cesiumTools._currentTool?.boxEntity?.removeFromCesium(viewer);
          window.cesiumTools.deselectAll();
        } catch (e) {
          // console.log(e);
        }
        viewer.scene.requestRender();
      }
      setShowMeasurement(false);
    }, [mode]);

    React.useEffect(() => {
      localStorage.setItem(
        "comment",
        JSON.stringify([
          ...comments,
          ...(localStorage.getItem("comment") ?
            JSON
              .parse(localStorage.getItem("comment"))
              .filter(c => c.assessmentId !== assessmentId && !comments.find((newComment) => c.id === newComment.id))
            : []
          )
        ])
      );
      enableDBSync.current && window.dispatchEvent(new CustomEvent("saveCesiumLayers", { detail: getPayload() }));
    }, [comments]);

    React.useEffect(() => {
      localStorage.setItem(
        "collection",
        JSON.stringify([
          ...collections,
          ...(localStorage.getItem("collection") ?
            JSON
              .parse(localStorage.getItem("collection"))
              .filter(c => c.assessmentId !== assessmentId && !collections.find((newCollection) => c.id === newCollection.id))
            : []
          )
        ])
      );
      enableDBSync.current && window.dispatchEvent(new CustomEvent("saveCesiumLayers", { detail: getPayload() }));
    }, [collections]);

    React.useEffect(() => {
      localStorage.setItem(
        "measurement",
        JSON.stringify([
          ...measurements,
          ...(localStorage.getItem("measurement") ?
            JSON
              .parse(localStorage.getItem("measurement"))
              .filter(c => c.assessmentId !== assessmentId && !measurements.find((newMeasurement) => c.id === newMeasurement.id))
            : []
          )
        ])
      );
      enableDBSync.current && window.dispatchEvent(new CustomEvent("saveCesiumLayers", { detail: getPayload() }));
    }, [measurements]);

    React.useEffect(() => {
      const handleSetShowPrimitive = (e) => {
        const primitiveId = e.detail.id;
        setShowPrimitive(primitives.find(x => x.id === primitiveId));
      };

      localStorage.setItem(
        "primitive",
        JSON.stringify([
          ...primitives,
          ...(localStorage.getItem("primitive") ?
            JSON
              .parse(localStorage.getItem("primitive"))
              .filter(c => c.assessmentId !== assessmentId && !primitives.find((newPrimitive) => c.id === newPrimitive.id))
            : []
          )
        ])
      );
      enableDBSync.current && window.dispatchEvent(new CustomEvent("saveCesiumLayers", { detail: getPayload() }));
      window.addEventListener("setShowPrimitive", handleSetShowPrimitive);
      return () => {
        window.removeEventListener("setShowPrimitive", handleSetShowPrimitive);
      }
    }, [primitives]);

    React.useEffect(() => {
      localStorage.setItem(
        "clippingPlane",
        JSON.stringify([
          ...clippingPlanes,
          ...(localStorage.getItem("clippingPlane") ?
            JSON
              .parse(localStorage.getItem("clippingPlane"))
              .filter(c => c.assessmentId !== assessmentId && !clippingPlanes.find((newCP) => c.id === newCP.id))
            : []
          )
        ])
      );
      enableDBSync.current && window.dispatchEvent(new CustomEvent("saveCesiumLayers", { detail: getPayload() }));
      enableDBSync.current = true;
    }, [clippingPlanes]);

    return (
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider>
          <Header
            viewer={viewer}
            tileset={tileset}
            assessmentId={assessmentId}
            mode={mode}
            setMode={setMode}
            commentToolActive={commentToolActive}
            setCommentToolActive={setCommentToolActive}
            showMeasurement={showMeasurement}
            setShowMeasurement={setShowMeasurement}
            setClippingPlanes={setClippingPlanes}
          />
          <Layers
            viewer={viewer}
            assessmentId={assessmentId}
            collections={collections}
            setCollections={setCollections}
            comments={comments}
            setComments={setComments}
            measurements={measurements}
            setMeasurements={setMeasurements}
            primitives={primitives}
            setPrimitives={setPrimitives}
            setMode={setMode}
            showMeasurement={showMeasurement}
            setShowMeasurement={setShowMeasurement}
            showPrimitive={showPrimitive}
            setShowPrimitive={setShowPrimitive}
            clippingPlanes={clippingPlanes}
            setClippingPlanes={setClippingPlanes}
          />
          <Comments
            viewer={viewer}
            assessmentId={assessmentId}
            setMode={setMode}
            collections={collections}
            measurements={measurements}
            comments={comments}
            setComments={setComments}
            primitives={primitives}
            toolActive={commentToolActive}
            setToolActive={setCommentToolActive}
          />
          <Measurements
            viewer={viewer}
            mode={mode}
            setMode={setMode}
            assessmentId={assessmentId}
            collections={collections}
            comments={comments}
            measurements={measurements}
            primitives={primitives}
            setMeasurements={setMeasurements}
            showMeasurement={showMeasurement}
            setShowMeasurement={setShowMeasurement}
          />
        </ThemeProvider>
      </DndProvider>
    );
  };

  const root = ReactDOM.createRoot(Cesium.getElement('reactUI'));
  root.render(<ReactUI />);
};

/* jshint ignore:end */
import { AxisAlignedBoundingBox, BoundingSphere, Cartesian3, DeveloperError, OrientedBoundingBox } from "../../Core/cesium/Source/Cesium.js";

export default class TilesetAssetGroup {
  constructor(options) {
    this._assets = options.assets;
  }

  getBoundingSphere(result) {
    const boundingSpheres = [];

    this._assets.forEach((asset) => {
      boundingSpheres.push(asset.tileset.boundingSphere);
    });

    return BoundingSphere.fromBoundingSpheres(boundingSpheres, result);
  }

  getAABoundingBox(result) {
    const positions = [];

    this._assets.forEach((asset) => {
      const tileset = asset.tileset;

      // @ts-ignore
      const boundingVolume = tileset?.root.boundingVolume.boundingVolume;

      if (boundingVolume instanceof OrientedBoundingBox) {
        // @ts-ignore
        const corners = OrientedBoundingBox.computeCorners(boundingVolume);

        corners.forEach((corner) => {
          positions.push(corner);
        });
      } else if (boundingVolume instanceof BoundingSphere) {
        positions.push(boundingVolume.center);
        const center = boundingVolume.center;
        const radius = boundingVolume.radius;

        const min = new Cartesian3(center.x - radius, center.y - radius, center.z - radius);
        const max = new Cartesian3(center.x + radius, center.y + radius, center.z + radius);

        positions.push(center);
        positions.push(min);
        positions.push(max);
      } else {
        throw new DeveloperError("unexpected boundingVolume");
      }
    });

    return AxisAlignedBoundingBox.fromPoints(positions, result);
  }

  get assets() {
    return this._assets;
  }

  get length() {
    return this._assets.length;
  }
}
import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import {
  FontAwesome,
  Box,
  IconButton,
  List,
} from "ca-react-component-lib";
import LayersCollection from "./LayersCollection.js";
import MeasurementInfo from "./MeasurementInfo.js";
import PrimitiveInfo from "./PrimitiveInfo.js";
import LayersItem from "./LayersItem.js";
import { getMaxId } from "./ReactUI.js";

/* jshint ignore:start */
export default function LayersCollections({
  viewer,
  assessmentId,
  collections,
  setCollections,
  comments,
  setComments,
  measurements,
  setMeasurements,
  primitives,
  setPrimitives,
  showMeasurement,
  setShowMeasurement,
  showPrimitive,
  setShowPrimitive,
  clippingPlanes,
  setClippingPlanes,
  setMode,
}) {
  const [activeCollection, setActiveCollection] = React.useState(collections[0]?.id || undefined);

  const newCollection = () => {
    const newId = getMaxId(assessmentId);
    setCollections(
      [...collections,
          {
            id: newId,
            name: "Collection #"+newId,
            assessmentId
          }
      ]
    )
    setActiveCollection(newId);
  };

  const layerItems = [...comments, ...measurements, ...primitives, ...clippingPlanes];

  return (<>
    <MeasurementInfo
      viewer={viewer}
      measurements={measurements}
      setMeasurements={setMeasurements}
      showMeasurement={showMeasurement}
      setShowMeasurement={setShowMeasurement}
    />
    <PrimitiveInfo
      viewer={viewer}
      setPrimitives={setPrimitives}
      showPrimitive={showPrimitive}
      setShowPrimitive={setShowPrimitive}
    />
    <Box
      className="collections"
      sx={{ px: 3, py: 1.5, mb: 1}}
    >
        <Box className="collectionsTitle">Collections</Box>
        <IconButton onClick={() => newCollection()}><FontAwesome icon="plus" /></IconButton>
    </Box>
    <List className="layers">
      {collections.map((collection) => <LayersCollection
        key={"collection_"+collection.id}
        viewer={viewer}
        collection={collection}
        activeCollection={activeCollection}
        setActiveCollection={setActiveCollection}
        collections={collections}
        setCollections={setCollections}
        comments={comments}
        setComments={setComments}
        measurements={measurements}
        setMeasurements={setMeasurements}
        primitives={primitives}
        setPrimitives={setPrimitives}
        showMeasurement={showMeasurement}
        setShowMeasurement={setShowMeasurement}
        showPrimitive={showPrimitive}
        setShowPrimitive={setShowPrimitive}
        clippingPlanes={clippingPlanes}
        setClippingPlanes={setClippingPlanes}
        setMode={setMode}
      />)}
      {layerItems.filter(x => !x.collection).sort((a, b) => a.index - b.index).map((layerItem) => <LayersItem
        key={(layerItem.measurement ? "measurement_" : layerItem.primitive ? "primitive_" : "comment_")+layerItem.id}
        viewer={viewer}
        item={layerItem}
        comments={comments}
        setComments={setComments}
        measurements={measurements}
        setMeasurements={setMeasurements}
        primitives={primitives}
        setPrimitives={setPrimitives}
        showMeasurement={showMeasurement}
        setShowMeasurement={setShowMeasurement}
        setShowPrimitive={setShowPrimitive}
        clippingPlanes={clippingPlanes}
        setClippingPlanes={setClippingPlanes}
        setMode={setMode}
      />)}
    </List>
  </>);
}
/* jshint ignore:end */
import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import {
  FontAwesome,
  Box,
  Drawer,
  List,
  ListItem,
  Button,
  IconButton,
  Divider,
  Typography,
  TextField,
} from "ca-react-component-lib";
import ColorPicker from "./ColorPicker.js";
import { Cartesian3, Cartographic, Color, Math as CesiumMath } from "@cesium/engine";
import MeasureUnits from "../Widgets/Measure/MeasureUnits.js";
import DXFDrawing from "../Widgets/ExportDXF/DXFDrawing.js";
import proj4 from "proj4";
import saveStringAsTextFile from "../Widgets/saveStringAsTextFile.js";

const EPSG2056Proj4String =
  "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs ";

/* jshint ignore:start */
export default function MeasurementInfo({ viewer, measurements, setMeasurements, showMeasurement, setShowMeasurement }) {
  const [info, setInfo] = React.useState({
    distanceLength: 0,
    horizontalLength: 0,
    elevationDifference: 0,
    area: 0
  });

  React.useEffect(() => {
    setTimeout(() => { // execute on the next tick when selectedMeasurement is available
      const selectedMeasurement = viewer?.measure?.viewModel?.selectedMeasurement;

      if (showMeasurement?.measurement?.pos1 && showMeasurement?.measurement?.pos2 && selectedMeasurement) {
        const distanceLength = MeasureUnits.distanceToString(
          Cartesian3.distance(
            new Cartesian3(showMeasurement.measurement.pos1.x, showMeasurement.measurement.pos1.y, showMeasurement.measurement.pos1.z),
            new Cartesian3(showMeasurement.measurement.pos2.x, showMeasurement.measurement.pos2.y, showMeasurement.measurement.pos2.z)
          ),
          selectedMeasurement?._selectedUnits?.distanceUnits,
          selectedMeasurement?._selectedLocale
        );
        const horizontalLength = MeasureUnits.distanceToString(
          Cartesian3.distance(
            new Cartesian3(showMeasurement.measurement.pos1.x, showMeasurement.measurement.pos1.y, 0),
            new Cartesian3(showMeasurement.measurement.pos2.x, showMeasurement.measurement.pos2.y, 0)
          ),
          selectedMeasurement?._selectedUnits?.distanceUnits,
          selectedMeasurement?._selectedLocale
        );
        const elevationDifference = MeasureUnits.distanceToString(
          Cartesian3.distance(
            new Cartesian3(0, 0, showMeasurement.measurement.pos1.z),
            new Cartesian3(0, 0, showMeasurement.measurement.pos2.z)
          ),
          selectedMeasurement?._selectedUnits?.distanceUnits,
          selectedMeasurement?._selectedLocale
        );

        const area = showMeasurement.measurement.area ? MeasureUnits.areaToString(
          showMeasurement.measurement.area,
          selectedMeasurement?._selectedUnits?.areaUnits,
          selectedMeasurement?._selectedLocale
        ) : 0;

        setInfo({distanceLength, horizontalLength, elevationDifference, area});
      }
    }, 0);
  }, [showMeasurement]);

  const closeDrawer = () => {
    viewer.measure?.viewModel?.selectedMeasurement?.reset();
    setShowMeasurement(false);
  };

  const updateTitle = (title) => {
    setMeasurements(measurements.map(m => {
      if (m.id === showMeasurement.id) {
        m.measurement.title = title;
      }
      return m;
    }));
  }

  const updateColor = (color) => {
    setMeasurements(measurements.map(m => {
      if (m.id === showMeasurement.id) {
        m.measurement.color = color;
      }
      return m;
    }));

    let colorTarget = viewer?.measure?.viewModel?.selectedMeasurement;
    while (colorTarget) {
      if (colorTarget._points) {
        colorTarget._points.map(p => {
          if (p) p.color = Color.fromCssColorString(color);
          return p;
        });
      }
      if (colorTarget._pointOptions) {
        colorTarget._pointOptions.color = Color.fromCssColorString(color);
      }
      if (colorTarget._polygon) {
        colorTarget._polygon.color = Color.fromCssColorString(color);
      }
      if (colorTarget._polyline) {
        colorTarget._polyline.color = Color.fromCssColorString(color);
      }
      if (colorTarget._startPoint) {
        colorTarget._startPoint.color = Color.fromCssColorString(color);
      }
      if (colorTarget._endPoint) {
        colorTarget._endPoint.color = Color.fromCssColorString(color);
      }
      colorTarget = colorTarget._drawing ? colorTarget._drawing : undefined;
    }
  };

  const exportAsDXF = () => {
    const selectedMeasurement = viewer?.measure?.viewModel?.selectedMeasurement;
    const extent = {
      minX: Number.POSITIVE_INFINITY,
      minY: Number.POSITIVE_INFINITY,
      maxX: Number.NEGATIVE_INFINITY,
      maxY: Number.NEGATIVE_INFINITY
    };

    const dxfDrawing = new DXFDrawing();

    const unit = selectedMeasurement._selectedUnits.distanceUnits.toLowerCase();
    dxfDrawing.setUnits(unit.charAt(0).toUpperCase() + unit.slice(1)); // Capitalize instead of UPPERCASE

    const coordConvertFunc = (position) => {
      const carto = Cartographic.fromCartesian(position);

      const longitude = CesiumMath.toDegrees(carto.longitude);
      const latitude = CesiumMath.toDegrees(carto.latitude);

      return proj4(EPSG2056Proj4String, [longitude, latitude]);
    };

    const layerName = showMeasurement.measurement.title || "Measurement";

    dxfDrawing.addLayer(layerName, 0xffd700, "CONTINUOUS");
    dxfDrawing.setActiveLayer(layerName);
    dxfDrawing.setTrueColor(0xffd700); // gold

    const positions = selectedMeasurement._positions || selectedMeasurement._drawing._positions;
    const points = [];
console.log(selectedMeasurement);
    positions.forEach((position) => {
      const coord = coordConvertFunc(position);

      const x = coord[0];
      const y = coord[1];

      if (x < extent.minX) {
        extent.minX = x;
      }

      if (x > extent.maxX) {
        extent.maxX = x;
      }

      if (y < extent.minY) {
        extent.minY = y;
      }

      if (y > extent.maxX) {
        extent.maxY = y;
      }

      points.push(coord);
    });

    dxfDrawing.drawPolyline(points, false, 1, 1);
    points.forEach((point) => {
      dxfDrawing.drawCircle(point[0], point[1], 1);
    });

    dxfDrawing.addLayer("Labels", 0, "CONTINUOUS");
    dxfDrawing.setActiveLayer("Labels");
    dxfDrawing.setTrueColor(0);

    if (selectedMeasurement._label?.text) {
      const position = selectedMeasurement._label?.position || selectedMeasurement._drawing._label.position;
      const coord = coordConvertFunc(position);
      dxfDrawing.drawText(coord[0] + 5, coord[1], 2, 0, selectedMeasurement._label?.text);
    } else {
      const labels = selectedMeasurement._drawing?._segmentLabels;

      labels && labels.forEach((label) => {
        const coord = coordConvertFunc(label.position);
        dxfDrawing.drawText(coord[0], coord[1], 2, 0, label.text);
      });
    }

    dxfDrawing.setExtent(extent.minX, extent.minY, extent.maxX, extent.maxY);

    saveStringAsTextFile(dxfDrawing.toDxfString(), layerName.replace(/[^a-z0-9]/gi, '_')+".dxf");
  };

  return (
      <Drawer
        hideBackdrop
        anchor="right"
        open={!!showMeasurement}
        className="toolbarDrawer toolbarDrawerSlim"
        onClose={() => closeDrawer()}
        ModalProps={{
          disableEnforceFocus: true,
        }}
      >
        <List>
          <ListItem>
            {showMeasurement?.measurement?.icon && <FontAwesome
              icon={showMeasurement?.measurement?.icon}
              sx={{ mr: 2 }}
            />}
            <TextField
              value={showMeasurement?.measurement?.title !== undefined ? showMeasurement?.measurement?.title : showMeasurement?.measurement?.type + " #" + showMeasurement?.id}
              variant="standard"
              className="drawerTitle"
              onChange={(e) => updateTitle(e.target.value)}
            />
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => closeDrawer()} sx={{ mr: -1 }}>
              <FontAwesome icon="xmark" />
            </IconButton>
          </ListItem>
        </List>

        <Divider />

        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between"}}>
            <Typography variant='subtitle1' sx={{ my: 2 }}>Color</Typography>
            <ColorPicker color={showMeasurement?.measurement?.color || "#ffeb3b"} setColor={(color) => updateColor(color)} />
          </Box>
          <Divider />
          <Typography variant='subtitle1' sx={{ my: 2 }}>Properties</Typography>
          {showMeasurement?.measurement?.area ?
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <Typography>Area</Typography>
              <Typography>{info.area}</Typography>
            </Box>
          : <>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                <Typography>Length</Typography>
                <Typography>{info.distanceLength}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                <Typography>Horizontal Length</Typography>
                <Typography>{info.horizontalLength}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                <Typography>Elevation difference</Typography>
                <Typography>{info.elevationDifference}</Typography>
              </Box>
          </>}
        </Box>

        <Divider />

        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button variant="contained" onClick={() => exportAsDXF()}>
            <FontAwesome icon="download" sx={{ mr: 3 }} />
            Export as DXF
          </Button>
        </Box>
      </Drawer>
  );
}
/* jshint ignore:end */
import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import ClippingBox from "../Widgets/ClippingBox/ClippingBox";
import TilesetAsset from "../Widgets/TilesetAsset";
import TilesetAssetGroup from "../Widgets/TilesetAssetGroup";
import { FontAwesome, Box, MenuPopover, Button, ICON } from 'ca-react-component-lib';
import { getMaxId } from "./ReactUI.js";

/* jshint ignore:start */
export default function ClippingPlanesButton({ viewer, tileset, assessmentId, setClippingPlanes }) {
  const [open, setOpen] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const clippingBox = React.useRef(null);

  const activateClippingBox = () => {
    if (!tileset.ready || clippingBox.current?.active) return;
    clippingBox.current = new ClippingBox({ viewer });
    window.cesiumClippingBox = clippingBox.current;
    const asset = new TilesetAsset({
      scene: viewer.scene,
      tileset,
    });
    const tilesetAssetGroup = new TilesetAssetGroup({
      assets: [asset]
    });

    clippingBox.current.activate(tilesetAssetGroup);
    clippingBox.current._slicerArrows._updateArrows();
    setEnabled(true);
  };

  const showClippingPlanes = (show = true) => {
    if (clippingBox.current?.active) {
      clippingBox.current.reset();
      clippingBox.current.enabled = show;
      clippingBox.current.showHideClippingWalls(show);
      clippingBox.current.showEditControls(show);
      setEnabled(show);
      return;
    }

    activateClippingBox();
  };

  React.useEffect(() => {
    window.addEventListener("activateClippingBox", showClippingPlanes);

    return () => window.removeEventListener("activateClippingBox", showClippingPlanes);
  }, []);

  const toggleClippingPlanes = () => {
    const show = !clippingBox.current._clippingBoxEntity.show;
    clippingBox.current.showEditControls(show);
    clippingBox.current.showHideClippingWalls(show);
  };

  const resetClippingPlanes = () => {
    clippingBox.current.showEditControls(true);
    clippingBox.current.showHideClippingWalls(true);
    clippingBox.current.reset();
    clippingBox.current._slicerArrows._updateArrows();
  };

  const saveClippingPlanes = () => {
    const newId = getMaxId(assessmentId);
    setClippingPlanes((currentPlanes) => ([
      ...currentPlanes,
      {
        id: newId,
        index: newId,
        title: `Clipping Planes #${newId}`,
        assessmentId,
        transform: clippingBox.current._transform.clone(),
        corners: {
          bottomLeft: clippingBox.current._bottomCorners.bottomLeft.clone(),
          bottomRight: clippingBox.current._bottomCorners.bottomRight.clone(),
          topLeft: clippingBox.current._bottomCorners.topLeft.clone(),
          topRight: clippingBox.current._bottomCorners.topRight.clone(),
        },
        planes: {
          matrix: tileset.clippingPlanes.modelMatrix.clone(),
          distance: tileset.clippingPlanes._planes.map((plane) => plane.distance)
        },
        height: clippingBox.current._height,
        collection: false,
        camera: {
          transform: viewer.scene.camera.transform.clone(),
          position: viewer.scene.camera.positionWC.clone(),
          heading: viewer.scene.camera.heading,
          pitch: viewer.scene.camera.pitch,
          roll: viewer.scene.camera.roll,
        }
      }
    ]));
  };

  return (
    <Box>
      <Button
        className={(open || enabled) ? "toolbarButton active" : "toolbarButton"}
      >
        <FontAwesome
          className="toolbarIconButton"
          icon="crop"
          onClick={() => showClippingPlanes(!enabled)}
        />
        <Box
          className="toolbarChevronButton"
          onClick={(e) => setOpen(e.currentTarget)}
        >
          <FontAwesome
            icon={open ? "chevron-up" : "chevron-down"}
            size={ICON.SMALL}
          />
        </Box>
      </Button>
      <MenuPopover
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(false)}
        sx={{ width: "280px" }}
      >
        <Button
          className="dropdownKeyButton"
          onClick={() => showClippingPlanes(!enabled)}
        >
          <FontAwesome icon="crop" sx={{ mx: 2 }}/>
          Toggle Clipping Planes
          <Box sx={{ flexGrow: 1 }} />
          <div className="keybind"></div>
        </Button>
        <Button
          className="dropdownKeyButton"
          onClick={() => {
            setOpen(false);
            resetClippingPlanes();
          }}
          disabled={!enabled}
        >
          <FontAwesome icon="arrow-rotate-right" type="solid" sx={{ mx: 2 }}/>
          Reset Clipping Planes
          <Box sx={{ flexGrow: 1 }} />
          <div className="keybind"></div>
        </Button>
        <Button
          className="dropdownKeyButton"
          onClick={() => toggleClippingPlanes()}
          disabled={!enabled}
        >
          <FontAwesome icon="eye" type="solid" sx={{ mx: 2 }}/>
          Hide/Show Clipping Planes
          <Box sx={{ flexGrow: 1 }} />
          <div className="keybind"></div>
        </Button>
        <Button
          className="dropdownKeyButton"
          onClick={() => saveClippingPlanes()}
          disabled={!enabled}
        >
          <FontAwesome icon="archive" sx={{ mx: 2 }}/>
          Save Clipping Planes
          <Box sx={{ flexGrow: 1 }} />
          <div className="keybind"></div>
        </Button>
      </MenuPopover>
    </Box>
  );
}
/* jshint ignore:end */
import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { FontAwesome, IconButton } from "ca-react-component-lib";
import { Cartesian3 } from "@cesium/engine";
import DistanceMeasurement from "../Widgets/Measure/DistanceMeasurement.js";
import MeasurementSettings from "../Widgets/Measure/MeasurementSettings.js";
import { getMaxId } from "./ReactUI.js";

/* jshint ignore:start */
export default function Measurements({ viewer, mode, setMode, assessmentId, collections, comments, measurements, setMeasurements, showMeasurement, setShowMeasurement }) {
  const [unselectedMeasurements, setUnselectedMeasurements] = React.useState([]);
  const debounceRender = React.useRef(null);
  const measurementTypes = [
    {
      id: "distanceMeasurement",
      type: "DistanceMeasurement",
      icon: "horizontal-rule"
    },
    {
      id: "componentDistanceMeasurement",
      type: "DistanceMeasurement",
      icon: "draw-polygon"
    },
    {
      id: "polylineMeasurement",
      type: "PolylineDrawing",
      icon: "angle"
    },
    {
      id: "horizontalMeasurement",
      type: "HorizontalPolylineDrawing",
      icon: "ruler-horizontal"
    },
    {
      id: "verticalMeasurement",
      type: "VerticalMeasurement",
      icon: "ruler-vertical"
    },
    {
      id: "areaMeasurement",
      type: "PolygonDrawing",
      icon: "chart-area"
    },
    {
      id: "heightMeasurement",
      type: "HeightMeasurement",
      icon: "line-height"
    },
  ];

  React.useEffect(() => {
    const positionMeasurementDots = () => {
      const selectedMeasurement = viewer.measure?.viewModel?.selectedMeasurement;
      const currentColor = measurements.find(x => x.id === selectedMeasurement?._savedId)?.measurement?.color;

      let measurementStartPoint = selectedMeasurement?._startPoint || undefined;
      if (!measurementStartPoint && selectedMeasurement?._drawing) {
        const pos0 = selectedMeasurement._drawing._positions[0];
        measurementStartPoint = pos0 ? { position: pos0, show: true } : undefined;
      }
      const measurementStartPointDOM = document.getElementById('measurementStartPoint');

      if (measurementStartPoint && measurementStartPointDOM && selectedMeasurement.id !== 'heightMeasurement') {
        const position = viewer.scene.cartesianToCanvasCoordinates(measurementStartPoint.position, new Cartesian3());
        if (position) measurementStartPointDOM.style.top = position.y - (measurementStartPointDOM.clientHeight / 2) + "px";
        if (position) measurementStartPointDOM.style.left = position.x - (measurementStartPointDOM.clientWidth / 2) + "px";
        measurementStartPointDOM.style.display = measurementStartPoint.show ? "block" : "none";
        measurementStartPointDOM.style.color = (
          currentColor === "#000000" ||
          currentColor === "#212121" ||
          currentColor === "#673AB7" ||
          currentColor === "#F44336" ||
          currentColor === "#666666"
        ) ? "#FFFFFF" : "#162231";
      } else if (measurementStartPointDOM) {
        measurementStartPointDOM.style.display = "none";
      }

      let measurementEndPoint = selectedMeasurement?._endPoint || undefined;
      if (!measurementEndPoint && selectedMeasurement?._drawing) {
        const posLast = (selectedMeasurement._drawing._positions?.length > 1) ?
          selectedMeasurement._drawing._positions.at(-1)
          : undefined;
        measurementEndPoint = posLast ? { position: posLast, show: true } : undefined;
      }
      const measurementEndPointDOM = document.getElementById('measurementEndPoint');

      if (measurementEndPoint && measurementEndPointDOM && !measurements.find(x => x.id === selectedMeasurement?._savedId)) {
        const position = viewer.scene.cartesianToCanvasCoordinates(measurementEndPoint.position, new Cartesian3());
        if (position) measurementEndPointDOM.style.top = position.y - (measurementEndPointDOM.clientHeight / 2) + "px";
        if (position) measurementEndPointDOM.style.left = position.x - (measurementEndPointDOM.clientWidth / 2) + "px";
        measurementEndPointDOM.style.display = measurementEndPoint.show ? "block" : "none";
        measurementEndPointDOM.style.color = (
          currentColor === "#000000" ||
          currentColor === "#212121" ||
          currentColor === "#673AB7" ||
          currentColor === "#F44336" ||
          currentColor === "#666666"
        ) ? "#FFFFFF" : "#162231";
      } else if (measurementEndPointDOM) {
        measurementEndPointDOM.style.display = "none";
      }
    }

    if (debounceRender.current) clearTimeout(debounceRender.current);
    debounceRender.current = setTimeout(() => renderUnselectedMeasurements(), 50);

    viewer.scene.preRender.addEventListener(positionMeasurementDots);
    return () => {
      viewer.scene.preRender.removeEventListener(positionMeasurementDots);
    }
  }, [collections, measurements, mode, showMeasurement]);

  const renderUnselectedMeasurements = () => {
    unselectedMeasurements.map(uM => !uM.isDestroyed() && uM.destroy());
    const measurementsToRender = [];
    measurements.map(measurement => {
      if (measurement.id === showMeasurement.id) return;
      const measurementType = viewer.measure.viewModel._measurements.find(x => x.type === measurement.measurement.type);
      if (!measurementType || measurement.hide || (measurement.collection && collections.find(col => col.id === measurement.collection)?.hide)) return;
      const newMeasurement = new measurementType.constructor({
        scene: measurementType._scene,
        units: measurementType.selectedUnits,
        points: measurementType._pointCollection,
        labels: measurementType._labelCollection,
        primitives: measurementType._primitives,
        color: measurement.measurement.color,
        area: measurement.measurement.area,
      });

      newMeasurement._redrawMeasurements({
        detail: {
          componentLines: "componentLines" in measurement.measurement ? measurement.measurement.componentLines : null,
          measurementType: measurement.measurement.measurementType,
          positionsArray: measurement.measurement.positionsArray,
          camera: viewer.scene.camera,
          pos1: measurement.measurement.pos1,
          pos2: measurement.measurement.pos2,
          label: "",
          color: measurement.measurement.color,
          area: measurement.measurement.area,
        },
      });
      measurementsToRender.push(newMeasurement);
    });
    setUnselectedMeasurements(measurementsToRender);
  };

  const hideMeasurement = () => {
    viewer.measure?.viewModel?.selectedMeasurement?.reset();
    setShowMeasurement(false);
  };

  const saveMeasurement = () => {
    const newId = getMaxId(assessmentId);
    const selectedMeasurement = viewer.measure.viewModel.selectedMeasurement;
    selectedMeasurement._savedId = newId;
    let startPoint = selectedMeasurement._startPoint;
    let endPoint = selectedMeasurement._endPoint;
    let positionsArray = selectedMeasurement._positions ? [...selectedMeasurement._positions] : [];
    if (selectedMeasurement._drawing) {
      startPoint = selectedMeasurement._drawing._points[0];
      endPoint = selectedMeasurement._drawing._points.at(-1);
      positionsArray = [...selectedMeasurement._drawing._points.map(p => p.position)];
    }

    const newMeasurement = {
      id: newId,
      index: newId,
      assessmentId,
      measurement: {
        componentLines: "componentLines" in selectedMeasurement ? selectedMeasurement.componentLines : null,
        measurementType: measurementTypes.find(x => x.id === selectedMeasurement.id)?.type || "DistanceMeasurement",
        icon: measurementTypes.find(x => x.id === selectedMeasurement.id)?.icon || "angle",
        type: selectedMeasurement.type,
        pos1: {...startPoint.position},
        pos2: {...endPoint.position},
        positionsArray,
        area: selectedMeasurement.area,
        title: selectedMeasurement.type + " #" + newId,
        camera: {
          position: viewer.scene.camera.positionWC.clone(),
          transform: viewer.scene.camera.transform.clone(),
          direction: viewer.scene.camera.direction.clone(),
          up: viewer.scene.camera.up.clone(),
        },
        color: MeasurementSettings.color.toCssHexString()
      },
    };

    setMeasurements((currentMeasurements) => [...currentMeasurements, newMeasurement]);
    setShowMeasurement(newMeasurement);
  };

  return (<>
    <IconButton
      id="measurementStartPoint"
      onClick={() => hideMeasurement()}
    >
      <FontAwesome icon="xmark" size="small" />
    </IconButton>
    <IconButton
      id="measurementEndPoint"
      onClick={() => { setMode("move"); setTimeout(() => saveMeasurement(), 0)}}
    >
      <FontAwesome icon="check" size="small" />
    </IconButton>
  </>);
}
/* jshint ignore:end */
//This file is automatically rebuilt by the Cesium build process.
export default "#define NO_HIT (-czm_infinity)\n\
#define INF_HIT (czm_infinity * 0.5)\n\
struct Ray {\n\
vec3 pos;\n\
vec3 dir;\n\
};\n\
struct Intersections {\n\
#if (INTERSECTION_COUNT > 1)\n\
vec2 intersections[INTERSECTION_COUNT * 2];\n\
int index;\n\
int surroundCount;\n\
bool surroundIsPositive;\n\
#else\n\
float intersections[2];\n\
#endif\n\
};\n\
#if (INTERSECTION_COUNT > 1)\n\
#define getIntersection(/*inout Intersections*/ ix, /*int*/ index) (ix).intersections[(index)].x\n\
#else\n\
#define getIntersection(/*inout Intersections*/ ix, /*int*/ index) (ix).intersections[(index)]\n\
#endif\n\
#define getIntersectionPair(/*inout Intersections*/ ix, /*int*/ index) vec2(getIntersection((ix), (index) * 2 + 0), getIntersection((ix), (index) * 2 + 1))\n\
#if (INTERSECTION_COUNT > 1)\n\
#define setIntersection(/*inout Intersections*/ ix, /*int*/ index, /*float*/ t, /*bool*/ positive, /*enter*/ enter) (ix).intersections[(index)] = vec2((t), float(!positive) * 2.0 + float(!enter))\n\
#else\n\
#define setIntersection(/*inout Intersections*/ ix, /*int*/ index, /*float*/ t, /*bool*/ positive, /*enter*/ enter) (ix).intersections[(index)] = (t)\n\
#endif\n\
#if (INTERSECTION_COUNT > 1)\n\
#define setIntersectionPair(/*inout Intersections*/ ix, /*int*/ index, /*vec2*/ entryExit) (ix).intersections[(index) * 2 + 0] = vec2((entryExit).x, float((index) > 0) * 2.0 + 0.0); (ix).intersections[(index) * 2 + 1] = vec2((entryExit).y, float((index) > 0) * 2.0 + 1.0)\n\
#else\n\
#define setIntersectionPair(/*inout Intersections*/ ix, /*int*/ index, /*vec2*/ entryExit) (ix).intersections[(index) * 2 + 0] = (entryExit).x; (ix).intersections[(index) * 2 + 1] = (entryExit).y\n\
#endif\n\
#if (INTERSECTION_COUNT > 1)\n\
void initializeIntersections(inout Intersections ix) {\n\
const int sortPasses = INTERSECTION_COUNT * 2 - 1;\n\
for (int n = sortPasses; n > 0; --n) {\n\
for (int i = 0; i < sortPasses; ++i) {\n\
if (i >= n) { break; }\n\
vec2 intersect0 = ix.intersections[i + 0];\n\
vec2 intersect1 = ix.intersections[i + 1];\n\
float t0 = intersect0.x;\n\
float t1 = intersect1.x;\n\
float b0 = intersect0.y;\n\
float b1 = intersect1.y;\n\
float tmin = min(t0, t1);\n\
float tmax = max(t0, t1);\n\
float bmin = tmin == t0 ? b0 : b1;\n\
float bmax = tmin == t0 ? b1 : b0;\n\
ix.intersections[i + 0] = vec2(tmin, bmin);\n\
ix.intersections[i + 1] = vec2(tmax, bmax);\n\
}\n\
}\n\
ix.index = 0;\n\
ix.surroundCount = 0;\n\
ix.surroundIsPositive = false;\n\
}\n\
#endif\n\
#if (INTERSECTION_COUNT > 1)\n\
vec2 nextIntersection(inout Intersections ix) {\n\
vec2 entryExitT = vec2(NO_HIT);\n\
const int passCount = INTERSECTION_COUNT * 2;\n\
if (ix.index == passCount) {\n\
return entryExitT;\n\
}\n\
for (int i = 0; i < passCount; ++i) {\n\
if (i < ix.index) {\n\
continue;\n\
}\n\
ix.index = i + 1;\n\
vec2 intersect = ix.intersections[i];\n\
float t = intersect.x;\n\
bool currShapeIsPositive = intersect.y < 2.0;\n\
bool enter = mod(intersect.y, 2.0) == 0.0;\n\
ix.surroundCount += enter ? +1 : -1;\n\
ix.surroundIsPositive = currShapeIsPositive ? enter : ix.surroundIsPositive;\n\
if (ix.surroundCount == 1 && ix.surroundIsPositive && enter == currShapeIsPositive) {\n\
entryExitT.x = t;\n\
}\n\
bool exitPositive = !enter && currShapeIsPositive && ix.surroundCount == 0;\n\
bool enterNegativeFromPositive = enter && !currShapeIsPositive && ix.surroundCount == 2 && ix.surroundIsPositive;\n\
if (exitPositive || enterNegativeFromPositive) {\n\
entryExitT.y = t;\n\
if (exitPositive) {\n\
ix.index = passCount;\n\
}\n\
break;\n\
}\n\
}\n\
return entryExitT;\n\
}\n\
#endif\n\
";

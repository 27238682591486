import { BoundingSphere, Cartesian3, Cartographic, destroyObject, Ellipsoid, HeadingPitchRoll, Matrix4, Transforms } from "../../Core/cesium/Source/Cesium.js";
import createBoxPrimitive from "./createBoxPrimitive";

const actualDimensionScratch = new Cartesian3();
const scaleScratch = new Cartesian3();

export default class BoxPrimitive {
    constructor(options) {
        this._show = (typeof options._show !== "undefined") ? options._show : true;
        this._id = options.id;
        this._color = options.color;
        this._dimensions = { x: 0, y: 0, z: 0 };
        this._dimensions.x = +options.dimensions.x;
        this._dimensions.y = +options.dimensions.y;
        this._dimensions.z = +options.dimensions.z;
        this._modelMatrix = options.modelMatrix;
        this._selectable = options.selectable;

        this._origPosition = Matrix4.getTranslation(this._modelMatrix, new Cartesian3());

        const hpr = Transforms.fixedFrameToHeadingPitchRoll(
          this._modelMatrix,
          Ellipsoid.WGS84,
          Transforms.eastNorthUpToFixedFrame,
          new HeadingPitchRoll()
        );

        this._hpr = hpr;

        this._primitive = undefined;
        this._update = true;
    }

    get depth() {
        return this._dimensions.z;
    }

    set depth(h) {
        this._dimensions.z = h;

        const carto = Cartographic.fromCartesian(this._origPosition);

        carto.height += h / 2;

        const position = Cartesian3.fromRadians(carto.longitude, carto.latitude, carto.height);

        this._modelMatrix = Transforms.headingPitchRollToFixedFrame(position, this._hpr);

        this._update = true;
    }

    updateWidth(val) {
        this._dimensions.x = +val;
        this._update = true;
    }

    updateHeight(val) {
        this._dimensions.y = +val;
        this._update = true;
    }

    updateLength(val) {
        this._dimensions.z = +val;
        this._update = true;
    }

    get modelMatrix() {
        return this._modelMatrix;
    }

    set modelMatrix(matrix) {
        this._modelMatrix = matrix;
    }

    get boundingSphere() {
        let radius = this._dimensions.x;

        if (radius < this._dimensions.y) {
            radius = this._dimensions.y;
        }

        if (radius < this._dimensions.z) {
            radius = this._dimensions.z;
        }

        return new BoundingSphere(this._position, radius);
    }

    get dimensions() {
        const actualDimension = Cartesian3.clone(this._dimensions, new Cartesian3());

        const scale = Matrix4.getScale(this._modelMatrix, scaleScratch);

        actualDimension.x *= scale.x;
        actualDimension.y *= scale.y;
        actualDimension.z *= scale.z;

        return actualDimension;
    }

    forceUpdate() {
        this._update = true;
    }

    update(frameState) {
        if (!this._show) {
            return;
        }

        if (this._update) {
            this._update = false;

            this._primitive = createBoxPrimitive({
                id: this._id,
                color: this._color,
                selectable: true,
                dimensions: this._dimensions,
                modelMatrix: this._modelMatrix
            });
        }

        this._primitive.update(frameState);
    }

    getCenterOfWidth() {
        const dimensions = Cartesian3.clone(this._dimensions, actualDimensionScratch);

        const localCenter = new Cartesian3(0, -dimensions.y / 2, -dimensions.z / 2);

        return Matrix4.multiplyByPoint(this._modelMatrix, localCenter, new Cartesian3());
    }

    getCenterOfDepth() {
        const dimensions = Cartesian3.clone(this._dimensions, actualDimensionScratch);

        const localCenter = new Cartesian3(-dimensions.x / 2, 0, -dimensions.z / 2);

        return Matrix4.multiplyByPoint(this._modelMatrix, localCenter, new Cartesian3());
    }

    getCenterOfHeight() {
        const dimensions = Cartesian3.clone(this._dimensions, actualDimensionScratch);

        const localCenter = new Cartesian3(-dimensions.x / 2, -dimensions.y / 2, 0);

        return Matrix4.multiplyByPoint(this._modelMatrix, localCenter, new Cartesian3());
    }

    getXAxisPositions() {
        const dimensions = Cartesian3.clone(this._dimensions, actualDimensionScratch);

        const first = new Cartesian3(-dimensions.x / 2, -dimensions.y / 2, -dimensions.z / 2);
        const second = new Cartesian3(dimensions.x / 2, -dimensions.y / 2, -dimensions.z / 2);

        return [
            Matrix4.multiplyByPoint(this._modelMatrix, first, new Cartesian3()),
            Matrix4.multiplyByPoint(this._modelMatrix, second, new Cartesian3())
        ];
    }

    getYAxisPositions() {
        const dimensions = Cartesian3.clone(this._dimensions, actualDimensionScratch);

        const first = new Cartesian3(-dimensions.x / 2, -dimensions.y / 2, -dimensions.z / 2);
        const second = new Cartesian3(-dimensions.x / 2, dimensions.y / 2, -dimensions.z / 2);

        return [
            Matrix4.multiplyByPoint(this._modelMatrix, first, new Cartesian3()),
            Matrix4.multiplyByPoint(this._modelMatrix, second, new Cartesian3())
        ];
    }

    getZAxisPositions() {
        const dimensions = Cartesian3.clone(this._dimensions, actualDimensionScratch);

        const first = new Cartesian3(-dimensions.x / 2, -dimensions.y / 2, -dimensions.z / 2);
        const second = new Cartesian3(-dimensions.x / 2, -dimensions.y / 2, dimensions.z / 2);

        return [
            Matrix4.multiplyByPoint(this._modelMatrix, first, new Cartesian3()),
            Matrix4.multiplyByPoint(this._modelMatrix, second, new Cartesian3())
        ];
    }

    destroy = function () {
        this._primitive = this._primitive && this._primitive.destroy();

        return destroyObject(this);
    };
}

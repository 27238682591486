import {
  Cartesian3,
  ClippingPlane,
  ClippingPlaneCollection,
  destroyObject,
  Matrix3,
  Matrix4,
  Transforms
} from "../../../Core/cesium/Source/Cesium.js";

const scratchReferenceFrame = new Matrix4();
const scratchInvReferenceFrame = new Matrix4();
const scratchRotationMatrix3 = new Matrix3();
const scratchMatrix4 = new Matrix4();

const scratchP1 = new Cartesian3();
const scratchInv2 = new Matrix4();

const scratchMatrixAtOffset = new Matrix4();

const scratchNewLeft = new Cartesian3();
const scratchNewRight = new Cartesian3();
const scratchNewFront = new Cartesian3();
const scratchNewBack = new Cartesian3();

// convert the point defined matrix1 coordinate into matrix2 coordinate
function convert(position, matrix1, matrix2, result) {
  const p1 = Matrix4.multiplyByPoint(matrix1, position, scratchP1);

  const inv2 = Matrix4.inverseTransformation(matrix2, scratchInv2);

  Matrix4.multiplyByPoint(inv2, p1, result);

  return result;
}

export default class TilesetAssetClippingBox {
  constructor(options) {
    this._asset = options.asset;
    this._rotationCenter = options.center;

    const tileset = this._asset.tileset;

    tileset.clippingPlanes = new ClippingPlaneCollection({
      planes: [],
      edgeWidth: 1.0,
      unionClippingRegions: true
    });

    const xDimension = options.xDimension;
    const yDimension = options.yDimension;
    const zDimension = options.zDimension;

    const xHalfDimension = xDimension / 2;
    const yHalfDimension = yDimension / 2;
    const zHalfDimension = zDimension / 2;

    let offsetX = 0;
    let offsetY = 0;
    let offsetZ = 0;

    const tilesetOrigin = this._asset.origin;
    const tilesetEastNorthUpFrame = Transforms.eastNorthUpToFixedFrame(tilesetOrigin);
    const inverse = Matrix4.inverse(tilesetEastNorthUpFrame, new Matrix4());
    const delta = Matrix4.multiplyByPoint(inverse, options.center, new Cartesian3());

    offsetX = delta.x;
    offsetY = delta.y;
    offsetZ = delta.z;

    const clippingPlanes = tileset.clippingPlanes;

    clippingPlanes.add(new ClippingPlane(new Cartesian3(1.0, 0.0, 0.0), xHalfDimension - offsetX)); // left
    clippingPlanes.add(new ClippingPlane(new Cartesian3(-1.0, 0.0, 0.0), xHalfDimension + offsetX)); // right
    clippingPlanes.add(new ClippingPlane(new Cartesian3(0.0, 1.0, 0.0), yHalfDimension - offsetY)); // front
    clippingPlanes.add(new ClippingPlane(new Cartesian3(0.0, -1.0, 0.0), yHalfDimension + offsetY)); // back
    clippingPlanes.add(new ClippingPlane(new Cartesian3(0.0, 0.0, -1.0), zHalfDimension + offsetZ)); // up
    clippingPlanes.add(new ClippingPlane(new Cartesian3(0.0, 0.0, 1.0), zHalfDimension - offsetZ)); // down

    this._initModelMatrixOfClippingPlanes();

    // matrix4 which contains only translation and z axis rotation
    // this does not contain inverse rotation of the asset
    this._clippingPlaneModelMatrix = new Matrix4();

    Matrix4.clone(Matrix4.IDENTITY, this._clippingPlaneModelMatrix);

    this._storedClippingPlanesModelMatrix = new Matrix4();

    this._storedClippingPlanesModelMatrixHeading = 0;
    this._storedClippingPlanesDistances = [];

    this._storeClippingPlanesParameters();
  }

  setRotationCenter(center) {
    this._rotationCenter = center;
  }

  move(clippingPlaneIndex, moveAmount) {
    const clippingPlanes = this._asset.tileset.clippingPlanes;

    const curDistance = clippingPlanes.get(clippingPlaneIndex).distance;

    clippingPlanes.get(clippingPlaneIndex).distance = curDistance + moveAmount;
  }

  rotate(angle) {
    const asset = this._asset;
    const tileset = asset.tileset;

    const referenceFrame = this._asset.getRefereceFrame(scratchReferenceFrame);

    // the origin of the clipping planes
    const invReferenceFrame = Matrix4.inverseTransformation(referenceFrame, scratchInvReferenceFrame);

    /*
    if (!asset.tileset.root.transform.equals(Matrix4.IDENTITY)) {
        // in this case the origin of the clipping planes is asset.origRootTransform
        // need to revise in case asset is rotated
        invReferenceFrame = Matrix4.inverseTransformation(asset.tileset.root.transform, new Matrix4());
    }
    */

    const translation = Matrix4.multiplyByPoint(invReferenceFrame, this._rotationCenter, new Cartesian3());

    translation.z = 0;

    const rotation = Matrix3.fromRotationZ(angle, scratchRotationMatrix3);

    const clippingPlanes = tileset.clippingPlanes;

    this._initModelMatrixOfClippingPlanes();

    const rotationTranslation = Matrix4.fromRotationTranslation(rotation, translation, scratchMatrix4);

    Matrix4.fromRotationTranslation(rotation, translation, this._clippingPlaneModelMatrix);

    Matrix4.multiply(clippingPlanes.modelMatrix, rotationTranslation, clippingPlanes.modelMatrix);

    // step3 update clipping planes 's distances

    const storedRotation = Matrix3.fromRotationZ(
      this._storedClippingPlanesModelMatrixHeading,
      scratchRotationMatrix3
    );

    Matrix4.fromRotationTranslation(storedRotation, translation, scratchMatrixAtOffset);

    const left = new Cartesian3(-this._storedClippingPlanesDistances[0], 0, 0);
    const right = new Cartesian3(this._storedClippingPlanesDistances[1], 0, 0);
    const front = new Cartesian3(0, -this._storedClippingPlanesDistances[2], 0);
    const back = new Cartesian3(0, this._storedClippingPlanesDistances[3], 0);

    const newLeft = convert(left, this._storedClippingPlanesModelMatrix, scratchMatrixAtOffset, scratchNewLeft);
    const newRight = convert(right, this._storedClippingPlanesModelMatrix, scratchMatrixAtOffset, scratchNewRight);
    const newFront = convert(front, this._storedClippingPlanesModelMatrix, scratchMatrixAtOffset, scratchNewFront);
    const newBack = convert(back, this._storedClippingPlanesModelMatrix, scratchMatrixAtOffset, scratchNewBack);

    clippingPlanes.get(0).distance = -newLeft.x;
    clippingPlanes.get(1).distance = newRight.x;
    clippingPlanes.get(2).distance = -newFront.y;
    clippingPlanes.get(3).distance = newBack.y;
  }

  _initModelMatrixOfClippingPlanes() {
    const asset = this._asset;
    const tileset = asset.tileset;
    const referenceFrame = this._asset.getRefereceFrame(scratchReferenceFrame);

    // @ts-ignore
    // to remove the affect of modelMatrix of the tileset to clippingPlanes 's modelMatrix

    const inv = Matrix4.inverse(tileset.clippingPlanesOriginMatrix, new Matrix4());

    tileset.clippingPlanes.modelMatrix = Matrix4.multiply(inv, referenceFrame, new Matrix4());
  }

  _storeClippingPlanesParameters() {
    const modelMatrix = this._clippingPlaneModelMatrix;

    const rotation = Matrix4.getMatrix3(modelMatrix, new Matrix3());

    // https://stackoverflow.com/questions/15022630/how-to-calculate-the-angle-from-rotation-matrix
    this._storedClippingPlanesModelMatrixHeading = Math.atan2(rotation[1], rotation[0]);

    modelMatrix.clone(this._storedClippingPlanesModelMatrix);

    const clippingPlanes = this._asset.tileset.clippingPlanes;

    this._storedClippingPlanesDistances = [
      clippingPlanes.get(0).distance,
      clippingPlanes.get(1).distance,
      clippingPlanes.get(2).distance,
      clippingPlanes.get(3).distance,
      clippingPlanes.get(4).distance
    ];
  }

  get asset() {
    return this._asset;
  }

  get heading() {
    return this._storedClippingPlanesModelMatrixHeading;
  }

  get enabled() {
    return this._asset.tileset.clippingPlanes.enabled;
  }

  set enabled(b) {
    this._asset.tileset.clippingPlanes.enabled = b;
  }

  toggle() {
    this._asset.tileset.clippingPlanes.enabled = !this._asset.tileset.clippingPlanes.enabled;
  }

  destroy() {
    this._asset.tileset.clippingPlanes.removeAll();
    destroyObject(this);
  }
}
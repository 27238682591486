//This file is automatically rebuilt by the Cesium build process.
export default "uniform ivec2 u_megatextureSliceDimensions;\n\
uniform ivec2 u_megatextureTileDimensions;\n\
uniform vec2 u_megatextureVoxelSizeUv;\n\
uniform vec2 u_megatextureSliceSizeUv;\n\
uniform vec2 u_megatextureTileSizeUv;\n\
uniform ivec3 u_dimensions;\n\
#if defined(PADDING)\n\
uniform ivec3 u_paddingBefore;\n\
uniform ivec3 u_paddingAfter;\n\
#endif\n\
int intMin(int a, int b) {\n\
return a <= b ? a : b;\n\
}\n\
int intMax(int a, int b) {\n\
return a >= b ? a : b;\n\
}\n\
int intClamp(int v, int minVal, int maxVal) {\n\
return intMin(intMax(v, minVal), maxVal);\n\
}\n\
vec2 index1DTo2DTexcoord(int index, ivec2 dimensions, vec2 uvScale)\n\
{\n\
int indexX = intMod(index, dimensions.x);\n\
int indexY = index / dimensions.x;\n\
return vec2(indexX, indexY) * uvScale;\n\
}\n\
Properties getPropertiesFromMegatexture(in SampleData sampleData) {\n\
vec3 tileUv = clamp(sampleData.tileUv, vec3(0.0), vec3(1.0));\n\
int tileIndex = sampleData.megatextureIndex;\n\
vec3 voxelCoord = tileUv * vec3(u_dimensions);\n\
ivec3 voxelDimensions = u_dimensions;\n\
#if defined(PADDING)\n\
voxelDimensions += u_paddingBefore + u_paddingAfter;\n\
voxelCoord += vec3(u_paddingBefore);\n\
#endif\n\
#if defined(NEAREST_SAMPLING)\n\
voxelCoord = floor(voxelCoord) + vec3(0.5);\n\
#endif\n\
vec2 tileUvOffset = index1DTo2DTexcoord(tileIndex, u_megatextureTileDimensions, u_megatextureTileSizeUv);\n\
float slice = voxelCoord.z - 0.5;\n\
int sliceIndex = int(floor(slice));\n\
int sliceIndex0 = intClamp(sliceIndex, 0, voxelDimensions.z - 1);\n\
vec2 sliceUvOffset0 = index1DTo2DTexcoord(sliceIndex0, u_megatextureSliceDimensions, u_megatextureSliceSizeUv);\n\
vec2 voxelUvOffset = clamp(voxelCoord.xy, vec2(0.5), vec2(voxelDimensions.xy) - vec2(0.5)) * u_megatextureVoxelSizeUv;\n\
vec2 uv0 = tileUvOffset + sliceUvOffset0 + voxelUvOffset;\n\
#if defined(NEAREST_SAMPLING)\n\
return getPropertiesFromMegatextureAtUv(uv0);\n\
#else\n\
float sliceLerp = fract(slice);\n\
int sliceIndex1 = intMin(sliceIndex + 1, voxelDimensions.z - 1);\n\
vec2 sliceUvOffset1 = index1DTo2DTexcoord(sliceIndex1, u_megatextureSliceDimensions, u_megatextureSliceSizeUv);\n\
vec2 uv1 = tileUvOffset + sliceUvOffset1 + voxelUvOffset;\n\
Properties properties0 = getPropertiesFromMegatextureAtUv(uv0);\n\
Properties properties1 = getPropertiesFromMegatextureAtUv(uv1);\n\
return mixProperties(properties0, properties1, sliceLerp);\n\
#endif\n\
}\n\
Properties accumulatePropertiesFromMegatexture(in SampleData sampleDatas[SAMPLE_COUNT]) {\n\
#if (SAMPLE_COUNT == 1)\n\
return getPropertiesFromMegatexture(sampleDatas[0]);\n\
#else\n\
Properties properties = clearProperties();\n\
for (int i = 0; i < SAMPLE_COUNT; ++i) {\n\
float weight = sampleDatas[i].weight;\n\
if (weight > 0.0) {\n\
Properties tempProperties = getPropertiesFromMegatexture(sampleDatas[i]);\n\
tempProperties = scaleProperties(tempProperties, weight);\n\
properties = sumProperties(properties, tempProperties);\n\
}\n\
}\n\
return properties;\n\
#endif\n\
}\n\
";

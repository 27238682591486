import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import {
  FontAwesome,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "ca-react-component-lib";
import { useDrag, useDrop } from "react-dnd";
import {
  Matrix4,
  Cartesian3,
} from "../../Core/cesium/Source/Cesium.js";

/* jshint ignore:start */
export default function LayersItem({
  viewer,
  item,
  comments,
  setComments,
  measurements,
  setMeasurements,
  primitives,
  setPrimitives,
  showMeasurement,
  setShowMeasurement,
  setShowPrimitive,
  clippingPlanes,
  setClippingPlanes,
  setMode,
}) {
  const dragDropRef = React.useRef(null);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'ListItemButton',
      item: () => {
        return {
          id: item.id,
          index: item.index || item.id
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        setItemCollection(item.id, dropResult?.collectionId || false);
      },
    })
  );
  const [{ handlerId }, drop] = useDrop(() => ({
    accept: 'ListItemButton',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(dragItem, monitor) {
      if (!dragDropRef.current) {
        return
      }
      const dragIndex = dragItem.index
      const hoverIndex = item.index || item.id;
      // // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dragDropRef.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      reorderItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      dragItem.index = hoverIndex
    }
  }));

  const setItemCollection = React.useCallback((itemId, collectionId) => {
    const setItems = item.measurement ? setMeasurements : item.primitive ? setPrimitives : setComments;
    setItems(oldItems => oldItems.map((i) => {
      if (i.id === itemId) i.collection = collectionId;
      return i;
    }))
  }, []);

  const reorderItem = React.useCallback((dragIndex, hoverIndex) => {
    setMeasurements(oldMeasurements => oldMeasurements.map((m) => {
      if (m.index === dragIndex) m.index = hoverIndex;
      if (item.measurement && m.id === item.id) m.index = dragIndex;
      return m;
    }));
    setComments(oldComments => oldComments.map((c) => {
      if (c.index === dragIndex) c.index = hoverIndex;
      if (!item.measurement && !item.primitive && c.id === item.id) c.index = dragIndex;
      return c;
    }));
    setPrimitives(oldPrimitives => oldPrimitives.map((c) => {
      if (c.index === dragIndex) c.index = hoverIndex;
      if (item.primitive && c.id === item.id) c.index = dragIndex;
      return c;
    }));
  }, []);

  React.useEffect(() => {
    if (showMeasurement) {
      openMeasurement(showMeasurement);
    }
  }, [showMeasurement]);

  const openMeasurement = (measurement) => {
    if (!measurement) return;

    const measurementType = viewer.measure.viewModel._measurements.find(x => x.type === measurement.measurement.type);
    viewer.measure.viewModel.selectedMeasurement = measurementType;
    viewer.measure.viewModel.selectedMeasurement.savedId = measurement.id;
    measurementType._refreshLabels();
    setMode("move");

    let redrawDistanceEvent = new CustomEvent("redrawDistance", {
      detail: {
        componentLines: "componentLines" in measurement.measurement ? measurement.measurement.componentLines : null,
        measurementType: measurement.measurement.measurementType,
        positionsArray: measurement.measurement.positionsArray,
        area: measurement.measurement.area,
        camera: viewer.scene.camera,
        pos1: measurement.measurement.pos1,
        pos2: measurement.measurement.pos2,
        color: measurement.measurement.color,
        label: ""
      },
    });

    document.dispatchEvent(redrawDistanceEvent);

    if (measurement.measurement.camera) {
      viewer.scene.camera.setView({
        endTransform: measurement.measurement.camera.transform,
        destination: measurement.measurement.camera.position,
        orientation: {
          direction: measurement.measurement.camera.direction,
          up: measurement.measurement.camera.up
        }
      });
    }
  };

  const toggleMeasurementVisibility = (measurementId) => {
    setMeasurements(measurements.map(m => {
      if (m.id === measurementId) m.hide = !m.hide;
      return m;
    }));
  };

  const deleteMeasurement = (measurementId) => {
    if (viewer.measure.viewModel.selectedMeasurement?.savedId === measurementId) {
      viewer.measure.viewModel.selectedMeasurement = undefined;
      setShowMeasurement(false);
    }
    setMeasurements(measurements.filter(x => x.id !== measurementId));
  };

  const openComment = (comment) => {
    if (comment.camera) {
      viewer.scene.camera.setView({
        endTransform: comment.camera.transform,
        destination: comment.camera.position,
        orientation: {
          direction: comment.camera.direction,
          up: comment.camera.up
        }
      });
    }
  };

  const toggleCommentVisibility = (commentId) => {
    setComments(comments.map(c => {
      if (c.id === commentId) c.hide = !c.hide;
      return c;
    }));
  };

  const deleteComment = (commentId) => {
    setComments(comments.filter(x => x.id !== commentId));
  };

  const openPrimitive = (primitive) => {
    if (primitive.camera) {
      viewer.scene.camera.setView({
        endTransform: primitive.camera.transform,
        destination: primitive.camera.position,
        orientation: {
          direction: primitive.camera.direction,
          up: primitive.camera.up
        }
      });
    }
    setShowPrimitive(primitive);
  };

  const togglePrimitiveVisibility = (primitiveId) => {
    setPrimitives(primitives.map(c => {
      if (c.id === primitiveId) {
        c.hide = !c.hide;
        const primitiveEntity = window.cesiumTools.getEntityById(c.id);
        primitiveEntity.visible = !c.hide;
        primitiveEntity._primitive._show = !c.hide;
        primitiveEntity._xAxis.show = !c.hide;
        primitiveEntity._yAxis.show = !c.hide;
        primitiveEntity._zAxis.show = !c.hide;
        primitiveEntity._labelCollection.show = !c.hide;
        const transformEditorViewModel = window.cesiumTools?._currentTool?._transformEditor?._viewModel;
        c.hide ?
          transformEditorViewModel?.deactivate()
          : transformEditorViewModel?.activate();
      }
      return c;
    }));
  };

  const deletePrimitive = (primitiveId) => {
    window.cesiumTools?._currentTool?._transformEditor?._viewModel?.deactivate();
    window.cesiumTools.removeEntity(primitives.find(p => p.id === primitiveId).title);
    setPrimitives(primitives.filter(x => x.id !== primitiveId));
    setShowPrimitive(false);
  };

  const openClippingPlanes = (item) => {
    window.dispatchEvent(new CustomEvent("activateClippingBox"));

    if (item.camera) {
      viewer.scene.camera.setView({
        endTransform: item.camera.transform,
        destination: item.camera.position,
        orientation: {
          heading: item.camera.heading,
          pitch: item.camera.pitch,
          roll: item.camera.roll,
        }
      });
    }

    window.cesiumClippingBox.updateClippingPlanes(item);
  };

  const deleteClippingPlanes = (clippingPlanesId) => {
    if (window.cesiumClippingBox) {
      window.cesiumClippingBox.reset();
      window.cesiumClippingBox.showEditControls(false);
      window.cesiumClippingBox.showHideClippingWalls(false);
    }
    setClippingPlanes((currentPlanes) => currentPlanes.filter(x => x.id !== clippingPlanesId));
  };

  drag(drop(dragDropRef));

  return (<div ref={dragDropRef}>
    <ListItemButton className="layers layersItem">
        <div
          className={item.collection ? "layerItem nested" : "layerItem"}
          onClick={() => item.measurement ? setShowMeasurement(item) : item.primitive ? openPrimitive(item) : item.corners ? openClippingPlanes(item) : openComment(item)}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <FontAwesome
              icon={item.measurement ? item.measurement.icon : item.primitive ? "cube" : item.corners ? "crop" : "comment"}
              size="small"
              color={item.color || item.measurement?.color}
            />
          </ListItemIcon>
          <ListItemText primary={item.measurement ? (item.measurement.title || item.measurement.type + " #" + item.id) : item.title} />
          {!item.corners && <IconButton className="eyeLayer" onClick={(e) => {
            e.stopPropagation();
            item.measurement ? toggleMeasurementVisibility(item.id) : item.primitive ? togglePrimitiveVisibility(item.id) : toggleCommentVisibility(item.id)
          }}>
            <FontAwesome icon={item.hide ? "eye-slash" : "eye"} type="solid" size="small" />
          </IconButton>}
          <IconButton className="deleteLayer" onClick={(e) => {
            e.stopPropagation();
            item.measurement ? deleteMeasurement(item.id) : item.primitive ? deletePrimitive(item.id) : item.corners ? deleteClippingPlanes(item.id) : deleteComment(item.id)
          }}>
            <FontAwesome icon="trash" size="small" />
          </IconButton>
        </div>
      </ListItemButton>
  </div>);
}
/* jshint ignore:end */
//This file is automatically rebuilt by the Cesium build process.
export default "uniform vec3 u_ellipsoidRadiiUv;\n\
#if !defined(ELLIPSOID_IS_SPHERE)\n\
uniform vec3 u_ellipsoidInverseRadiiSquaredUv;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MIN_DISCONTINUITY) || defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MAX_DISCONTINUITY) || defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE_MIN_MAX_REVERSED)\n\
uniform vec3 u_ellipsoidShapeUvLongitudeMinMaxMid;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE)\n\
uniform vec2 u_ellipsoidUvToShapeUvLongitude;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LATITUDE)\n\
uniform vec2 u_ellipsoidUvToShapeUvLatitude;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_HEIGHT_MIN) && !defined(ELLIPSOID_HAS_SHAPE_BOUNDS_HEIGHT_FLAT)\n\
uniform float u_ellipsoidInverseHeightDifferenceUv;\n\
uniform vec2 u_ellipseInnerRadiiUv;\n\
#endif\n\
float ellipseDistanceIterative (vec2 pos, vec2 radii) {\n\
vec2 p = abs(pos);\n\
vec2 invRadii = 1.0 / radii;\n\
vec2 a = vec2(1.0, -1.0) * (radii.x * radii.x - radii.y * radii.y) * invRadii;\n\
vec2 t = vec2(0.70710678118);\n\
vec2 v = radii * t;\n\
const int iterations = 3;\n\
for (int i = 0; i < iterations; ++i) {\n\
vec2 e = a * pow(t, vec2(3.0));\n\
vec2 q = normalize(p - e) * length(v - e);\n\
t = normalize((q + e) * invRadii);\n\
v = radii * t;\n\
}\n\
return length(v * sign(pos) - pos) * sign(p.y - v.y);\n\
}\n\
vec3 convertUvToShapeUvSpace(in vec3 positionUv) {\n\
vec3 positionLocal = positionUv * 2.0 - 1.0;\n\
#if defined(ELLIPSOID_IS_SPHERE)\n\
vec3 posEllipsoid = positionLocal * u_ellipsoidRadiiUv.x;\n\
vec3 normal = normalize(posEllipsoid);\n\
#else\n\
vec3 posEllipsoid = positionLocal * u_ellipsoidRadiiUv;\n\
vec3 normal = normalize(posEllipsoid * u_ellipsoidInverseRadiiSquaredUv);\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE_RANGE_EQUAL_ZERO)\n\
float longitude = 1.0;\n\
#else\n\
float longitude = (atan(normal.y, normal.x) + czm_pi) / czm_twoPi;\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE_MIN_MAX_REVERSED)\n\
longitude += float(longitude < u_ellipsoidShapeUvLongitudeMinMaxMid.z);\n\
#endif\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MIN_DISCONTINUITY)\n\
longitude = longitude > u_ellipsoidShapeUvLongitudeMinMaxMid.z ? u_ellipsoidShapeUvLongitudeMinMaxMid.x : longitude;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MAX_DISCONTINUITY)\n\
longitude = longitude < u_ellipsoidShapeUvLongitudeMinMaxMid.z ? u_ellipsoidShapeUvLongitudeMinMaxMid.y : longitude;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE)\n\
longitude = longitude * u_ellipsoidUvToShapeUvLongitude.x + u_ellipsoidUvToShapeUvLongitude.y;\n\
#endif\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LATITUDE_RANGE_EQUAL_ZERO)\n\
float latitude = 1.0;\n\
#else\n\
float latitude = (asin(normal.z) + czm_piOverTwo) / czm_pi;\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LATITUDE)\n\
latitude = latitude * u_ellipsoidUvToShapeUvLatitude.x + u_ellipsoidUvToShapeUvLatitude.y;\n\
#endif\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_HEIGHT_FLAT)\n\
float height = 1.0;\n\
#else\n\
#if defined(ELLIPSOID_IS_SPHERE)\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_HEIGHT_MIN)\n\
float height = (length(posEllipsoid) - u_ellipseInnerRadiiUv.x) * u_ellipsoidInverseHeightDifferenceUv;\n\
#else\n\
float height = length(posEllipsoid);\n\
#endif\n\
#else\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_HEIGHT_MIN)\n\
vec2 posEllipse = vec2(length(posEllipsoid.xy), posEllipsoid.z);\n\
float height = ellipseDistanceIterative(posEllipse, u_ellipseInnerRadiiUv) * u_ellipsoidInverseHeightDifferenceUv;\n\
#else\n\
float height = length(posEllipsoid);\n\
#endif\n\
#endif\n\
#endif\n\
return vec3(longitude, latitude, height);\n\
}\n\
";

/**
 * @private
 */
const DrawingMode = {
  BeforeDraw: 0,
  Drawing: 1,
  AfterDraw: 2,
};

export default Object.freeze(DrawingMode);

import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import {
  FontAwesome,
  Box,
  Drawer,
  Button,
  IconButton,
  Divider,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Card,
  Select,
  InputLabel,
  MenuItem
} from "ca-react-component-lib";
import DistanceUnits from "../Widgets/Measure/DistanceUnits.js";

/* jshint ignore:start */
export default function GeneralSettings({ viewer, showMeasurement }) {
  const [open, setOpen] = React.useState(false);
  const [open3D, setOpen3D] = React.useState(true); // temporarily force 3d accordion to be open by default - remove this later and switch to uncontrolled state
  const [settings, setSettings] = React.useState({
    disableAA: localStorage.getItem('disableAA') === "true",
    showCompass: localStorage.getItem('showCompass') === "true",
    cameraDefault: localStorage.getItem('cameraDefault') || "pan",
    measurementUnits: localStorage.getItem('measurementUnits') || DistanceUnits.FEET,
    disableCollisionDetection: localStorage.getItem('disableCollisionDetection') === "true"
  });

  React.useEffect(() => {
    if (showMeasurement) setOpen(false);
  }, [showMeasurement])

  const showCompass = (show) => {
    const compassContainer = document.getElementById('cesium-viewer-compassContainer');
    compassContainer.style.display = show ? 'block' : 'none';
    localStorage.setItem('showCompass', ""+show);
    setSettings({
      ...settings,
      showCompass: show
    });
  };

  const disableAA = (disable) => {
    viewer.scene.postProcessStages.fxaa.enabled = !disable;
    localStorage.setItem('disableAA', ""+disable);
    setSettings({
      ...settings,
      disableAA: disable
    });
  };

  const disableCollisionDetection = (disable) => {
    viewer.scene.screenSpaceCameraController.enableCollisionDetection = !disable;
    localStorage.setItem('disableCollisionDetection', ""+disable);
    setSettings({
      ...settings,
      disableCollisionDetection: disable
    });
  };

  const changeMeasurementUnits = (unit) => {
    localStorage.setItem('measurementUnits', ""+unit);
    setSettings({
      ...settings,
      measurementUnits: unit
    });
    viewer.measure.viewModel.units.distanceUnits = DistanceUnits[unit] || DistanceUnits.FEET
  };

  const changeCameraDefault = (cameraMode) => {
    localStorage.setItem('cameraDefault', cameraMode);
    setSettings({
      ...settings,
      cameraDefault: cameraMode
    });
  };

  React.useEffect(() => {
    if (settings.showCompass) showCompass(true);
    if (settings.disableAA) disableAA(true);
    if (settings.disableCollisionDetection) disableCollisionDetection(true);
    if (settings.measurementUnits) viewer.measure.viewModel.units.distanceUnits = DistanceUnits[settings.measurementUnits]
  }, []);

  return (
    <Box>
      <Button className={open ? "toolbarButton active" : "toolbarButton"} onClick={() => setOpen(!open)}>
        <FontAwesome
          icon="gear"
        />
      </Button>
      <Drawer
        hideBackdrop
        anchor="right"
        open={open}
        className="toolbarDrawer"
        onClose={() => setOpen(false)}
        ModalProps={{
          disableEnforceFocus: true,
        }}
      >
        <List>
          <ListItem key="settings">
            <FontAwesome
              icon="gear"
              sx={{ mr: 2 }}
            />
            <span className="drawerTitle">Settings</span>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => setOpen(false)}>
              <FontAwesome icon="xmark" />
            </IconButton>
          </ListItem>
        </List>

        <Divider />

        <Card sx={{ m: 2 }}>
          <Accordion expanded={open3D} onChange={(e, isOpen) => setOpen3D(isOpen)} key="3d">
            <AccordionSummary expandIcon={<FontAwesome icon="chevron-down" sx={{ mx: 2 }} />}>
              <FontAwesome
                icon="cube"
                type="solid"
                sx={{ mr: 2 }}
              />
              3D Viewer Settings
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={settings.disableCollisionDetection} onChange={(e) => disableCollisionDetection(e.target.checked)}/>} label="Disable Collision Detection" />
                <FormControlLabel control={<Checkbox checked={settings.disableAA} onChange={(e) => disableAA(e.target.checked)}/>} label="Disable Anti-aliasing" />
                <FormControlLabel control={<Checkbox checked={settings.showCompass} onChange={(e) => showCompass(e.target.checked)}/>} label="Enable Compass" />
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel>Units</InputLabel>
                  <Select value={settings.measurementUnits} sx={{ textTransform: "capitalize" }} onChange={(e) => changeMeasurementUnits(e.target.value)}>
                    {Object.keys(DistanceUnits).map((unit) => (
                      <MenuItem key={unit} value={unit} sx={{ textTransform: "capitalize" }}>
                        {unit?.toLowerCase().replaceAll('_', ' ').replace('us ', 'US ')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                  <InputLabel>Default Camera Mode</InputLabel>
                  <Select value={settings.cameraDefault} onChange={(e) => changeCameraDefault(e.target.value)}>
                    <MenuItem value="pan">Pan</MenuItem>
                    <MenuItem value="rotate">Rotate</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Drawer>
    </Box>
  );
}
/* jshint ignore:end */
import { defined, DeveloperError } from "@cesium/engine";
import { createCommand } from "@cesium/widgets";
import knockout from "@cesium/widgets/Source/ThirdParty/knockout.js";

/**
 * The view model for {@link Compass}.
 * @alias CompassViewModel
 * @constructor
 *
 * @param {Element|String} container The DOM element or ID that will contain the widget.
 * @param {Scene} scene The scene instance to use.
 */
function CompassViewModel(container, scene) {

  if (!defined(scene)) {
    throw new DeveloperError("scene is required.");
  }

  const that = this;
  this.showCompass= false;
  this._scene = scene;
  this._command = createCommand(function () {
    that.showCompass = !that.showCompass;
  });

  /**
   * Gets or sets the tooltip.  This property is observable.
   *
   * @type {String}
   */
  this.tooltip = "Compass";

  knockout.track(this, ["tooltip", "showCompass",]);
}

Object.defineProperties(CompassViewModel.prototype, {
  /**
   * Gets the scene to control.
   * @memberof CompassViewModel.prototype
   *
   * @type {Scene}
   */
  scene: {
    get: function () {
      return this._scene;
    },
  },

  /**
   * Gets the Command that is executed when the button is clicked.
   * @memberof CompassViewModel.prototype
   *
   * @type {Command}
   */
  command: {
    get: function () {
      return this._command;
    },
  },

  settingsEnableCompass: {
    get: function () {
      return this._settingsEnableCompass;
    },
    set: function () {
      this._settingsEnableCompass = !this._settingsEnableCompass;
    }
  }
});

export default CompassViewModel;

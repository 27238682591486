import { defined, DeveloperError } from "@cesium/engine";
import { createCommand } from "@cesium/widgets";
import knockout from "@cesium/widgets/Source/ThirdParty/knockout.js";

/**
 * The view model for {@link IsolateButton}.
 * @alias IsolateButtonViewModel
 * @constructor
 *
 * @param {Element|String} container The DOM element or ID that will contain the widget.
 * @param {Scene} scene The viewer instance to use.
 */
function IsolateButtonViewModel(container, scene) {
  if (!defined(scene)) {
    throw new DeveloperError("scene is required.");
  }

  const that = this;

  this.isolateEnabled = false;
  this._scene = scene;
  this._command = createCommand( () => toggleIsolation(!that.isolateEnabled));

  const toggleIsolation = (isolateEnabled) => {
    this.isolateEnabled = isolateEnabled;
    const isolateButton = document.querySelector('.cesium-isolate-button');
    if (this.isolateEnabled) {
      isolateButton.classList.add('active');
    } else {
      isolateButton.classList.remove('active');
    }
    scene.globe.show = !isolateEnabled;
    // scene.skyBox.show = !isolateEnabled;
  };
  /**
   * Gets or sets the tooltip.  This property is observable.
   *
   * @type {String}
   */
  this.tooltip = "Isolate";

  knockout.track(this, [
    "tooltip",
    "isolateEnabled",
  ]);
}

Object.defineProperties(IsolateButtonViewModel.prototype, {
  /**
   * Gets the scene to control.
   * @memberof IsolateButtonViewModel.prototype
   *
   * @type {Scene}
   */
  scene: {
    get: function () {
      return this._scene;
    },
  },

  /**
   * Gets the Command that is executed when the button is clicked.
   * @memberof IsolateButtonViewModel.prototype
   *
   * @type {Command}
   */
  command: {
    get: function () {
      return this._command;
    },
  },
});

export default IsolateButtonViewModel;

import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { FontAwesome, Box, MenuPopover, Button, ICON, useTheme } from 'ca-react-component-lib';
import MeasurementSettings from "../Widgets/Measure/MeasurementSettings.js";

/* jshint ignore:start */
export default function measurementButton({ viewer, mode, setMode, setShowMeasurement }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [buttonIcon, setButtonIcon] = React.useState("angle");

  const selectMeasurement = (measurementType) => {
    setShowMeasurement(false);
    viewer.measure.viewModel._measurements.map((m) => {
      m._savedId = undefined;
      return m;
    });
    setOpen(false);
    setMode(measurementType);

    let colorTarget = viewer.measure.viewModel.selectedMeasurement;
    while (colorTarget) {
      if (colorTarget._points) {
        colorTarget._points.color = MeasurementSettings.color;
      }
      if (colorTarget._polygon) {
        colorTarget._polygon.color = MeasurementSettings.color;
      }
      if (colorTarget._polyline) {
        colorTarget._polyline.color = MeasurementSettings.color;
      }
      if (colorTarget._pointCollection?._pointPrimitives) {
        colorTarget._pointCollection._pointPrimitives.map(p => {
          if (p) p.color = MeasurementSettings.color;
          return p;
        });
      }
      colorTarget = colorTarget._drawing ? colorTarget._drawing : undefined;
    }
  };

  React.useEffect(() => {
    if (getActiveMeasurement()) {
      viewer.measure.viewModel.selectedMeasurement = getActiveMeasurement();
      viewer.measure.viewModel._activate();
    } else if (viewer.measure.viewModel.selectedMeasurement) {
      viewer.measure.viewModel._deactivateMouseHandler();
    }
  }, [mode]);

  const getActiveMeasurement = () => {
    return viewer.measure.viewModel._measurements.find(x => x.type === mode)
  };

  const DropdownButton = ({ id, index, icon, name, keybind }) => (
    <Button
      id={id}
      className="dropdownKeyButton"
      onClick={() => {
        selectMeasurement(viewer.measure.viewModel._measurements[index]?.type);
        setButtonIcon(icon);
      }}
    >
      <Box sx={{ width: ICON.DEFAULT+"px" }}>
        {(mode === viewer.measure.viewModel._measurements[index]?.type)
          && <FontAwesome icon="check" color={theme.palette.info.light} />
        }
      </Box>
      <FontAwesome icon={icon} sx={{ ml: 2, mr: 2 }}/>
      {name}
      <Box sx={{ flexGrow: 1 }} />
      <div className="keybind">{keybind}</div>
    </Button>
  );

  return (
    <Box>
      <Button
        className={getActiveMeasurement() ? "toolbarButton active" : "toolbarButton"}
      >
        <FontAwesome
          className="toolbarIconButton"
          icon={(buttonIcon && getActiveMeasurement()) ? buttonIcon : "angle"}
          onClick={() => {
            selectMeasurement(viewer.measure.viewModel._measurements[0]?.type);
            setButtonIcon("horizontal-rule");
          }}
        />
        <Box
          className="toolbarChevronButton"
          onClick={(e) => setOpen(e.currentTarget)}
        >
          <FontAwesome
            icon={open ? "chevron-up" : "chevron-down"}
            size={ICON.SMALL}
          />
        </Box>
      </Button>
      <MenuPopover
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(false)}
        sx={{ width: "280px" }}
      >
        <DropdownButton
          id="distanceMeasurement"
          index={0}
          icon="horizontal-rule"
          name="Single Line"
          keybind="L"
        />
        <DropdownButton
          id="componentDistanceMeasurement"
          index={1}
          icon="draw-polygon"
          name="Component"
          keybind="C"
        />
        <DropdownButton
          id="polylineMeasurement"
          index={2}
          icon="angle"
          name="Polyline"
          keybind="P"
        />
        <DropdownButton
          id="horizontalMeasurement"
          index={3}
          icon="ruler-horizontal"
          name="Horizontal"
          keybind="H"
        />
        <DropdownButton
          id="verticalMeasurement"
          index={4}
          icon="ruler-vertical"
          name="Vertical"
          keybind="V"
        />
        <DropdownButton
          id="heightMeasurement"
          index={5}
          icon="line-height"
          name="Height from terrain"
          keybind="T"
        />
        <DropdownButton
          id="areaMeasurement"
          index={6}
          icon="chart-area"
          name="Area"
          keybind="A"
        />
        <DropdownButton
          id="pointMeasurement"
          index={7}
          icon="circle-dot"
          name="Point coordinates"
          keybind="D"
        />
      </MenuPopover>
    </Box>
  );
}
/* jshint ignore:end */
import {
 defined,
 DeveloperError,
 wrapFunction,
 Math as CesiumMath
} from "../../../Core/cesium/Source/Cesium.js";

import Compass from '../Compass/Compass.js';

/**
* A mixin which adds the Compass widget to the Viewer widget.
* Rather than being called directly, this function is normally passed as
* a parameter to {@link Viewer#extend}, as shown in the example below.
* @function
*
* @param {Viewer} viewer The viewer instance.
* @param {Object} [options] An object with the following properties:
* @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
*
* @exception {DeveloperError} viewer is required.
*
* @example
* var viewer = new Cesium.Viewer('cesiumContainer');
* viewer.extend(Cesium.viewerCompassMixin);
*/
function viewerCompassMixin(viewer, options) {

 if (!defined(viewer)) {
   throw new DeveloperError("viewer is required.");
 }

 const cesiumCompassContainer = document.createElement("div");
 cesiumCompassContainer.id = "cesium-viewer-compassContainer"
 cesiumCompassContainer.className = "cesium-viewer-compass";

 const cesiumCompassButton = document.createElement("button");
 cesiumCompassButton.className = "cesium-viewer-compassButton";

 const compassButtonTooltip = document.createElement('span');
 compassButtonTooltip.className = "cesium-viewer-compassButtonTooltip";
 compassButtonTooltip.innerText = 'Click to reset'
 cesiumCompassButton.appendChild(compassButtonTooltip);


 cesiumCompassButton.addEventListener('click', () => {
  viewer.camera.flyTo({
    destination: viewer.camera.positionWC,
    orientation: {
      heading: CesiumMath.toRadians(0),
      pitch: viewer.camera.pitch,
      roll: viewer.camera.roll
    }
  })
 })
 
 cesiumCompassContainer.appendChild(cesiumCompassButton);
 viewer._container.appendChild(cesiumCompassContainer)
 const compass = new Compass(cesiumCompassContainer, viewer);


 viewer.destroy = wrapFunction(viewer, viewer.destroy, function () {
   compass.destroy();
 });

 Object.defineProperties(viewer, {
  compass: {
     get: function () {
       return compass;
     },
   },
 });
}
export default  viewerCompassMixin;

/**
 * Event names used for the measure tools.
 *
 * @enum {String}
 */
const MeasureEventNames = {
  /**
   * @type {String}
   * @constant
   */
  NEW_GROUND_POINT_HEIGHT: "NEW_GROUND_POINT_HEIGHT",
};

export default Object.freeze(MeasureEventNames);

/* eslint-disable class-methods-use-this */
import AbstractTool from "./AbstractTool";
import Box from "./Box";
import { toolIds } from "./common";

import TransformPrimitiveEditor from "./TransformEditor/TransformPrimitiveEditor";
import "./TransformEditor/TransformEditor.css";

export default class BoxEditTool extends AbstractTool {
    constructor(cesiumTools) {
        super(toolIds.basic.boxEdit, cesiumTools);

        this._selectedEntity = undefined;
        this._transformEditor = undefined;
    }

    get selectedEntity() {
        return this._selectedEntity;
    }

    set selectedEntity(entity) {
        window.dispatchEvent(new CustomEvent("setShowPrimitive", { detail: entity }));
        if (entity instanceof Box) {
            this.deselectEntity();
            this.cancelTool();
            this._selectedEntity = entity;

            if (entity) {
                entity.selected = true;
            }

            if (this.cesiumTools.editShapeAllowed) {
                this.createManipulators();
            }

            this.afterSettingEntity();
        } else {
            console.error(`Passing invalid ToolEntity type to ManipulatorsTool: ${entity}`);
        }
    }

    cancelTool() {
        super.cancelTool();
        this.destroyManipulators();
    }

    deselectEntity() {
        if (this._selectedEntity) {
            this._selectedEntity.selected = false;
            this._selectedEntity = undefined;
        }

        this.destroyManipulators();
    }

    afterSettingEntity() {}

    createManipulators() {
        const viewer = this.cesiumTools.viewer;

        const boundingSphere = this._selectedEntity.boundingSphere;

        this._transformEditor = new TransformPrimitiveEditor({
            container: viewer.container,
            scene: viewer.scene,
            transform: this.selectedEntity.modelMatrix,
            boundingSphere: boundingSphere
        });

        this._transformEditor.viewModel.activate();

        const viewModel = this._transformEditor.viewModel;

        this._removePositionChangedCallback = viewModel.positionChanged.addEventListener(this._onPositionChanged.bind(this));
        this._removeHeadingPitchRollChangedCallback = viewModel.headingPitchRollChanged.addEventListener(
            this._onHeadingPitchRollChanged.bind(this)
        );
        this._removeScaleChangedCallback = viewModel.scaleChanged.addEventListener(this._onScaleChanged.bind(this));
    }

    destroyManipulators() {
        if (this._transformEditor) {
            this._transformEditor.viewModel.deactivate();
            this._transformEditor = undefined;

            if (this._removePositionChangedCallback) {
                this._removePositionChangedCallback();
                this._removePositionChangedCallback = undefined;
            }

            if (this._removeHeadingPitchRollChangedCallback) {
                this._removeHeadingPitchRollChangedCallback();
                this._removeHeadingPitchRollChangedCallback = undefined;
            }

            if (this._removeScaleChangedCallback) {
                this._removeScaleChangedCallback();
                this._removeScaleChangedCallback = undefined;
            }
        }
    }

    _onPositionChanged() {
        this._selectedEntity.forceUpdate();

        this.cesiumTools.entitiesUpdated([this._selectedEntity]);
    }

    _onHeadingPitchRollChanged() {
        this._selectedEntity.forceUpdate();

        this.cesiumTools.entitiesUpdated([this._selectedEntity]);
    }

    _onScaleChanged() {
        this._selectedEntity.forceUpdate();

        this.cesiumTools.entitiesUpdated([this._selectedEntity]);
    }
}

import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { FontAwesome, Box, MenuPopover, Button, ICON, useTheme } from 'ca-react-component-lib';

/* jshint ignore:start */
export default function MovementButton({ viewer, mode, setMode }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const selectMovement = () => {
    setOpen(false);
    setMode("move");
  }

  const isMovementSelected = () => {
    return mode === "move";
  }

  return (
    <Box>
      <Button
        className={isMovementSelected() ? "toolbarButton active" : "toolbarButton"}
      >
        <FontAwesome
          className="toolbarIconButton"
          icon="hand"
          onClick={(e) => selectMovement()}
        />
        <Box
          className="toolbarChevronButton"
          onClick={(e) => setOpen(e.currentTarget)}
        >
          <FontAwesome
            icon={open ? "chevron-up" : "chevron-down"}
            size={ICON.SMALL}
          />
        </Box>
      </Button>
      <MenuPopover
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(false)}
        sx={{ width: "280px" }}
      >
        <Button
          className="dropdownKeyButton"
          onClick={() => selectMovement()}
        >
          <Box sx={{ width: ICON.DEFAULT+"px" }}>
            {isMovementSelected() && <FontAwesome icon="check" color={theme.palette.info.light} />}
          </Box>
          <FontAwesome icon="hand" sx={{ ml: 2, mr: 2 }}/>
          Move
          <Box sx={{ flexGrow: 1 }} />
          <div className="keybind">M</div>
        </Button>
      </MenuPopover>
    </Box>
  );
}
/* jshint ignore:end */
import {
  defined,
  DeveloperError,
  wrapFunction,
} from "../../../Core/cesium/Source/Cesium.js";

import SettingsButton from "../SettingsButton/SettingsButton.js";

/**
 * A mixin which adds the Settings widget to the Viewer widget.
 * Rather than being called directly, this function is normally passed as
 * a parameter to {@link Viewer#extend}, as shown in the example below.
 * @function
 *
 * @param {Viewer} viewer The viewer instance.
 * @param {Object} [options] An object with the following properties:
 * @param {Cesium3DTileset} options.tileset The current tileset
 * @param {Boolean} options.georef Whether the model is georeferenced or not
 * @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
 *
 * @exception {DeveloperError} viewer is required.
 *
 * @example
 * var viewer = new Cesium.Viewer('cesiumContainer');
 * viewer.extend(Cesium.viewerSettingsButtonMixin);
 */
function viewerSettingsButtonMixin(viewer, options) {
  if (!defined(viewer)) {
    throw new DeveloperError("viewer is required.");
  }

  const cesiumSettingsContainer = document.createElement("div");
  cesiumSettingsContainer.className = "cesium-viewer-settingsContainer";
  viewer._toolbar.insertBefore(
    cesiumSettingsContainer,
    viewer._toolbar.lastChild
  );
  const settingsButton = new SettingsButton(cesiumSettingsContainer, viewer, options?.tileset, options?.georef);

  viewer.destroy = wrapFunction(viewer, viewer.destroy, function () {
    settingsButton.destroy();
  });

  Object.defineProperties(viewer, {
    settingsButton: {
      get: function () {
        return settingsButton;
      },
    },
  });
}
export default viewerSettingsButtonMixin;

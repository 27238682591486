import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { FontAwesome, Box, MenuPopover, Button, ICON, useTheme } from 'ca-react-component-lib';

/* jshint ignore:start */
export default function CameraButton({ viewer, tileset }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const resetCamera = () => {
    viewer._clearObjects();
    viewer.flyTo(tileset);
    setOpen(false);
  }

  return (
    <Box>
      <Button
        className={open ? "toolbarButton active" : "toolbarButton"}
      >
        <FontAwesome
          className="toolbarIconButton"
          icon="arrow-rotate-right"
          onClick={() => resetCamera()}
        />
        <Box
          className="toolbarChevronButton"
          onClick={(e) => setOpen(e.currentTarget)}
        >
          <FontAwesome
            icon={open ? "chevron-up" : "chevron-down"}
            size={ICON.SMALL}
          />
        </Box>
      </Button>
      <MenuPopover
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(false)}
        sx={{ width: "280px" }}
      >
        <Button
          className="dropdownKeyButton"
          onClick={() => {}}
          disabled
        >
          <FontAwesome icon="camera-movie" sx={{ mx: 2 }}/>
          New camera
          <Box sx={{ flexGrow: 1 }} />
          <div className="keybind">C</div>
        </Button>
        <Button
          className="dropdownKeyButton"
          onClick={() => resetCamera()}
        >
          <FontAwesome icon="arrow-rotate-right" sx={{ mx: 2 }}/>
          Reset view
          <Box sx={{ flexGrow: 1 }} />
          <div className="keybind">1</div>
        </Button>
      </MenuPopover>
    </Box>
  );
}
/* jshint ignore:end */
import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import {
  FontAwesome,
  IconButton,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ICON
} from "ca-react-component-lib";
import { useDrop } from 'react-dnd';
import LayersItem from "./LayersItem.js";

/* jshint ignore:start */
export default function LayersCollection({
  viewer,
  collection,
  activeCollection,
  setActiveCollection,
  collections,
  setCollections,
  comments,
  setComments,
  measurements,
  setMeasurements,
  primitives,
  setPrimitives,
  showMeasurement,
  setShowMeasurement,
  showPrimitive,
  setShowPrimitive,
  clippingPlanes,
  setClippingPlanes,
  setMode
}) {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'ListItemButton',
    drop: () => ({ name: 'Collection', collectionId: collection.id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const getCollectionItems = (collectionId) => {
    return [
      ...comments.filter(c => c.collection === collectionId),
      ...measurements.filter(c => c.collection === collectionId),
      ...primitives.filter(c => c.collection === collectionId),
    ];
  }

  const toggleCollectionVisibility = (collectionId) => {
    setCollections(collections.map(c => {
      if (c.id === collectionId) c.hide = !c.hide;
      return c;
    }));
  };

  const deleteCollection = (collectionId) => {
    setCollections(collections.filter(x => x.id !== collectionId));
    setComments(comments.filter(x => x.collection !== collectionId));
    setMeasurements(measurements.filter(x => x.collection !== collectionId));
    setPrimitives(primitives.filter(x => x.collection !== collectionId));
  };

  return (<div key={collection.id} ref={drop}>
    <ListItemButton className="layersItem">
        <div
          onClick={() => setActiveCollection((activeCollection === collection.id) ? undefined : collection.id)}
          className="collectionItem"
        >
          {(getCollectionItems(collection.id).length > 0) && <FontAwesome
            icon={(activeCollection === collection.id) ? "chevron-up" : "chevron-down"}
            size={ICON.SMALL}
            sx={{ mr: .5 }}
            className="layersChevron"
          />}
          <ListItemIcon sx={{ mr: 1 }}><FontAwesome icon="draw-square" /></ListItemIcon>
          <ListItemText primary={collection.name} />
          <IconButton className="eyeLayer" onClick={(e) => {e.stopPropagation(); toggleCollectionVisibility(collection.id)}}>
            <FontAwesome icon={collection.hide ? "eye-slash" : "eye"} type="solid" size="small" />
          </IconButton>
          <IconButton className="deleteLayer" onClick={(e) => {e.stopPropagation(); deleteCollection(collection.id)}}>
            <FontAwesome icon="trash" size="small" />
          </IconButton>
        </div>
        <Collapse in={activeCollection === collection.id} unmountOnExit>
          {getCollectionItems(collection.id).filter(x => x.collection === collection.id).sort((a, b) => a.index - b.index).map((collectionItem) => <LayersItem
            key={(collectionItem.measurement ? "measurement_" : collectionItem.primitive ? "primitive_" : "comment_")+collectionItem.id}
            viewer={viewer}
            item={collectionItem}
            comments={comments}
            setComments={setComments}
            measurements={measurements}
            setMeasurements={setMeasurements}
            primitives={primitives}
            setPrimitives={setPrimitives}
            showMeasurement={showMeasurement}
            setShowMeasurement={setShowMeasurement}
            showPrimitive={showPrimitive}
            setShowPrimitive={setShowPrimitive}
            clippingPlanes={clippingPlanes}
            setClippingPlanes={setClippingPlanes}
            setMode={setMode}
          />)}
        </Collapse>
      </ListItemButton>
  </div>);
}
/* jshint ignore:end */
import {
  AxisAlignedBoundingBox,
  BoxGeometry,
  BoxOutlineGeometry,
  BoundingSphere,
  Cartesian3,
  Cartographic,
  Color,
  ColorGeometryInstanceAttribute,
  DeveloperError,
  DebugModelMatrixPrimitive,
  Ellipsoid,
  GeometryInstance,
  HeadingPitchRoll,
  Math as CesiumMath,
  Matrix4,
  OrientedBoundingBox,
  PerInstanceColorAppearance,
  Primitive,
  PrimitiveCollection,
  Transforms
} from "../../Core/cesium/Source/Cesium.js";

import {
  georeferencedAtECEF,
  updateModelMatrixOfTilesetDefinedAtLocal,
  updateModelMatrixOfTilesetDefinedAtECEF
} from "./georeferencing";

const geoRefScratch = new Cartesian3();

const invalidPosition = Cartesian3.fromDegrees(0, 0, 0, Ellipsoid.WGS84, new Cartesian3());

export default class TilesetAsset {
  constructor(options) {
    this._scene = options.scene;
    this._initialGeolocation = options.assetGeolocation;
    this._originallyGeoreferencedAtECEF = false;

    this._geoRefAtLocal = new Cartesian3();
    this._geoRef = new Cartesian3();
    this._position = new Cartesian3();

    const primitiveCollection = new PrimitiveCollection();
    const tileset = options.tileset;

    // @ts-ignore
    primitiveCollection.id = "TilesetAsset-PrimitiveCollection";

    this._primitiveCollection = primitiveCollection;

    options.scene.primitives.add(primitiveCollection);

    // Model level of detail
    tileset.maximumScreenSpaceError = 8.0; // Default is 16
    tileset.maximumMemoryUsage = 512; // Default is 512

    tileset.pointCloudShading.maximumAttenuation = 3; // Don't allow points larger than 4 pixels.
    tileset.pointCloudShading.baseResolution = 0.44; // Assume an original capture resolution of 5 centimeters between neighboring points.
    tileset.pointCloudShading.geometricErrorScale = 0.3; // Applies to both geometric error and the base resolution.
    tileset.pointCloudShading.attenuation = true;
    tileset.pointCloudShading.eyeDomeLighting = true;
    tileset.pointCloudShading.eyeDomeLightingStrength = 0.5;
    tileset.pointCloudShading.eyeDomeLightingRadius = 0.5;

    this._tileset = tileset;

    this._onTilesetReady();
    this._ready = true;
  }

  getRefereceFrame(result) {
    const origin = this.origin;

    return Transforms.eastNorthUpToFixedFrame(origin, Ellipsoid.WGS84, result);
  }

  get tileset() {
    return this._tileset;
  }

  get positionChanged() {
    return this._positionChanged;
  }

  get origRootBoundingVolume() {
    return this._origRootBoundingVolume;
  }

  get originallyGeoreferencedAtECEF() {
    return this._originallyGeoreferencedAtECEF;
  }

  _doGeoreference() {
    const tileset = this.tileset;
    const position = this._position;
    const hpr = this.hpr;
    const scale = this.scale;

    if (this.originallyGeoreferencedAtECEF) {
      updateModelMatrixOfTilesetDefinedAtECEF(tileset, position, this._geoRefAtLocal, hpr, scale);
    } else {
      updateModelMatrixOfTilesetDefinedAtLocal(tileset, position, this._geoRefAtLocal, hpr, scale);
    }

    this.updateGeoRef();

    if (this._positionDebugModelMatrixPrimitive) {
      this._positionDebugModelMatrixPrimitive.modelMatrix = Transforms.eastNorthUpToFixedFrame(position);
    }
  }

  georeference(geolocation) {
    const cartographic = Cartographic.fromDegrees(geolocation.longitude, geolocation.latitude, geolocation.height);
    const position = Ellipsoid.WGS84.cartographicToCartesian(cartographic);

    position.clone(this._position);

    this._heading = CesiumMath.toRadians(geolocation.heading);
    this._pitch = CesiumMath.toRadians(geolocation.pitch);
    this._roll = CesiumMath.toRadians(geolocation.roll);

    this._scale = geolocation.scale.x;

    if (geolocation.localGeoRefX) {
      this._geoRefAtLocal.x = geolocation.localGeoRefX;
    }

    if (geolocation.localGeoRefY) {
      this._geoRefAtLocal.y = geolocation.localGeoRefY;
    }

    if (geolocation.localGeoRefZ) {
      this._geoRefAtLocal.z = geolocation.localGeoRefZ;
    }

    this._doGeoreference();
  }

  _updateGeoRefLocal() {
    console.assert(this._geoRef, "error");

    const worldToLocal = this.worldToLocalMatrix(new Matrix4());

    this._geoRefAtLocal = Matrix4.multiplyByPoint(worldToLocal, this._geoRef, new Cartesian3());
  }

  updateGeoRef() {
    if (!this._geoRefAtLocal) {
      return;
    }

    const localToWorld = this.localToWorldMatrix(new Matrix4());

    this._geoRef = Matrix4.multiplyByPoint(localToWorld, this._geoRefAtLocal, geoRefScratch);
  }

  hasValidGeoRef() {
    return !Cartesian3.ZERO.equals(this._geoRefAtLocal);
  }

  get geoRef() {
    return this._geoRef;
  }

  geoRefJson() {
    const carto = Cartographic.fromCartesian(this._geoRef);

    const data = {
      longitude: CesiumMath.toDegrees(carto.longitude),
      latitude: CesiumMath.toDegrees(carto.latitude),
      height: carto.height
    };

    return JSON.stringify(data);
  }

  height() {
    const tileset = this.tileset;

    if (this._originallyGeoreferencedAtECEF) {
      const carto = Cartographic.fromCartesian(tileset.boundingSphere.center);

      return carto.height;
    }

    const position = Matrix4.getTranslation(tileset.modelMatrix, new Cartesian3());
    const carto = Cartographic.fromCartesian(position);

    return carto.height;
  }

  heightOfGeoRef() {
    const carto = Cartographic.fromCartesian(this._geoRef);

    return carto.height;
  }

  _extrude(deltaHeight) {
    const geolocation = this.calculateGeolocation();
    geolocation.height += deltaHeight;
    this.georeference(geolocation);
  }

  worldToLocalMatrix(result) {
    const tileset = this.tileset;

    if (!this._originallyGeoreferencedAtECEF) {
      return Matrix4.inverse(tileset.modelMatrix, result);
    }

    const referenceFrame = this.getRefereceFrame(new Matrix4());

    Matrix4.inverseTransformation(referenceFrame, result);

    const invModelMatrix = Matrix4.inverse(tileset.modelMatrix, new Matrix4());

    Matrix4.multiply(result, invModelMatrix, result);

    return result;
  }

  localToWorldMatrix(result) {
    const tileset = this.tileset;

    if (!this._originallyGeoreferencedAtECEF) {
      return tileset.modelMatrix.clone(result);
    }

    this.getRefereceFrame(result);

    return Matrix4.multiply(tileset.modelMatrix, result, result);
  }

  initialGeoreference() {
    const tileset = this.tileset;

    const geolocation = this._initialGeolocation;

    if (geolocation) {
      this.georeference(geolocation);
    } else if (this._originallyGeoreferencedAtECEF) {
      this._position = tileset.boundingSphere.center.clone();
      if (this._positionDebugModelMatrixPrimitive) {
        this._positionDebugModelMatrixPrimitive.modelMatrix = Transforms.eastNorthUpToFixedFrame(
          this._position
        );
      }
    } else {
      Cartesian3.fromDegrees(0, 0, 0, Ellipsoid.WGS84, this._position);
      tileset.modelMatrix = Transforms.eastNorthUpToFixedFrame(this._position);

      if (this._positionDebugModelMatrixPrimitive) {
        this._positionDebugModelMatrixPrimitive.modelMatrix = Transforms.eastNorthUpToFixedFrame(
          this._position
        );
      }
    }
  }

  get georeferenced() {
    return !invalidPosition.equals(this._position);
  }

  get origin() {
    const tileset = this.tileset;
    const modelMatrix = tileset.modelMatrix;

    if (!this._originallyGeoreferencedAtECEF) {
      return Matrix4.getTranslation(modelMatrix, new Cartesian3());
    }

    // originally georeferenced at ECEF
    return this.origRootBoundingVolume?.center;
  }

  get position() {
    return this._position;
  }

  set position(position) {
    position.clone(this._position);

    this._doGeoreference();
  }

  get hpr() {
    return new HeadingPitchRoll(this._heading, this._pitch, this._roll);
  }

  set hpr(hpr) {
    this._heading = hpr.heading;
    this._pitch = hpr.pitch;
    this._roll = hpr.roll;

    this._doGeoreference();
  }

  set scale(scale) {
    this._scale = scale.x;
    this._doGeoreference();
  }

  get scale() {
    return new Cartesian3(this._scale, this._scale, this._scale);
  }

  calculateGeolocation(round = false) {
    const cartographic = Cartographic.fromCartesian(this._position);
    const scale = this._scale;
    const geoRefAtLocal = this._geoRefAtLocal;

    if (!round) {
      return {
        longitude: CesiumMath.toDegrees(cartographic.longitude),
        latitude: CesiumMath.toDegrees(cartographic.latitude),
        height: cartographic.height,
        localGeoRefX: geoRefAtLocal.x,
        localGeoRefY: geoRefAtLocal.y,
        localGeoRefZ: geoRefAtLocal.z,
        heading: CesiumMath.toDegrees(this._heading),
        pitch: CesiumMath.toDegrees(this._pitch),
        roll: CesiumMath.toDegrees(this._roll),
        scale: {
          x: scale,
          y: scale,
          z: scale
        }
      };
    }

    const digit = 2;

    return {
      longitude: CesiumMath.toDegrees(cartographic.longitude),
      latitude: CesiumMath.toDegrees(cartographic.latitude),
      height: parseFloat(cartographic.height.toFixed(digit)),
      localGeoRefX: parseFloat(geoRefAtLocal.x.toFixed(digit)),
      localGeoRefY: parseFloat(geoRefAtLocal.y.toFixed(digit)),
      localGeoRefZ: parseFloat(geoRefAtLocal.z.toFixed(digit)),
      heading: parseFloat(CesiumMath.toDegrees(this._heading).toFixed(digit)),
      pitch: parseFloat(CesiumMath.toDegrees(this._pitch).toFixed(digit)),
      roll: parseFloat(CesiumMath.toDegrees(this._roll).toFixed(digit)),
      scale: {
        x: parseFloat(scale.toFixed(1)),
        y: parseFloat(scale.toFixed(1)),
        z: parseFloat(scale.toFixed(1))
      }
    };
  }

  _onTilesetReady() {
    const tileset = this.tileset;

    // @ts-ignore
    const boundingVolume = tileset.root.boundingVolume.boundingVolume;

    if (boundingVolume instanceof OrientedBoundingBox) {
      this._origRootBoundingVolume = OrientedBoundingBox.clone(boundingVolume, new OrientedBoundingBox());
    } else if (boundingVolume instanceof BoundingSphere) {
      this._origRootBoundingVolume = BoundingSphere.clone(boundingVolume, new BoundingSphere());
    } else {
      throw new DeveloperError("unexpected bounding volume");
    }

    if (georeferencedAtECEF(tileset)) {
      this._originallyGeoreferencedAtECEF = true;
    }

    this._scene.primitives.add(tileset);

    if (this._debug) {
      this._positionDebugModelMatrixPrimitive = this._primitiveCollection.add(
        new DebugModelMatrixPrimitive({
          modelMatrix: Matrix4.IDENTITY,
          length: tileset.boundingSphere.radius * 1.5,
          width: 3
        })
      );
    }

    this.initialGeoreference();

    let maxOfNumberOfTilesProcessing = 0;

    tileset.loadProgress.addEventListener((numberOfPendingRequests, numberOfTilesProcessing) => {
      if (numberOfPendingRequests === 0 && numberOfTilesProcessing === 0) {
        maxOfNumberOfTilesProcessing = 0;
      }

      if (maxOfNumberOfTilesProcessing < numberOfTilesProcessing) {
        maxOfNumberOfTilesProcessing = numberOfTilesProcessing;
      }

      if (maxOfNumberOfTilesProcessing === 0) {
        this._percentOfLoadingProgress = 100;
        return;
      }

      this._percentOfLoadingProgress = (1 - numberOfTilesProcessing / maxOfNumberOfTilesProcessing) * 100;
    });

    const aabbox = this.getAABoundingBox(new AxisAlignedBoundingBox());

    const dimensions = Cartesian3.subtract(aabbox.maximum, aabbox.minimum, new Cartesian3());

    this._aabbox = this._primitiveCollection.add(
      new Primitive({
        show: false,
        geometryInstances: new GeometryInstance({
          geometry: BoxGeometry.fromDimensions({
            vertexFormat: PerInstanceColorAppearance.VERTEX_FORMAT,
            dimensions: dimensions
          }),
          modelMatrix: Transforms.eastNorthUpToFixedFrame(this.position),
          attributes: {
            color: ColorGeometryInstanceAttribute.fromColor(Color.RED.withAlpha(0.2))
          }
        }),
        appearance: new PerInstanceColorAppearance({
          closed: true
        })
      })
    );

    this._aabboxOutline = this._primitiveCollection.add(
      new Primitive({
        show: false,
        geometryInstances: new GeometryInstance({
          geometry: BoxOutlineGeometry.fromDimensions({
            dimensions: dimensions
          }),
          modelMatrix: Transforms.eastNorthUpToFixedFrame(this.position),
          attributes: {
            color: ColorGeometryInstanceAttribute.fromColor(Color.YELLOW)
          }
        }),
        appearance: new PerInstanceColorAppearance({
          flat: true
        })
      })
    );
  }

  showHideBox(show) {
    this._aabbox.show = show;
    this._aabboxOutline.show = show;
  }

  get percentOfLoadingProgress() {
    return this._percentOfLoadingProgress;
  }

  toggle() {
    const tileset = this.tileset;
    tileset.show = !tileset.show;
  }

  getAABoundingBox(result) {
    const tileset = this.tileset;

    // @ts-ignore
    const boundingVolume = tileset.root.boundingVolume.boundingVolume;
    const positions = [];

    if (boundingVolume instanceof OrientedBoundingBox) {
      const corners = OrientedBoundingBox.computeCorners(boundingVolume);

      corners.forEach((corner) => {
        positions.push(corner);
      });
    } else if (boundingVolume instanceof BoundingSphere) {
      const center = boundingVolume.center;
      const radius = boundingVolume.radius;

      const min = new Cartesian3(center.x - radius, center.y - radius, center.z - radius);
      const max = new Cartesian3(center.x + radius, center.y + radius, center.z + radius);

      return new AxisAlignedBoundingBox(min, max, center);
    } else {
      throw new DeveloperError("unexpected boundingVolume");
    }

    return AxisAlignedBoundingBox.fromPoints(positions, result);
  }

  getDimensions() {
    const tileset = this._tileset;

    // @ts-ignore
    const boundingVolume = tileset.root.boundingVolume.boundingVolume;

    if (boundingVolume instanceof OrientedBoundingBox) {
      const halfAxes = boundingVolume.halfAxes;

      let xAxisHalfLength = halfAxes[0] * halfAxes[0] + halfAxes[1] * halfAxes[1] + halfAxes[2] * halfAxes[2];
      let zAxisHalfLength = halfAxes[3] * halfAxes[3] + halfAxes[4] * halfAxes[4] + halfAxes[5] * halfAxes[5];
      let yAxisHalfLength = halfAxes[6] * halfAxes[6] + halfAxes[7] * halfAxes[7] + halfAxes[8] * halfAxes[8];

      xAxisHalfLength = Math.sqrt(xAxisHalfLength);
      yAxisHalfLength = Math.sqrt(yAxisHalfLength);
      zAxisHalfLength = Math.sqrt(zAxisHalfLength);

      return {
        x: xAxisHalfLength * 2,
        y: yAxisHalfLength * 2,
        z: zAxisHalfLength * 2
      };
    }

    if (boundingVolume instanceof BoundingSphere) {
      const radius = boundingVolume.radius;

      return {
        x: radius * 2,
        y: radius * 2,
        z: radius * 2
      };
    }

    throw new DeveloperError("unexpected boundingVolume");
  }
}
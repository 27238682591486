import {
  defaultValue,
  defined,
  destroyObject,
  Cartesian2,
  Cartesian3,
  Cartographic,
  HorizontalOrigin,
  VerticalOrigin,
  SceneMode,
  Math as CesiumMath,
} from "../../../Core/cesium/Source/Cesium.js";

import PolylinePrimitive from "../../Scene/PolylinePrimitive.js";
import getWorldPosition from "../getWorldPosition.js";
import Measurement from "./Measurement.js";
import MeasurementSettings from "./MeasurementSettings.js";
import MeasureUnits from "./MeasureUnits.js";
import getSlope from "./getSlope.js";
import { Color } from "@cesium/engine";

const scratch = new Cartesian3();
const scratchCarto = new Cartographic();
const scratchCartesian = new Cartesian3();
const scratchCartographic = new Cartographic();

function getIcon(size) {
  return `<svg viewBox="0 0 30 30" height="${size}px" width="${size}px">\n\
             <g transform="translate(0,-267)">\n\
               <path d="m 15.042838,272.34414 0.01712,19.60575"/>\n\
               <circle r="2.0788691" cy="270.01154" cx="15.078616"/>\n\
               <path d="m 0.64901081,296.20687 8.80039389,-6.01044 7.9375003,3.1183 12.347278,-3.34365"/>\n\
             </g>\n\
           </svg>`;
}
/**
 * Draws a measurement between a selected point and the ground beneath that point.
 *
 * @param {Object} options An object with the following properties:
 * @param {Scene} options.scene The scene
 * @param {MeasureUnits} options.units The selected units of measurement
 * @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
 * @param {PointPrimitiveCollection} options.points A collection for adding the point primitives
 * @param {LabelCollection} options.labels A collection for adding the labels
 * @param {PrimitiveCollection} options.primitives A collection for adding primitives
 *
 * @constructor
 * @alias HeightMeasurement
 */
function HeightMeasurement(options) {
  options = defaultValue(options, defaultValue.EMPTY_OBJECT);
  Measurement.call(this, options);

  const positions = [new Cartesian3(), new Cartesian3()];
  const pointCollection = this._pointCollection;
  const groundPointCollection = this._groundPointCollection;

  const color = options.color ? Color.fromCssColorString(options.color) : undefined;
  this._startPoint = pointCollection.add(MeasurementSettings.getPointOptions({ color }));
  this._endPoint = pointCollection.add(MeasurementSettings.getPointOptions({ color }));

  this._polyline = this._primitives.add(
    new PolylinePrimitive(
      MeasurementSettings.getPolylineOptions({
        ellipsoid: this._scene.frameState.mapProjection.ellipsoid,
        positions: positions,
      })
    )
  );

  this._point = this._groundPointCollection.add(
    MeasurementSettings.getPointOptions({ color })
  );

  this._label = this._labelCollection.add(
    MeasurementSettings.getLabelOptions({
      horizontalOrigin: HorizontalOrigin.LEFT,
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(10, 10),
    })
  );

  this._positions = positions;
  this._distance = 0;
}

HeightMeasurement.prototype = Object.create(Measurement.prototype);

HeightMeasurement.prototype.constructor = HeightMeasurement;

Object.defineProperties(HeightMeasurement.prototype, {
  /**
   * Gets the distance in meters
   * @type {Number}
   * @memberof HeightMeasurement.prototype
   * @readonly
   */
  distance: {
    get: function () {
      return this._distance;
    },
  },
  /**
   * Gets the icon.
   * @type {String}
   * @memberof HeightMeasurement.prototype
   * @readonly
   */
  icon: {
    value: getIcon(15),
  },
  /**
   * Gets the thumbnail.
   * @type {String}
   * @memberof HeightMeasurement.prototype
   * @readonly
   */
  thumbnail: {
    value: getIcon(25),
  },
  /**
   * Gets the type.
   * @type {String}
   * @memberof HeightMeasurement.prototype
   * @readonly
   */
  type: {
    value: "Height from terrain",
  },
  /**
   * Gets the instruction text.
   * @type {String[]}
   * @memberof HeightMeasurement.prototype
   * @readonly
   */
  instructions: {
    value: [
      "Right click on the ground to set ground point, then click on the model to get a distance from that point to terrain (ground point)",
    ],
  },
  /**
   * Gets the id.
   * @type {String}
   * @memberof HeightMeasurement.prototype
   * @readonly
   */
  id: {
    value: "heightMeasurement",
  },
});

HeightMeasurement.prototype._redrawMeasurements = function (e) {
  if (e.detail.measurementType != "HeightMeasurement") return false;

  this._positions[0] = new Cartesian3(
    e.detail.pos1.x,
    e.detail.pos1.y,
    e.detail.pos1.z
  );
  this._positions[1] = new Cartesian3(
    e.detail.pos2.x,
    e.detail.pos2.y,
    e.detail.pos2.z
  );
  this._polyline.positions = this._positions;
  this._polyline.show = true;

  this._startPoint.position = this._polyline.positions[0];
  this._startPoint.show = true;

  this._endPoint.position = this._polyline.positions[1];
  this._endPoint.show = true;

  this._label.text = e.detail.label;
  this._label.position = new Cartesian3.midpoint(
    this._polyline.positions[0],
    this._polyline.positions[1],
    scratchCartesian
  );
  this._label.show = true;

  this._scene._view.camera.position = new Cartesian3(
    e.detail.camera.position.x,
    e.detail.camera.position.y,
    e.detail.camera.position.z
  );
  this._scene._view.camera.direction = new Cartesian3(
    e.detail.camera.direction.x,
    e.detail.camera.direction.y,
    e.detail.camera.direction.z
  );
  this._scene._view.camera.up = new Cartesian3(
    e.detail.camera.up.x,
    e.detail.camera.up.y,
    e.detail.camera.up.z
  );

  if (e.detail.color) {
    let colorTarget = this;
    while (colorTarget) {
      if (colorTarget._points) {
        colorTarget._points.map(p => {
          if (p) p.color = Color.fromCssColorString(e.detail.color);
          return p;
        });
      }
      if (colorTarget._polygon) {
        colorTarget._polygon.color = Color.fromCssColorString(e.detail.color);
      }
      if (colorTarget._polyline) {
        colorTarget._polyline.color = Color.fromCssColorString(e.detail.color);
      }
      if (colorTarget._startPoint) {
        colorTarget._startPoint.color = Color.fromCssColorString(e.detail.color);
      }
      if (colorTarget._endPoint) {
        colorTarget._endPoint.color = Color.fromCssColorString(e.detail.color);
      }
      colorTarget = colorTarget._drawing ? colorTarget._drawing : undefined;
    }
  }
};

/**
 * Handles click events while drawing a height measurement.
 * @param {Cartesian2} clickPosition The click position
 */
HeightMeasurement.prototype.handleClick = function (clickPosition) {
  if (typeof this._height === "undefined") {
    alert("Please select ground point by doing right click on the model.");
    return false;
  }

  const scene = this._scene;
  this.reset();

  const positions = this._positions;

  const pos0 = HeightMeasurement._getWorldPosition(
    scene,
    clickPosition,
    positions[0]
  );
  if (!defined(pos0)) {
    return;
  }

  const globe = scene.globe;
  const ellipsoid = scene.frameState.mapProjection.ellipsoid;

  const carto = ellipsoid.cartesianToCartographic(pos0, scratchCarto);


  /*if (defined(globe)) {
    carto.height = defaultValue(globe.getHeight(carto), 0);
  } else {
    carto.height = 0;
  }*/

  carto.height = this._height;

  const pos1 = ellipsoid.cartographicToCartesian(carto, positions[1]);

  const vec = Cartesian3.subtract(pos1, pos0, scratch);
  const distance = Cartesian3.magnitude(vec);

  const label = this._label;
  label.position = pos0;
  label.show = true;

  this._polyline.positions = positions;
  this._polyline.show = true;
  this._startPoint.position = pos0;
  this._startPoint.show = true;
  this._endPoint.position = pos1;
  this._endPoint.show = true;

  this._distance = distance;

  const getDistance = MeasureUnits.distanceToString(
    this._distance,
    this._selectedUnits.distanceUnits,
    this._selectedLocale
  );

  const event = new CustomEvent("measurement", {
    detail: {
      type: "Ground Height",
      value: getDistance,
      meta: {
        measurementType: "HeightMeasurement",
        camera: {
          position: {
            x: this._scene._view.camera.position.x,
            y: this._scene._view.camera.position.y,
            z: this._scene._view.camera.position.z,
          },
          direction: {
            x: this._scene._view.camera.direction.x,
            y: this._scene._view.camera.direction.y,
            z: this._scene._view.camera.direction.z,
          },
          up: {
            x: this._scene._view.camera.up.x,
            y: this._scene._view.camera.up.y,
            z: this._scene._view.camera.up.z,
          },
        },
        label: getDistance,
        pos1: {
          x: this._positions[0].x,
          y: this._positions[0].y,
          z: this._positions[0].z,
        },
        pos2: {
          x: this._positions[1].x,
          y: this._positions[1].y,
          z: this._positions[1].z,
        },
      },
    },
  });
  document.dispatchEvent(event);

  this._refreshLabels();
};

HeightMeasurement.prototype.handleRightClick = function (movePosition) {
  const scene = this._scene;
  this.resetGroundPoint();

  if (scene.mode === SceneMode.MORPHING) {
    return;
  }

  this._point.show = false;

  const position = HeightMeasurement._getWorldPosition(
    scene,
    movePosition,
    scratchCartesian
  );

  if (!defined(position)) {
    return;
  }

  this._point.position = position;

  const positionCartographic = scene.frameState.mapProjection.ellipsoid.cartesianToCartographic(
    position,
    scratchCartographic
  );
  let height = 0.0;
  if (defined(scene.globe)) {
    height = defaultValue(scene.globe.getHeight(positionCartographic), 0.0);
  }
  height = positionCartographic.height - height;
  if (CesiumMath.equalsEpsilon(height, 0.0, CesiumMath.EPSILON3)) {
    height = 0.0;
  }

  const event = new CustomEvent("onGroundPositionHeightChange", {
    detail: {
      type: "Ground Position Height Changed",
      value: height,
    },
  });
  document.dispatchEvent(event);

  let slope;
  if (scene.mode !== SceneMode.SCENE2D) {
    slope = HeightMeasurement._getSlope(scene, movePosition, this._primitives);
  }

  this._point.show = true;

  const label = this._label;
  label.position = position;
  label.show = true;

  this._position = Cartesian3.clone(position, this._position);
  this._height = height;
  this._slope = slope;

  this._refreshGroundPointLabels();
};

/**
 * Refreshes label text.
 * @private
 */
HeightMeasurement.prototype._refreshLabels = function () {
  const label = this._label;
  label.text = MeasureUnits.distanceToString(
    this._distance,
    this._selectedUnits.distanceUnits,
    this._selectedLocale
  );
};

HeightMeasurement.prototype._refreshGroundPointLabels = function () {
  const scene = this._scene;
  const label = this._label;
  const positionCartographic = scene.frameState.mapProjection.ellipsoid.cartesianToCartographic(
    this._position,
    scratchCartographic
  );

  label.text = "Ground point";
};

HeightMeasurement.prototype.resetGroundPoint = function () {
  this._label.show = false;
  this._point.show = false;
  this._position = Cartesian3.clone(Cartesian3.ZERO, this._position);
};

/**
 * Resets the widget.
 */
HeightMeasurement.prototype.reset = function () {
  this._polyline.show = false;
  this._label.show = false;
  this._startPoint.show = false;
  this._endPoint.show = false;
  this._distance = 0;
  this.resetGroundPoint();
};

HeightMeasurement.prototype._pickPositionSupported = function () {
  return this._scene.pickPositionSupported;
};

/**
 * @returns {Boolean} true if the object has been destroyed, false otherwise.
 */
HeightMeasurement.prototype.isDestroyed = function () {
  return false;
};

/**
 * Destroys the measurement.
 */
HeightMeasurement.prototype.destroy = function () {
  this._primitives.remove(this._polyline);

  const points = this._pointCollection;
  points.remove(this._startPoint);
  points.remove(this._endPoint);

  this._labelCollection.remove(this._label);

  return destroyObject(this);
};

// exposed for specs
HeightMeasurement._getSlope = getSlope;
HeightMeasurement._getWorldPosition = getWorldPosition;
export default HeightMeasurement;

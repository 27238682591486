import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { FontAwesome, Box, Drawer, Button, Typography, Paper, IconButton, List, ListItem, Divider } from 'ca-react-component-lib';

/* jshint ignore:start */
export default function AssetProfile({site, currentSiteAssessment}) {
  const [open, setOpen] = React.useState(false);
  
  const qualityControlIcon = React.useCallback(() => {
    if (currentSiteAssessment?.lastQcRecordStatus === 'passed') {
     return <FontAwesome icon="circle-check" color='#2E7D32' type='solid' size={24} sx={{ mr: 1}} />
    } else if (currentSiteAssessment?.lastQcRecordStatus === 'failed') {
      return  <FontAwesome icon="circle-x" color='#FF4842' type='solid' size={24} sx={{ mr: 1 }} />
    } else {
      return 
    }
  }, [currentSiteAssessment?.lastQcRecordStatus])

  const handleOpen = () => {
    if(!site || !currentSiteAssessment) {
      return;
    } else {
      setOpen(!open)
    }
  }

  return (
    <Box >
      <Button className={open ? "toolbarButton active" : "toolbarButton"} onClick={handleOpen}>
        <FontAwesome
          icon="circle-info"
        />
      </Button>
      <Drawer
        hideBackdrop
        anchor="right"
        open={open}
        className="toolbarDrawer"
        onClose={() => setOpen(false)}
        ModalProps={{
          disableEnforceFocus: true,
        }}
      >
       <List>
          <ListItem>
            <FontAwesome
              icon="circle-info"
              sx={{ mr: 2 }}
            />
            <span className="drawerTitle">Asset Profile</span>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => setOpen(false)}>
              <FontAwesome icon="xmark" />
            </IconButton>
          </ListItem>
        </List>

        <Divider />

      {site && currentSiteAssessment && (
        <>
          <Paper sx={{ p: 2, m: 3, display: 'flex', flexDirection: 'column', gap: 2 }} >
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Quality Control</Typography>
                {currentSiteAssessment?.lastQcRecordStatus ? (
                  <Box  sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography  sx={{ display: 'flex', textTransform: 'capitalize' }}>
                      {qualityControlIcon()}
                      {currentSiteAssessment?.lastQcRecordStatus}
                    </Typography>
                    {currentSiteAssessment?.lastLogTimestamp && (
                      <Typography sx={{ ml: 1, color: '#8F98A4', fontSize: '14px' }}>
                      ({new Date(currentSiteAssessment?.lastLogTimestamp).toDateString().slice(4)})
                      </Typography>
                    )}
                  </Box>
          ) : (
            <Typography>N/A</Typography>
          )} 
            </Box>

            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Site id</Typography>
              <Typography>{site?.companySiteId ? `#${site?.companySiteId}` : 'N/A'}</Typography>
            </Box>
        
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Owner</Typography>
              <Typography>{site?.site?.owner?.name || 'N/A'}</Typography>
            </Box>
            
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Owner site id</Typography>
              <Typography>{site?.site?.owner?.id || 'N/A' }</Typography>
            </Box>
          
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Asset class</Typography>
              <Typography>{site?.site?.assetClassName || 'N/A' }</Typography>
            </Box>

            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Structure type</Typography>
              <Typography>{site?.site?.structureTypeName || 'N/A' }</Typography>
            </Box>

            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Structure height</Typography>
              <Typography>{site?.site?.structureHeightAgl ?  `${site?.site?.structureHeightAgl} ft.` : 'N/A' }</Typography>
            </Box>
            
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Assessment tag</Typography>
              <Typography>{currentSiteAssessment?.assessmentTag || 'N/A' }</Typography>
            </Box>
            

            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Data type</Typography>
              <Typography>{currentSiteAssessment?.dataType || 'N/A' }</Typography>
            </Box>
          
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Drone vendor</Typography>
              <Typography>{currentSiteAssessment?.vendor?.name || 'N/A'}</Typography>
            </Box>
          
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Flight date</Typography>
              <Typography>{currentSiteAssessment?.date ? new Date(currentSiteAssessment?.date).toLocaleDateString() : 'N/A'}</Typography>
            </Box>
            
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Elevation</Typography>
              <Typography>{site?.site?.elevation ? `${site?.site?.elevation.toFixed(2)} ft.` : 'N/A'}</Typography>
            </Box>
          
            <Box>
              <Typography variant='subtitle2' color={'#8F98A4'}>Flood zone</Typography>
              {site?.site?.floodZoneDesignation ? (
              <Box sx={{display: 'flex', justifyContent: 'center', borderRadius: '50%', border: '2px solid #666666', width: '5%'}}>
                <Typography sx={{fontSize: '12px', fontWeight: 700, color: '#666666'}}>{site?.site?.floodZoneDesignation.trim()}</Typography>
              </Box>
              ) : (
                <Typography>{'N/A'}</Typography>
              )}
            </Box>
          </Paper>
          <Box sx={{ mt: 1 }}>
          <Divider />
          <Button  sx={{ backgroundColor: '#E0E0E0', color: '#212121', borderRadius: '8px', float: 'right', my: 1, mr: 2, '&:hover': {
            color: '#FFF'
          } }} variant='contained' onClick={() => setOpen(false)}>Close</Button>
        </Box>
      </>
          )}
      </Drawer>
    </Box>
  );
}
/* jshint ignore:end */

import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import { FontAwesome, Box, Button } from "ca-react-component-lib";

/* jshint ignore:start */
export default function CommentButton({ toolActive, setToolActive }) {

  return (<>
    <Box>
      <Button className={toolActive ? "toolbarButton active" : "toolbarButton"} onClick={() => setToolActive(!toolActive)}>
          <FontAwesome
            icon="comment"
          />
        </Button>
    </Box>
  </>);
}
/* jshint ignore:end */
import {
  defaultValue,
  destroyObject,
  Cartesian2,
  HorizontalOrigin,
  VerticalOrigin,
} from "../../../Core/cesium/Source/Cesium.js";

import Measurement from "./Measurement.js";
import MeasurementSettings from "./MeasurementSettings.js";

function measurementItemTemplate(
  index,
  measurement_type,
  measurement_value,
  measurement_comment
) {
  let draw = "";
  let remove = "";
  if (
    measurement_type == "Distance" ||
    measurement_type == "Height" ||
    measurement_type == "Area" ||
    measurement_type == "Polyline Distance" ||
    measurement_type == "Horizontal Polyline Distance" ||
    measurement_type == "Ground Height"
  ) {
    draw = `<a href="" id="redraw_measurement_${index}"  class="redraw_measurement" style="display: block">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" style="width: 1.25rem; height: 1.25rem; color: rgb(156 163 175);" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 74.16L437.8 14.06c-18.75-18.75-49.19-18.75-67.93 0l-56.53 56.55l127.1 128l56.56-56.55C516.7 123.3 516.7 92.91 497.9 74.16zM290.8 93.23l-259.7 259.7c-2.234 2.234-3.755 5.078-4.376 8.176l-26.34 131.7C-1.921 504 7.95 513.9 19.15 511.7l131.7-26.34c3.098-.6191 5.941-2.141 8.175-4.373l259.7-259.7L290.8 93.23z"></path></svg>
        </a>`;
    remove = `<a href="" id="remove_measurement_${index}" class="remove_measurement" style="display: block; margin-left: 1.25rem; ">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-can" style="width: 1.25rem; height: 1.25rem; color: rgb(156 163 175);" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464C32 490.5 53.5 512 80 512h288c26.5 0 48-21.5 48-48V128H32V464zM304 208C304 199.1 311.1 192 320 192s16 7.125 16 16v224c0 8.875-7.125 16-16 16s-16-7.125-16-16V208zM208 208C208 199.1 215.1 192 224 192s16 7.125 16 16v224c0 8.875-7.125 16-16 16s-16-7.125-16-16V208zM112 208C112 199.1 119.1 192 128 192s16 7.125 16 16v224C144 440.9 136.9 448 128 448s-16-7.125-16-16V208zM432 32H320l-11.58-23.16c-2.709-5.42-8.25-8.844-14.31-8.844H153.9c-6.061 0-11.6 3.424-14.31 8.844L128 32H16c-8.836 0-16 7.162-16 16V80c0 8.836 7.164 16 16 16h416c8.838 0 16-7.164 16-16V48C448 39.16 440.8 32 432 32z"></path></svg>
        </a>`;
  }
  const newItem = document.createElement("li");
  newItem.setAttribute("data-key", `${index}`);
  newItem.className = "measurement_item";
  newItem.innerHTML = `<div style="display:flex">
                    <div style="display: flex; align-items: center; flex: 1">
                        <div style="flex: 1">
                            <div style="display: block">
                                <div>
                                    <p style="font-size: 0.875rem; line-height: 1.25rem; display: flex; align-items: center; color: rgb(107 114 128);" >
                                        <input name="measurement_type" value="${measurement_type}" type="hidden"/>
                                        <input name="measurement_value" value="${measurement_value}" type="hidden"/>
                                        <textarea rows="1" name="measurement_comment" class="cesium-measure-data-management-textarea">${measurement_comment}</textarea>
                                    </p>
                                    <p style="font-size: 0.875rem; line-height: 1.25rem; color: rgb(17 24 39); margin-top: 0.5rem;">
                                        ${measurement_type} of ${measurement_value}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center; display: grid; grid-template-columns: auto auto; padding: 20px 0 0 12px">
                        ${draw}${remove}
                    </div>
                </div>
`;
  return newItem;
}

function getWidgetElement() {
  const widgetContainer = document.createElement("div");
  widgetContainer.id = "measurement_widget";
  widgetContainer.style.position = "absolute";
  widgetContainer.style.top = "40px";
  widgetContainer.style.right = "0";
  widgetContainer.style.zIndex = "10";
  widgetContainer.style.overflow = "hidden";

  widgetContainer.innerHTML = `
  <div class="cesium-measure-data-management-widget">
    <ul role="list" style="list-style-type: none; padding: 0; margin: 0">
      <li>
        <div style="display: flex; align-items: center;">
            <div style="display: flex; flex: 1; align-items: center;">
                <div style="flex: 1; padding-right: 1rem;">
                    <div style="display: block">
                        <div>
                            <p style="display: flex; align-items: center; font-size: 0.875rem; line-height: 1.25rem; color: rgb(107 114 128);">
                                <textarea rows="1" id="measurement_comment" class="cesium-measure-data-management-textarea" placeholder="Measurement title"></textarea>
                            </p>
                            <p style="margin-top: 0.5rem; font-size: 0.875rem; line-height color: rgb(17 24 39);" class="hidden" id="measurement_info">
                                <span id="measurement_type"></span> of <span id="measurement_value"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex; align-items: stretch">
              <button class="cesium-data-file-button" id="add_measurement" type="button">
                  Add
              </button>
            </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="cesium-measure-list-container">
      <ul role="list" id="measurements" style="list-style-type: none; padding: 0; margin: 0">
      </ul>
  </div>
  <div class="cesium-data-file-buttons">
    <label for="file-upload" style="height: 24px; cursor: pointer">
        <span>Import a CSV</span>
        <input id="file-upload" name="file-upload" type="file" class="sr-only" />
    </label>
    <button class="cesium-data-file-button" id="export_measurements" type="button" style="height: 24px; cursor: pointer">
        Export to CSV
    </button>
  </div>
`;
  return widgetContainer;
}
//<mask id="path-1-inside-1_1_2">
function getIcon(size) {
  return `<svg width="${size}" height="${size}" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.71538 24.2168C0.251123 22.752 0.251123 20.3731 1.71538 18.9082L18.9097 1.71387C20.3746 0.249673 22.7535 0.249673 24.2183 1.71387L28.2847 5.78614C29.7496 7.24805 29.7496 9.62696 28.2847 11.0918L11.0933 28.2832C9.62847 29.7481 7.24956 29.7481 5.78706 28.2832L1.71538 24.2168ZM3.70464 22.2246L7.7769 26.2969C8.14019 26.666 8.73784 26.666 9.10112 26.2969L26.2984 9.09961C26.6675 8.73633 26.6675 8.13868 26.2984 7.7754L22.2261 3.70313C21.8628 3.33692 21.2652 3.33692 20.9019 3.70313L18.5933 6.01172L20.3511 7.7754C20.7203 8.13868 20.7203 8.73633 20.3511 9.09961C19.9878 9.46875 19.3902 9.46875 19.0269 9.09961L17.2632 7.3418L14.8433 9.76172L16.6011 11.5254C16.9703 11.8887 16.9703 12.4863 16.6011 12.8496C16.2378 13.2188 15.6402 13.2188 15.2769 12.8496L13.5132 11.0918L11.0933 13.5117L12.8511 15.2754C13.2203 15.6387 13.2203 16.2363 12.8511 16.5996C12.4878 16.9688 11.8902 16.9688 11.5269 16.5996L9.76323 14.8418L7.34331 17.2617L9.10112 19.0254C9.47026 19.3887 9.47026 19.9863 9.10112 20.3496C8.73784 20.7188 8.14019 20.7188 7.7769 20.3496L6.01323 18.5918L3.70464 20.9004C3.33843 21.2637 3.33843 21.8613 3.70464 22.2246Z" fill="black"/>
  </svg>`;
}

/**
 * Import/export measurement data
 *
 * @param {Object} options An object with the following properties:
 * @param {String|Element} options.container The container for the widget
 * @constructor
 * @alias DataManagement
 */
function DataManagement(options) {
  options = defaultValue(options, defaultValue.EMPTY_OBJECT);
  Measurement.call(this, options);
  this._container = options.container;
  this._drawingTool = false;
  this._metaData = [];
  this._currentMeta = undefined;
  this._addMeasurementEvent = false;
  this._redrawMeasurementEvents = [];
  this._removeMeasurementEvents = [];
  this._exportDataEvent = false;
  this._importDataEvent = false;
  this._removeEventListeners = [];
  this._currentItemIndex = 0;

  this._label = this._labelCollection.add(
    MeasurementSettings.getLabelOptions({
      horizontalOrigin: HorizontalOrigin.LEFT,
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(10, 10),
    })
  );

  document.addEventListener(
    "measurement",
    function (e) {
      const measurementValue = document.querySelector("#measurement_value");
      const measurementType = document.querySelector("#measurement_type");
      const measurementInfo = document.querySelector("#measurement_info");
      if (measurementValue) measurementValue.innerHTML = e.detail.value;
      if (measurementType) measurementType.innerHTML = e.detail.type;
      if (measurementInfo) measurementInfo.classList.remove("hidden");
      this._currentMeta = e.detail.meta;
    }.bind(this)
  );
}

DataManagement.prototype = Object.create(Measurement.prototype);

DataManagement.prototype.constructor = DataManagement;

Object.defineProperties(DataManagement.prototype, {
  /**
   * Gets the icon.
   * @type {String}
   * @memberof DataManagement.prototype
   * @readonly
   */
  icon: {
    value: getIcon(15),
  },
  /**
   * Gets the thumbnail.
   * @type {String}
   * @memberof DataManagement.prototype
   * @readonly
   */
  thumbnail: {
    value: getIcon(25),
  },
  /**
   * Gets the type.
   * @type {String}
   * @memberof DataManagement.prototype
   * @readonly
   */
  type: {
    value: "Import/export measurement data",
  },
  /**
   * Gets the instruction text.
   * @type {String[]}
   * @memberof DataManagement.prototype
   * @readonly
   */
  instructions: {
    value: ["Import/export measurement data"],
  },
  /**
   * Gets the id.
   * @type {String}
   * @memberof DataManagement.prototype
   * @readonly
   */
  id: {
    value: "dataManagement",
  },
});

/**
 * Resets the widget.
 */
DataManagement.prototype.reset = function () {};

/**
 * @returns {Boolean} true if the object has been destroyed, false otherwise.
 */
DataManagement.prototype.isDestroyed = function () {
  return false;
};

DataManagement.prototype._redrawMeasurement = function (index) {
  const { meta } = this._metaData.find(function (data) {
    return data.index === index;
  });
  let redrawDistanceEvent = new CustomEvent("redrawDistance", {
    detail: {
      componentLines: "componentLines" in meta ? meta.componentLines : null,
      measurementType: meta.measurementType,
      positionsArray: meta.positionsArray,
      camera: meta.camera,
      pos1: meta.pos1,
      pos2: meta.pos2,
      label: meta.label,
    },
  });

  document.dispatchEvent(redrawDistanceEvent);
};

DataManagement.prototype._removeMeasurement = function (index) {
  const measurementsList = document.querySelector("#measurements");
  let itemToRemove;
  document.querySelectorAll(".measurement_item").forEach(function (node) {
    const dataKey = node.getAttribute("data-key");
    if (dataKey == index) {
      itemToRemove = node;
      return;
    }
  });
  measurementsList.removeChild(itemToRemove);
  this._metaData = this._metaData.filter(function (data) {
    return data.index !== index;
  });
  document.dispatchEvent(new CustomEvent("measurementRemoved"));

  document.removeEventListener("click", this._redrawMeasurementEvents[index]);
  document.removeEventListener("click", this._removeMeasurementEvents[index]);
  this._redrawMeasurementEvents[index] = undefined;
  this._removeMeasurementEvents[index] = undefined;
};

DataManagement.prototype._addRedrawEventListener = function (index) {
  const redrawDistanceButton = document.querySelector(
    `#redraw_measurement_${index}`
  );
  const event = redrawDistanceButton.addEventListener(
    "click",
    function (e) {
      e.preventDefault();
      this._redrawMeasurement(index);
    }.bind(this)
  );
  this._redrawMeasurementEvents.push(event);
};

DataManagement.prototype._addRemoveEventListener = function (index) {
  const removeMeasurementButton = document.querySelector(
    `#remove_measurement_${index}`
  );
  const event = removeMeasurementButton.addEventListener(
    "click",
    function (e) {
      e.preventDefault();
      this._removeMeasurement(index);
    }.bind(this)
  );
  this._removeMeasurementEvents.push(event);
};

DataManagement.prototype._addMeasurement = function () {
  if (!this._currentMeta) return;
  const measurement_value = document.querySelector("#measurement_value")
    .innerHTML;
  const measurement_type = document.querySelector("#measurement_type")
    .innerHTML;
  const measurement_comment = document.querySelector("#measurement_comment")
    .value;

  const measurementsList = document.querySelector("#measurements");

  this._metaData.push({
    index: this._currentItemIndex,
    meta: this._currentMeta,
  });
  measurementsList.appendChild(
    measurementItemTemplate(
      this._currentItemIndex,
      measurement_type,
      measurement_value,
      measurement_comment
    )
  );
  this._addRedrawEventListener(this._currentItemIndex);
  this._addRemoveEventListener(this._currentItemIndex);
  document.querySelector("#measurement_comment").value = "";
  document.querySelector("#measurement_value").innerHTML = "";
  document.querySelector("#measurement_type").innerHTML = "";
  document.querySelector("#measurement_info").classList.add("hidden");
  this._currentMeta = undefined;
  this._currentItemIndex++;
};

DataManagement.prototype._exportMeasurements = function () {
  let collectComments = [];
  let collectTypes = [];
  let collectValues = [];
  let collectDataKeys = [];

  document
    .querySelectorAll('[name="measurement_comment"]')
    .forEach(function (element) {
      collectComments.push(element.value);
    });
  document
    .querySelectorAll('[name="measurement_type"]')
    .forEach(function (element) {
      collectTypes.push(element.value);
    });
  document
    .querySelectorAll('[name="measurement_value"]')
    .forEach(function (element) {
      collectValues.push(element.value);
    });
  document.querySelectorAll(".measurement_item").forEach(function (node) {
    const dataKey = node.getAttribute("data-key");
    collectDataKeys.push(dataKey);
  });

  const rows = [["measurement", "type", "value", "meta"]];
  const metaData = this._metaData;
  collectComments.forEach(function (comment, i) {
    const { meta } = metaData.find(function (data) {
      return data.index == collectDataKeys[i];
    });
    let array = [
      comment,
      collectTypes[i],
      collectValues[i],
      JSON.stringify(meta),
    ];
    rows.push(array);
  });

  const pom = document.createElement("a");
  const csvContent = rows.map((e) => e.join("\t")).join("\n"); //here we load our csv data
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  pom.href = url;
  pom.setAttribute("download", "measurements.csv");
  pom.click();
};

DataManagement.prototype._importMeasurements = function () {
  const inputElement = document.querySelector("#file-upload");
  const measurementsList = document.querySelector("#measurements");

  const fileList = inputElement.files;
  this._currentItemIndex = 0;
  if (fileList.length) {
    const fr = new FileReader();
    const file = fileList[0];
    const that = this;
    fr.onload = function () {
      const rows = fr.result.split("\n");
      document.getElementById("file-upload").value = "";
      that._metaData = [];
      measurementsList.innerHTML = "";
      rows.forEach(function (row, index) {
        if (index === 0) return;
        const fields = row.split("\t");

        let meta = JSON.parse(fields[3]);

        meta.label = fields[0] + ": " + fields[2];
        that._metaData.push({
          index,
          meta,
        });
        that._currentItemIndex++;
        const key = that._currentItemIndex;
        measurementsList.appendChild(
          measurementItemTemplate(key, fields[1], fields[2], fields[0])
        );
        that._addRedrawEventListener(key);
        that._addRemoveEventListener(key);
      });
      that._currentItemIndex++;
    };
    fr.readAsText(file);
  }
};

DataManagement.prototype._addEventListeners = function () {
  const addButton = document.querySelector("#add_measurement");
  if (addButton) {
    this._addMeasurementEvent = addButton.addEventListener(
      "click",
      function () {
        this._addMeasurement();
      }.bind(this)
    );
  }

  const exportButton = document.querySelector("#export_measurements");
  if (exportButton) {
    this._exportDataEvent = exportButton.addEventListener(
      "click",
      function () {
        this._exportMeasurements();
      }.bind(this)
    );
  }
  const inputElement = document.querySelector("#file-upload");
  if (inputElement) {
    this._importDataEvent = inputElement.addEventListener(
      "change",
      function () {
        this._importMeasurements();
      }.bind(this),
      false
    );
  }
};

DataManagement.prototype._onActiveChange = function () {
  const container = this._container.parentNode;
  if (this._active) {
    const widget = getWidgetElement();
    container.appendChild(widget);
    this._addEventListeners();
  } else {
    const widget = document.querySelector("#measurement_widget");
    if (!widget) return;
    container.removeChild(widget);
  }
};
/**
 * Destroys the measurement.
 */
DataManagement.prototype.destroy = function () {
  return destroyObject(this);
};

export default DataManagement;

//This file is automatically rebuilt by the Cesium build process.
export default "uniform mat4 u_transformPositionViewToUv;\n\
void intersectDepth(vec2 screenCoord, Ray ray, inout Intersections ix) {\n\
float logDepthOrDepth = czm_unpackDepth(texture(czm_globeDepthTexture, screenCoord));\n\
if (logDepthOrDepth != 0.0) {\n\
vec4 eyeCoordinateDepth = czm_screenToEyeCoordinates(screenCoord, logDepthOrDepth);\n\
eyeCoordinateDepth /= eyeCoordinateDepth.w;\n\
vec3 depthPositionUv = vec3(u_transformPositionViewToUv * eyeCoordinateDepth);\n\
float t = dot(depthPositionUv - ray.pos, ray.dir);\n\
setIntersectionPair(ix, DEPTH_INTERSECTION_INDEX, vec2(t, +INF_HIT));\n\
} else {\n\
setIntersectionPair(ix, DEPTH_INTERSECTION_INDEX, vec2(NO_HIT));\n\
}\n\
}\n\
";

import {
  defined,
  DeveloperError,
  JulianDate
} from "../../../Core/cesium/Source/Cesium.js";

/**
 * A mixin which configures the keyboard controls for the camera.
 * Rather than being called directly, this function is normally passed as
 * a parameter to {@link Viewer#extend}, as shown in the example below.
 * @function
 *
 * @param {Viewer} viewer The viewer instance.
 * @param {Object} [options] An object with the following properties:
 * @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
 *
 * @exception {DeveloperError} viewer is required.
 *
 * @example
 * var viewer = new Cesium.Viewer('cesiumContainer');
 * viewer.extend(Cesium.viewerSkyboxDestroyMixin);
 */
function viewerKeyboardCameraControlsMixin(viewer, options) {

  if (!defined(viewer)) {
    throw new DeveloperError("viewer is required.");
  }

  const scene = viewer.scene;
  const canvas = viewer.canvas;
  canvas.setAttribute("tabindex", "0"); // needed to put focus on the canvas
  canvas.onclick = function () {
    canvas.focus();
  };
  const ellipsoid = scene.globe.ellipsoid;

  let startMovingTime;
  let startMousePosition;
  let mousePosition;
  const flags = {
    looking: false,
    rotating: false,
    forward: false,
    backward: false,
    up: false,
    down: false,
    left: false,
    right: false,
  };

  function getFlagForKeyCode(keyCode) {
    switch (keyCode) {
      case "W".charCodeAt(0):
        return "forward";
      case "S".charCodeAt(0):
        return "backward";
      case "Q".charCodeAt(0):
        return "up";
      case "E".charCodeAt(0):
        return "down";
      case "D".charCodeAt(0):
        return "right";
      case "A".charCodeAt(0):
        return "left";
      default:
        return undefined;
    }
  }

  function isMoving() {
    return flags.forward || flags.backward || flags.up || flags.down || flags.left || flags.right;
  }

  document.addEventListener(
    "keydown",
    function (e) {
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") return false;
      const flagName = getFlagForKeyCode(e.keyCode);
      if (typeof flagName !== "undefined") {
        flags[flagName] = true;
      }
    },
    false
  );

  document.addEventListener(
    "keyup",
    function (e) {
      const flagName = getFlagForKeyCode(e.keyCode);
      if (typeof flagName !== "undefined") {
        flags[flagName] = false;
      }
    },
    false
  );

  viewer.clock.onTick.addEventListener(function (clock) {
    const camera = viewer.camera;

    if (flags.looking) {
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;

      // Coordinate (0.0, 0.0) will be where the mouse was clicked.
      const x = (mousePosition.x - startMousePosition.x) / width;
      const y = -(mousePosition.y - startMousePosition.y) / height;

      const lookFactor = 0.05;
      camera.lookRight(x * lookFactor);
      camera.lookUp(y * lookFactor);
    }

    // mark down the time when the movement starts
    if (!startMovingTime && isMoving()) {
      startMovingTime = clock.currentTime.clone();
      clock.shouldAnimate = true;
    } else if (!isMoving()) {
      startMovingTime = undefined;
      clock.shouldAnimate = false;
    }

    // Change movement speed based on the distance of the camera to the surface of the ellipsoid.
    const cameraHeight = ellipsoid.cartesianToCartographic(
      camera.position
    ).height;
    // Build up movement speed the longer you hold the movement keys
    const secondsMoving = startMovingTime ? Math.max(JulianDate.secondsDifference(clock.currentTime, startMovingTime), 1) : 1;
    const moveRate = (cameraHeight < 9000) ?
      Math.max(Math.min(cameraHeight / 800, 5) * (secondsMoving * 2), 0.0001) :
      cameraHeight / 100;
    const lookRate = (0.584 / (180 * Math.PI)) * 4 * secondsMoving;
    const zoomRate = 0.1 * secondsMoving;

    if (flags.up) {
      viewer.selectedEntity ? camera.zoomIn(zoomRate) : camera.moveUp(moveRate);
    }
    if (flags.down) {
      viewer.selectedEntity ? camera.zoomOut(zoomRate) : camera.moveDown(moveRate);
    }
    if (flags.forward) {
      viewer.selectedEntity ? camera.rotateDown(lookRate) : camera.moveForward(moveRate);
    }
    if (flags.backward) {
      viewer.selectedEntity ? camera.rotateUp(lookRate) : camera.moveBackward(moveRate);
    }
    if (flags.left) {
      viewer.selectedEntity ? camera.rotateLeft(lookRate) : camera.moveLeft(moveRate);
    }
    if (flags.right) {
      viewer.selectedEntity ? camera.rotateRight(lookRate) : camera.moveRight(moveRate);
    }
  });
}
export default viewerKeyboardCameraControlsMixin;

import {
  BoundingSphere,
  Cartesian2,
  Cartesian3,
  Color,
  destroyObject,
  Event,
  Model,
  PrimitiveCollection,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
} from "../../../Core/cesium/Source/Cesium.js";

import SliceArrow from "./SliceArrow";

export default class SlicerArrows {
  constructor(viewer, dataSource, options) {
    this._viewer = viewer;
    this._scene = viewer.scene;

    this._dataSource = dataSource;
    this._moveCallback = options.moveCallback;
    this._positionUpdateCallback = options.positionUpdateCallback;
    this._arrowsList = options.arrowsList;

    this._scratchBoundingSphere = new BoundingSphere();
    this._scratchArrowPosition2d = new Cartesian2();
    this._scratchOppositeArrowPosition2d = new Cartesian2();
    this._scratchAxisVector2d = new Cartesian2();
    this._scratchMouseMoveVector = new Cartesian2();
    this._scratchObjectMoveVector2d = new Cartesian2();
    this._scratchNewArrowPosition2d = new Cartesian2();
    this._scratchAxisVector3d = new Cartesian3();

    this._createArrows();

    this._eventHandler = new ScreenSpaceEventHandler(this._viewer.canvas);

    this._eventHandler.setInputAction(this.onLeftDown.bind(this), ScreenSpaceEventType.LEFT_DOWN);
    this._eventHandler.setInputAction(this.onMouseMove.bind(this), ScreenSpaceEventType.MOUSE_MOVE);
    this._eventHandler.setInputAction(this.onLeftUp.bind(this), ScreenSpaceEventType.LEFT_UP);

    this._selectedArrowReleased = new Event();
  }

  get selectedArrowReleased() {
    return this._selectedArrowReleased;
  }

  onLeftDown(event) {
    const pickedSliceArrow = this._pickSliceArrow(event.position);

    if (pickedSliceArrow) {
      this._selectedArrow = pickedSliceArrow;
      this._viewer.scene.screenSpaceCameraController.enableInputs = false;
    }
  }

  onLeftUp() {
    if (this._selectedArrow) {
      this._selectedArrow = undefined;
      this._viewer.scene.screenSpaceCameraController.enableInputs = true;

      this._selectedArrowReleased.raiseEvent();
    }

    this._unhighlightArrow();
  }

  onMouseMove(movement) {
    if (this._selectedArrow) {
      const scene = this._viewer.scene;
      const side = this._selectedArrow.side;
      const oppositeSide = this._selectedArrow.oppositeSide;
      const oppositeArrow = this._arrows[oppositeSide];

      const oppositePosition3d = oppositeArrow.position;
      const arrowPosition3d = this._selectedArrow.position;

      scene.cartesianToCanvasCoordinates(arrowPosition3d, this._scratchArrowPosition2d);
      scene.cartesianToCanvasCoordinates(oppositePosition3d, this._scratchOppositeArrowPosition2d);

      // get pixel size for calculation move distance in meters
      this._scratchBoundingSphere.center = arrowPosition3d;
      const pixelSize = scene.camera.getPixelSize(
        this._scratchBoundingSphere,
        scene.drawingBufferWidth,
        scene.drawingBufferHeight
      );

      // calculate scalar of mouse move
      Cartesian2.subtract(
        this._scratchOppositeArrowPosition2d,
        this._scratchArrowPosition2d,
        this._scratchAxisVector2d
      );

      Cartesian2.subtract(movement.endPosition, this._scratchArrowPosition2d, this._scratchMouseMoveVector);

      const scalar2d =
        Cartesian2.dot(this._scratchMouseMoveVector, this._scratchAxisVector2d) /
        Cartesian2.dot(this._scratchAxisVector2d, this._scratchAxisVector2d);

      // calculate distance in meters
      Cartesian2.multiplyByScalar(this._scratchAxisVector2d, scalar2d, this._scratchObjectMoveVector2d);
      Cartesian2.add(
        this._scratchArrowPosition2d,
        this._scratchObjectMoveVector2d,
        this._scratchNewArrowPosition2d
      );

      const distance =
        Cartesian2.distance(this._scratchNewArrowPosition2d, this._scratchArrowPosition2d) * pixelSize;

      // calculate Cartesian3 position of arrow
      const scalarDirection = (1 / scalar2d) * Math.abs(scalar2d);
      const scalar3d = (distance / Cartesian3.distance(arrowPosition3d, oppositePosition3d)) * scalarDirection;

      const axisVector3d = Cartesian3.subtract(oppositePosition3d, arrowPosition3d, this._scratchAxisVector3d);
      const objectMoveVector3d = Cartesian3.multiplyByScalar(
        axisVector3d,
        scalar3d,
        new Cartesian3()
      );

      const newArrowPosition3d = Cartesian3.add(arrowPosition3d, objectMoveVector3d, new Cartesian3());

      if (this._moveCallback) {
        const origDistance = Cartesian3.distance(arrowPosition3d, oppositePosition3d);
        const newDistance = Cartesian3.distance(newArrowPosition3d, oppositePosition3d);

        let direction = newDistance > origDistance ? 1 : -1;

        let moveAmount = distance * direction;

        if (side === "up") {
          direction = this._scratchArrowPosition2d.y > this._scratchNewArrowPosition2d.y ? -1 : 1;

          moveAmount = distance * direction;
        }

        this._moveCallback(side, moveAmount, objectMoveVector3d);
      }

      this._updateArrows();
    } else {
      this._highlightArrow(movement.endPosition);
    }

    this._viewer.scene.requestRender();
  }

  _createArrows() {
    // @ts-ignore
    this._arrows = {};

    const primitiveCollection = new PrimitiveCollection();

    // @ts-ignore
    primitiveCollection.id = "SlicerArrows-PrimitiveCollection";

    this._viewer.scene.primitives.add(primitiveCollection);

    this._arrowsList.forEach((arrow) => {
      const side = arrow.side;

      this._arrows[side] = new SliceArrow({
        scene: this._viewer.scene,
        side: side,
        color: arrow.color,
        oppositeSide: arrow.oppositeSide,
        uri: arrow.uri,
        positionUpdateCallback: this._positionUpdateCallback,
        primitiveCollection: primitiveCollection
      });
    });
  }

  _updateArrows() {
    this._arrowsList.forEach((arrow) => {
      this._arrows[arrow.side].update();
    });
  }

  _pickSliceArrow(position) {
    const pickedObject = this._viewer.scene.pick(position);

    const isModelPicked =
      pickedObject && pickedObject.primitive && pickedObject.primitive instanceof Model && pickedObject.id;

    if (!isModelPicked) {
      return undefined;
    }

    return this._arrows[pickedObject.id];
  }

  _highlightArrow(position) {
    const pickedSliceArrow = this._pickSliceArrow(position);

    if (pickedSliceArrow) {
      this._highlightedArrow = pickedSliceArrow;

      pickedSliceArrow.color = Color.YELLOW;
      this._viewer.canvas.style.cursor = "pointer";
    } else {
      this._unhighlightArrow();
    }
  }

  _unhighlightArrow() {
    if (this._highlightedArrow) {
      this._highlightedArrow.color = this._highlightedArrow.originalColor;
      this._highlightedArrow = undefined;
      this._viewer.canvas.style.cursor = "";
    }
  }

  destroy() {
    this._arrowsList.forEach((arrow) => {
      const arrow1 = this._arrows[arrow.side];

      arrow1.destroy();
    });

    this._eventHandler.destroy();

    destroyObject(this._eventHandler);
    destroyObject(this);
  }

  showHide(show) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const side in this._arrows) {
      this._arrows[side].show = show;
      this._arrows[side].update();
    }
  }
}
//This file is automatically rebuilt by the Cesium build process.
export default "#define STEP_COUNT_MAX 1000 // Harcoded value because GLSL doesn't like variable length loops\n\
#define ALPHA_ACCUM_MAX 0.98 // Must be > 0.0 and <= 1.0\n\
uniform mat3 u_transformDirectionViewToLocal;\n\
uniform vec3 u_cameraPositionUv;\n\
#if defined(PICKING)\n\
uniform vec4 u_pickColor;\n\
#endif\n\
#if defined(JITTER)\n\
float hash(vec2 p)\n\
{\n\
vec3 p3 = fract(vec3(p.xyx) * 50.0);\n\
p3 += dot(p3, p3.yzx + 19.19);\n\
return fract((p3.x + p3.y) * p3.z);\n\
}\n\
#endif\n\
void main()\n\
{\n\
vec4 fragCoord = gl_FragCoord;\n\
vec2 screenCoord = (fragCoord.xy - czm_viewport.xy) / czm_viewport.zw;\n\
vec3 eyeDirection = normalize(czm_windowToEyeCoordinates(fragCoord).xyz);\n\
vec3 viewDirWorld = normalize(czm_inverseViewRotation * eyeDirection);\n\
vec3 viewDirUv = normalize(u_transformDirectionViewToLocal * eyeDirection);\n\
vec3 viewPosUv = u_cameraPositionUv;\n\
Intersections ix;\n\
vec2 entryExitT = intersectScene(screenCoord, viewPosUv, viewDirUv, ix);\n\
if (entryExitT.x == NO_HIT) {\n\
discard;\n\
}\n\
float currT = entryExitT.x;\n\
float endT = entryExitT.y;\n\
vec3 positionUv = viewPosUv + currT * viewDirUv;\n\
vec3 positionUvShapeSpace = convertUvToShapeUvSpace(positionUv);\n\
TraversalData traversalData;\n\
SampleData sampleDatas[SAMPLE_COUNT];\n\
traverseOctreeFromBeginning(positionUvShapeSpace, traversalData, sampleDatas);\n\
#if defined(JITTER)\n\
float noise = hash(screenCoord);\n\
currT += noise * traversalData.stepT;\n\
positionUv += noise * traversalData.stepT * viewDirUv;\n\
#endif\n\
FragmentInput fragmentInput;\n\
#if defined(STATISTICS)\n\
setStatistics(fragmentInput.metadata.statistics);\n\
#endif\n\
vec4 colorAccum =vec4(0.0);\n\
for (int stepCount = 0; stepCount < STEP_COUNT_MAX; ++stepCount) {\n\
Properties properties = accumulatePropertiesFromMegatexture(sampleDatas);\n\
copyPropertiesToMetadata(properties, fragmentInput.metadata);\n\
fragmentInput.voxel.positionUv = positionUv;\n\
fragmentInput.voxel.positionShapeUv = positionUvShapeSpace;\n\
fragmentInput.voxel.positionUvLocal = sampleDatas[0].tileUv;\n\
fragmentInput.voxel.viewDirUv = viewDirUv;\n\
fragmentInput.voxel.viewDirWorld = viewDirWorld;\n\
fragmentInput.voxel.travelDistance = traversalData.stepT;\n\
czm_modelMaterial materialOutput;\n\
fragmentMain(fragmentInput, materialOutput);\n\
vec4 color = vec4(materialOutput.diffuse, materialOutput.alpha);\n\
color.rgb = max(color.rgb, vec3(0.0));\n\
color.a = clamp(color.a, 0.0, 1.0);\n\
colorAccum += (1.0 - colorAccum.a) * vec4(color.rgb * color.a, color.a);\n\
if (colorAccum.a > ALPHA_ACCUM_MAX) {\n\
colorAccum.a = ALPHA_ACCUM_MAX;\n\
break;\n\
}\n\
if (traversalData.stepT == 0.0) {\n\
break;\n\
}\n\
currT += traversalData.stepT;\n\
positionUv += traversalData.stepT * viewDirUv;\n\
if (currT > endT) {\n\
#if (INTERSECTION_COUNT == 1)\n\
break;\n\
#else\n\
vec2 entryExitT = nextIntersection(ix);\n\
if (entryExitT.x == NO_HIT) {\n\
break;\n\
} else {\n\
currT = entryExitT.x;\n\
endT = entryExitT.y;\n\
positionUv = viewPosUv + currT * viewDirUv;\n\
}\n\
#endif\n\
}\n\
positionUvShapeSpace = convertUvToShapeUvSpace(positionUv);\n\
traverseOctreeFromExisting(positionUvShapeSpace, traversalData, sampleDatas);\n\
}\n\
colorAccum.a /= ALPHA_ACCUM_MAX;\n\
#if defined(PICKING)\n\
if (colorAccum.a == 0.0) {\n\
discard;\n\
}\n\
out_FragColor = u_pickColor;\n\
#else\n\
out_FragColor = colorAccum;\n\
#endif\n\
}\n\
";

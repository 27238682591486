import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import {
  FontAwesome,
  Box,
  Drawer,
  Button,
  List,
  ListItem,
  IconButton,
  Divider,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme
} from "ca-react-component-lib";

/* jshint ignore:start */
export default function ToolbarInfo({}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <Button className={open ? "toolbarButton active" : "toolbarButton"} onClick={() => setOpen(!open)}>
        <FontAwesome
          icon="circle-question"
        />
      </Button>
      <Drawer
        hideBackdrop
        anchor="right"
        open={open}
        className="toolbarDrawer"
        onClose={() => setOpen(false)}
        ModalProps={{
          disableEnforceFocus: true,
        }}
      >
        <List>
          <ListItem>
            <FontAwesome
              icon="circle-question"
              sx={{ mr: 2 }}
            />
            <span className="drawerTitle">Toolbar information</span>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => setOpen(false)}>
              <FontAwesome icon="xmark" />
            </IconButton>
          </ListItem>
        </List>

        <Divider />

        <Card sx={{ m: 2, overflow: "auto" }}>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesome icon="chevron-down" sx={{ mx: 2 }} />}>
              <FontAwesome
                icon="scrollwheel"
                sx={{ mr: 2 }}
              />
              3D Navigation (mouse & keyboard)
            </AccordionSummary>
            <AccordionDetails sx={{ px: 4, py: 2 }}>
              <div className="drawerSubTitle">Pan view</div>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Moves the view forward, back, right, left, up and down.</Box>
              <div>
                <div className="drawerKeyBox">
                  <div className="drawerKey">q</div>
                  <div className="drawerKeyText">up</div>
                </div>
                <div className="drawerKeyBox">
                  <div className="drawerKey">w</div>
                  <div className="drawerKeyText">forward</div>
                </div>
                <div className="drawerKeyBox">
                  <div className="drawerKey">e</div>
                  <div className="drawerKeyText">down</div>
                </div>
              </div>
              <div>
                <div className="drawerKeyBox">
                  <div className="drawerKey">a</div>
                  <div className="drawerKeyText">left</div>
                </div>
                <div className="drawerKeyBox">
                  <div className="drawerKey">s</div>
                  <div className="drawerKeyText">back</div>
                </div>
                <div className="drawerKeyBox">
                  <div className="drawerKey">d</div>
                  <div className="drawerKeyText">right</div>
                </div>
              </div>

              <Box className="drawerSubTitle" sx={{ mt: 3 }}>Zoom</Box>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Moves in and out.</Box>
              <div className="drawerKeybindDescription">
                <div className="drawerKey"><FontAwesome icon="scrollwheel" /></div>
                <div className="drawerKeyDisclaimer">Mousewheel scroll</div>
              </div>

              <Box className="drawerSubTitle" sx={{ mt: 3 }}>Rotate angle</Box>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Rotates the view up, down, left and right.</Box>
              <Box className="drawerCrosshairsDesc" color={theme.palette.info.light}>
                <FontAwesome icon="crosshairs" sx={{ mr: 2 }} /> while targeting environment
              </Box>
              <div className="drawerKeybindDescription">
                <div className="drawerKey leftClickPoint"><FontAwesome icon="mouse" /></div>
                <div className="drawerKeyDisclaimer">Left click + drag</div>
              </div>
              <div className="drawerKeybindDescription">
                <div className="drawerKey rightClickPoint"><FontAwesome icon="mouse" /></div>
                <div className="drawerKeyDisclaimer">Right click + drag</div>
              </div>

              <Box className="drawerSubTitle" sx={{ mt: 3 }}>Rotate model</Box>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Rotates the view up, down, left and right.</Box>
              <Box className="drawerCrosshairsDesc" color={theme.palette.info.light}>
                <FontAwesome icon="crosshairs" sx={{ mr: 2 }} /> while targeting model
              </Box>
              <div className="drawerKeybindDescription">
                <div className="drawerKey rightClickPoint"><FontAwesome icon="mouse" /></div>
                <div className="drawerKeyDisclaimer">Right click + drag</div>
              </div>
              <Box sx={{ my: 2, mt: 2 }}>
                <div className="drawerKeybindDescription">
                  <div className="drawerKey">ctrl</div>
                  <Box className="drawerKey" sx={{ ml: 2 }}><FontAwesome icon="mouse" /></Box>
                  <div className="drawerKeyDisclaimer">Click + drag</div>
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesome icon="chevron-down" sx={{ mx: 2 }} />}>
              <FontAwesome
                icon="hand-pointer"
                sx={{ mr: 2 }}
              />
              3D Navigation (touch)
            </AccordionSummary>
            <AccordionDetails sx={{ px: 4, py: 2 }}>
              <Box className="drawerSubTitle" sx={{ mt: 3 }}>Pan view</Box>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Moves the view up, down, left and right.</Box>
              <div className="drawerKeybindDescription">
                <div className="drawerKeyDisclaimer">One finger drag</div>
              </div>

              <Box className="drawerSubTitle" sx={{ mt: 3 }}>Tilt view</Box>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Move back and forward.</Box>
              <div className="drawerKeybindDescription">
                <div className="drawerKeyDisclaimer">Two-finger drag, same direction</div>
              </div>

              <Box className="drawerSubTitle" sx={{ mt: 3 }}>Zoom view</Box>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Zoom in and out.</Box>
              <div className="drawerKeybindDescription">
                <div className="drawerKeyDisclaimer">Two-finger pinch</div>
              </div>

              <Box className="drawerSubTitle" sx={{ mt: 3 }}>Rotate</Box>
              <Box className="drawerGreytext" sx={{ mb: 2 }}>Rotates the model view up, down, left and right.</Box>
              <div className="drawerKeybindDescription">
                <div className="drawerKeyDisclaimer">Two-finger drag, opposite direction</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesome icon="chevron-down" sx={{ mx: 2 }} />}>
              <FontAwesome
                icon="hand"
                sx={{ mr: 2 }}
              />
              Move group
            </AccordionSummary>
            <AccordionDetails sx={{ px: 4, py: 2 }}>
              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="hand"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Move tool</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(Spacebar)</span>
              </Box>
              <List className="bulletList">
                <ListItem>The Hand Tool allows you to click around within a project without accidentally activating hover outlines, making selections, or moving objects.</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="location-arrow"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Select tool</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(Shift+V)</span>
              </Box>
              <List className="bulletList">
                <ListItem>You can select layers in the canvas itself, or from the Layers panel. Click on an object in the canvas to select it.</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesome icon="chevron-down" sx={{ mx: 2 }} />}>
              <FontAwesome
                icon="angle"
                sx={{ mr: 2 }}
              />
              Measurement group
            </AccordionSummary>
            <AccordionDetails sx={{ px: 4, py: 2 }}>
              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="horizontal-rule"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Single Line</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(L)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click on the point cloud or the globe to set the start point and end points</ListItem>
                <ListItem>To make a new measurement, click to clear the previous measurement</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="draw-polygon"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Component</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(C)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click on the point cloud or the globe to set the start point and end points</ListItem>
                <ListItem>To make a new measurement, click to clear the previous measurement</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="angle"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Polyline</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(P)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click to start drawing a polyline</ListItem>
                <ListItem>Keep clicking to add more points</ListItem>
                <ListItem>Double click to finish drawing</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="ruler-horizontal"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Horizontal</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(H)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click on the point cloud or the globe to set the start point</ListItem>
                <ListItem>Move the mouse to drag the line</ListItem>
                <ListItem>Press this shift key to clamp the direction of the line</ListItem>
                <ListItem>Click again to set the end point</ListItem>
                <ListItem>To make a new measurement, click to clear the previous measurement</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="ruler-vertical"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Vertical</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(V)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click on the point cloud or the globe to set the start point</ListItem>
                <ListItem>Move the mouse to drag the line</ListItem>
                <ListItem>Click again to set the end point</ListItem>
                <ListItem>To make a new measurement, click to clear the previous measurement</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="line-height"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Height from terrain</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(T)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Right click on the ground to set ground point, then click on the model to get a distance from that point to terrain (ground point)</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="chart-area"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Area</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(A)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click to start drawing a polygon</ListItem>
                <ListItem>Keep clicking to add more points</ListItem>
                <ListItem>Double click to finish drawing</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="circle-dot"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Point coordinates</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(D)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Move the mouse to see the longitude, latitude and height of the point</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesome icon="chevron-down" sx={{ mx: 2 }} />}>
              <FontAwesome
                icon="camera-movie"
                sx={{ mr: 2 }}
              />
              Camera group
            </AccordionSummary>
            <AccordionDetails sx={{ px: 4, py: 2 }}>
              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="camera-movie"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">New Camera</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(Shift+C)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click on the point cloud or the globe to set the start point and end points</ListItem>
                <ListItem>To make a new measurement, click to clear the previous measurement</ListItem>
              </List>

              <Box sx={{ display: 'flex' }}>
                <FontAwesome
                  icon="arrow-rotate-right"
                  sx={{ mr: 2 }}
                />
                <span className="drawerSubTitle">Reset Camera view</span>
                <Box sx={{ flexGrow: 1 }} />
                <span className="drawerKeybind">(Shift+R)</span>
              </Box>
              <List className="bulletList">
                <ListItem>Click on the point cloud or the globe to set the start point and end points</ListItem>
                <ListItem>To make a new measurement, click to clear the previous measurement</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Drawer>
    </Box>
  );
}
/* jshint ignore:end */
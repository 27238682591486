import React from 'react'; // This is required for the transpiled .js version (React.createELement)
import MovementButton from './MovementButton.js';
import MeasurementButton from './MeasurementButton.js';
import CameraButton from './CameraButton.js';
import CommentButton from './CommentButton.js';
import ClippingPlanesButton from './ClippingPlanesButton.js';
import GeneralSettings from './GeneralSettings.js';
import AssetProfile from './AssetProfile.js';
import ToolbarInfo from './ToolbarInfo.js';
import { Toolbar, Button, FontAwesome, Box } from 'ca-react-component-lib';

/* jshint ignore:start */

export default function Header({
  viewer,
  tileset,
  assessmentId,
  mode,
  setMode,
  commentToolActive,
  setCommentToolActive,
  showMeasurement,
  setShowMeasurement,
  setClippingPlanes,
}) {
    const [site, setSite ] = React.useState(undefined);
    const [currentSiteAssessment, setCurrentSiteAssesment ] = React.useState(undefined);
    const [sitePlannerEnabled, setSitePlannerEnabled] = React.useState(localStorage.getItem("sitePlannerEnabled") || "false")

    window.addEventListener(
      "message",
      function (event) {
        if (event.data && event.data.detail && event.data.detail.data && event.data.detail.action === 'siteData') {
            setSite(event.data.detail.data[0])
            setCurrentSiteAssesment(event.data.detail.data[1])
        }
      },
      false
    );

    React.useEffect(() => {
      return () => {
        setSite(undefined);
        setCurrentSiteAssesment(undefined);
      };
    }, [])

    return (
        <Toolbar id="reactHeader" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <MovementButton
            viewer={viewer}
            mode={mode}
            setMode={setMode}
          />
          <MeasurementButton
            viewer={viewer}
            mode={mode}
            setMode={setMode}
            setShowMeasurement={setShowMeasurement}
          />
          <CameraButton
            viewer={viewer}
            tileset={tileset}
          />
          <ClippingPlanesButton
            viewer={viewer}
            tileset={tileset}
            assessmentId={assessmentId}
            setClippingPlanes={setClippingPlanes}
          />
          {/*<Button className="toolbarButton" disabled>*/}
          {/*  <FontAwesome*/}
          {/*    icon="camera"*/}
          {/*  />*/}
          {/*</Button>*/}
          <CommentButton
            toolActive={commentToolActive}
            setToolActive={setCommentToolActive}
          />
          <Button className={mode === "primitive" ? "toolbarButton active" : "toolbarButton"} onClick={() => {
            setMode("primitive");
            window.cesiumTools.selectTool("box");
          }}>
            <FontAwesome
              icon="cube"
            />
          </Button>

          <Box sx={{ flexGrow: 1 }} />
          <Box className="siteId">{site?.companySiteId ? `#${site?.companySiteId}` : ''}</Box>
          <Box sx={{ flexGrow: 1 }} />

          <AssetProfile site={site} currentSiteAssessment={currentSiteAssessment} />
          <ToolbarInfo />
          <GeneralSettings viewer={viewer} showMeasurement={showMeasurement} />
          <Button variant={sitePlannerEnabled === "true" ? "contained" : "outlined"} sx={{ ml: 2 }} onClick={() => {
            const sitePlannerEnabled = localStorage.getItem("sitePlannerEnabled") || "false";
            const sitePlannerStatus = sitePlannerEnabled === "true" ? "false" : "true";
            localStorage.setItem("sitePlannerEnabled", sitePlannerStatus);
            setSitePlannerEnabled(sitePlannerStatus);
            window.dispatchEvent(new Event("toggleSitePlanner"));
          }}>Site Planner</Button>
        </Toolbar>
    );
};

/* jshint ignore:end */
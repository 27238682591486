import AbstractTool from "./AbstractTool";
import { pickObject } from "./common";

function shiftPressed(e) {
    return e.shiftKey;
}

export default class SelectTool extends AbstractTool {
    constructor() {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
        this.enableDnD = false;
    }

    handleClick(event) {
        const pickedObject = pickObject(event, this.viewer);

        if (pickedObject) {
            const object = pickedObject.id ? pickedObject.id : pickedObject.primitive.id;

            if (object && pickedObject.primitive) {
                const shift = shiftPressed(event);
                this.selectPrimitive(object, pickedObject.primitive, event, shift);

                return true;
            }

            if ("primitive" in pickedObject && pickedObject.primitive?.onClick) {
                pickedObject.primitive?.onClick();
            }

            this.selectEntity(undefined, false);
        }

        return false;
    }

    selectPrimitive(id, primitive, event, r) {
        if (this.cesiumTools.editShapeAllowed !== false) {
            this.viewer.selectedEntity = undefined;
            this.cesiumTools.selectCesiumPrimitive(id, primitive, event, r);
        }
    }

    selectEntity(entity, shift) {
        this.viewer.selectedEntity = entity;
        this.cesiumTools.selectCesiumEntity(entity, shift);
    }
}
